<template>
	<main id="main">
		<ReportFilter @toggle-filter="toggleFilter(false)" />
		<ReportList @toggle-filter="toggleFilter(true)" />
	</main>
</template>

<script setup>
import ReportList from "./ReportList"
import ReportFilter from "./ReportFilter"

const toggleFilter = (flag) => {
	var sideMenu = document.getElementById('sideMenu');
	if (flag) { //open filter
		sideMenu.style.transition = 'left 0.5s';
		sideMenu.style.left = '0';
	} else { //close filter
		sideMenu.style.left = '-450px';
	}
}
</script>
<style scoped src="@/assets/css/pc/reportList.css">
</style>
<template>
	<div id="sideMenu">
		<button class="side_close_btn" @click="closeFilter"></button>
		<button class="side_reset_btn" @click="clearFilter"></button>
		<div class="side_list">
			<div class="side_list_cont">
				<div class="side_title open_title">{{ t("message.msg_204") }}</div>
				<div class="side_check">
					<template v-for="statusOption in statusOptions" :key="statusOption.value">
						<label class="container"> {{ statusOption.text }}
							<input v-model="filters.status" type="radio" name="radio" :value="statusOption.value" />
							<span class="checkmark"></span>
						</label>
					</template>
				</div>
			</div>
			<div class="side_list_cont">
				<div class="side_title">{{ t("message.msg_217") }}</div>
				<select id="readStatus" v-model="filters.readStatus" name="readStatus">
					<option v-for="readStatusOption in readStatusOptions" :key="readStatusOption.value" :value="readStatusOption.value">{{ readStatusOption.text }}</option>
				</select>
			</div>
			<div class="side_list_cont">
				<div class="side_title">{{ t("message.msg_224") }}</div>
				<select id="replyStatus" v-model="filters.replyStatus" name="replyStatus">
					<option v-for="replyStatusOption in replyStatusOptions" :key="replyStatusOption.value" :value="replyStatusOption.value">{{ replyStatusOption.text }}</option>
				</select>
			</div>
			<div class="side_list_cont">
				<div class="side_title">{{ t("message.msg_225") }}</div>
				<div class="side_date">
					<VDatePicker v-model="filters.openDateFrom" :disabled-dates="disabledOpenDateFrom" :masks="masks" :popover="popover" :color="selectedColor">
						<template #default="{ inputValue, inputEvents }">
							<input :value="inputValue" :placeholder="t('message.msg_086')" v-on="inputEvents" />
						</template>
					</VDatePicker>
					~
					<VDatePicker v-model="filters.openDateTo" :disabled-dates="disabledOpenDateTo" :masks="masks" :popover="popover" :color="selectedColor">
						<template #default="{inputValue, inputEvents}">
							<input :value="inputValue" :placeholder="t('message.msg_086')" v-on="inputEvents" />
						</template>
					</VDatePicker>
				</div>
			</div>
			<div class="side_list_cont">
				<div class="side_title">{{ t("message.msg_228") }}</div>
				<div class="side_date">
					<VDatePicker v-model="filters.dueDateFrom" :disabled-dates="disabledDueDateFrom" :masks="masks" :popover="popover" :color="selectedColor">
						<template #default="{ inputValue, inputEvents }">
							<input :value="inputValue" :placeholder="t('message.msg_086')" v-on="inputEvents" />
						</template>
					</VDatePicker>
					~
					<VDatePicker v-model="filters.dueDateTo" :disabled-dates="disabledDueDateTo" :masks="masks" :popover="popover" :color="selectedColor">
						<template #default="{inputValue, inputEvents}">
							<input :value="inputValue" :placeholder="t('message.msg_086')" v-on="inputEvents" />
						</template>
					</VDatePicker>
				</div>
			</div>
			<div class="side_list_cont">
				<div class="side_title">{{ t("message.msg_226") }}</div>
				<div class="side_date">
					<VDatePicker v-model="filters.replyDateFrom" :disabled-dates="disabledReplyDateFrom" :masks="masks" :popover="popover" :color="selectedColor">
						<template #default="{ inputValue, inputEvents }">
							<input :value="inputValue" :placeholder="t('message.msg_086')" v-on="inputEvents" />
						</template>
					</VDatePicker>
					~
					<VDatePicker v-model="filters.replyDateTo" :disabled-dates="disabledReplyDateTo" :masks="masks" :popover="popover" :color="selectedColor">
						<template #default="{inputValue, inputEvents}">
							<input :value="inputValue" :placeholder="t('message.msg_086')" v-on="inputEvents" />
						</template>
					</VDatePicker>
				</div>
			</div>
			<div class="side_list_cont">
				<div class="side_title">{{ t("message.msg_227") }}</div>
				<div class="side_date">
					<VDatePicker v-model="filters.readDateFrom" :disabled-dates="disabledReadDateFrom" :masks="masks" :popover="popover" :color="selectedColor">
						<template #default="{ inputValue, inputEvents }">
							<input :value="inputValue" :placeholder="t('message.msg_086')" v-on="inputEvents" />
						</template>
					</VDatePicker>
					~
					<VDatePicker v-model="filters.readDateTo" :disabled-dates="disabledReadDateTo" :masks="masks" :popover="popover" :color="selectedColor">
						<template #default="{inputValue, inputEvents}">
							<input :value="inputValue" :placeholder="t('message.msg_086')" v-on="inputEvents" />
						</template>
					</VDatePicker>
				</div>
			</div>
			<div class="side_list_cont">
				<div class="side_title">{{ t("message.msg_018") }} / {{ t("message.msg_019") }}</div>
				<input v-model="filters.reportNm" type="text" />
			</div>
			<div class="side_list_cont">
				<div class="side_title">{{ t("message.msg_021") }}</div>
				<input v-model="filters.memo" type="text" />
			</div>
			<div class="side_list_cont">
				<div class="side_title">{{ t("message.msg_031") }}</div>
				<input v-model="filters.partnerNm" type="text" />
			</div>
			<div class="side_list_cont">
				<div class="side_title">{{ t("message.msg_085") }}</div>
				<div class="side_date">
					<input v-model="filters.minPrice" min="0" type="number" @change="onChangeMinPrice" />
					~
					<input v-model="filters.maxPrice" min="0" type="number" @change="onChangeMaxPrice" />					
				</div>
			</div>
			<button class="search" @click="fetchReportList">
				<img :src="$icons.search" alt="" />{{ t("message.msg_013") }}
			</button>
		</div>
	</div>
</template>

<script setup>

import {useReportStore} from "@/store/reportStore.js"
import {ref, defineEmits, onUnmounted, watch} from "vue"
import _ from "lodash"
import {format} from "date-fns"
import {ko} from "date-fns/locale"
import {useI18n} from "vue-i18n"

const emit = defineEmits(["toggle-filter"])

const reportStore = useReportStore()
const masks = ref({ input: "YYYY-MM-DD" });
const selectedColor = ref('green');
const popover = ref({
				visibility: "focus",
				placement: "bottom",
				autoHide: "boolean",
			});

const filters = ref({
		status: "",
		readStatus: "",
		replyStatus: "",
		openDateFrom: null,
		openDateTo: null,
		dueDateFrom: null,
		dueDateTo: null,
		replyDateFrom: null,
		replyDateTo: null,
		readDateFrom: null,
		readDateTo: null,
		replyMemo: "",
		reportNm: "",
		reportNo: "",
		memo: "",
		partnerNm: "",
		minPrice: null,
		maxPrice: null,
})
const locale = ref(ko)

const disabledOpenDateFrom = ref(null)
const disabledOpenDateTo = ref(null)
const disabledDueDateFrom = ref(null)
const disabledDueDateTo = ref(null)
const disabledReplyDateFrom = ref(null)
const disabledReplyDateTo = ref(null)
const disabledReadDateFrom = ref(null)
const disabledReadDateTo = ref(null)

const {t} = useI18n()

const statusOptions = [
	{value: "", text: t("message.msg_205")},
	{value: "READY", text: t("message.msg_206")},
	{value: "OPENED", text: t("message.msg_207")},
	{value: "CLOSED", text: t("message.msg_208")},
]

const readStatusOptions = [
	{value: "", text: t("message.msg_205")},
	{value: "READY", text: t("message.msg_218")},
	{value: "READ", text: t("message.msg_219")},
]

const replyStatusOptions = [
	{value: "", text: t("message.msg_205")},
	{value: "READY", text: t("message.msg_220")},
	{value: "REPLIED", text: t("message.msg_221")},
]

onUnmounted(() => {
	clearFilter()
	fetchReportList()
})

watch(() => filters.value.openDateFrom, (newValue) => {
	if (newValue == null) {
		disabledOpenDateTo.value = null
	} else {
		let date = new Date(newValue)
		disabledOpenDateTo.value = [{end: date.setDate(date.getDate() - 1)}]
	}
})

watch(() => filters.value.openDateTo, (newValue) => {
	if (newValue == null) {
		disabledOpenDateFrom.value = null
	} else {
		let date = new Date(newValue)
		disabledOpenDateFrom.value = [{start: date.setDate(date.getDate() + 1)}]
	}
})

watch(() => filters.value.dueDateFrom, (newValue) => {
	if (newValue == null) {
		disabledDueDateTo.value = null
	} else {
		let date = new Date(newValue)
		disabledDueDateTo.value = [{end: date.setDate(date.getDate() - 1)}]
	}
})

watch(() => filters.value.dueDateTo, (newValue) => {
	if (newValue == null) {
		disabledDueDateFrom.value = null
	} else {
		let date = new Date(newValue)
		disabledDueDateFrom.value = [{start: date.setDate(date.getDate() + 1)}]
	}
})

watch(() => filters.value.replyDateFrom, (newValue) => {
	if (newValue == null) {
		disabledReplyDateTo.value = null
	} else {
		let date = new Date(newValue)
		disabledReplyDateTo.value = [{end: date.setDate(date.getDate() - 1)}]
	}
})

watch(() => filters.value.replyDateTo, (newValue) => {
	if (newValue == null) {
		disabledReplyDateFrom.value = null
	} else {
		let date = new Date(newValue)
		disabledReplyDateFrom.value = [{start: date.setDate(date.getDate() + 1)}]
	}
})

watch(() => filters.value.readDateFrom, (newValue) => {
	if (newValue == null) {
		disabledReadDateTo.value = null
	} else {
		let date = new Date(newValue)
		disabledReadDateTo.value = [{end: date.setDate(date.getDate() - 1)}]
	}
})

watch(() => filters.value.readDateTo, (newValue) => {
	if (newValue == null) {
		disabledReadDateFrom.value = null
	} else {
		let date = new Date(newValue)
		disabledReadDateFrom.value = [{start: date.setDate(date.getDate() + 1)}]
	}
})

const onChangeMinPrice = () => {
	if (filters.value.minPrice == "") {
		filters.value.minPrice = null
		return
	}
	if(filters.value.maxPrice != null) {
		if (filters.value.minPrice > filters.value.maxPrice) {
			filters.value.minPrice = filters.value.maxPrice
		}
	}
}

const onChangeMaxPrice = () => {
	if (filters.value.maxPrice == "") {
		filters.value.maxPrice = null
		return
	}
	if(filters.value.minPrice != null) {
		if (filters.value.maxPrice < filters.value.minPrice) {
			filters.value.maxPrice = filters.value.minPrice
		}
	}
}

const fetchReportList = async () => {
	let payload = _.cloneDeep(filters.value)
	payload.openDateFrom = makeDateStr(filters.value.openDateFrom, true)
	payload.openDateTo = makeDateStr(filters.value.openDateTo, false)
	payload.dueDateFrom = makeDateStr(filters.value.dueDateFrom, true)
	payload.dueDateTo = makeDateStr(filters.value.dueDateTo, false)
	payload.replyDateFrom = makeDateStr(filters.value.replyDateFrom, true)
	payload.replyDateTo = makeDateStr(filters.value.replyDateTo, false)
	payload.readDateFrom = makeDateStr(filters.value.readDateFrom, true)
	payload.readDateTo = makeDateStr(filters.value.readDateTo, false)
	payload.page = 0
	payload.sort = "modifiedDate,desc"
	await reportStore.setReportFilter(payload)
	await reportStore.fetchReportList()
}

const makeDateStr = (date, flag) => {
	if(date == null || date == undefined || date == "") {
		return ""
	}
	let dateStr = format(date, "yyyy-MM-dd")
	if (flag) {
		return `${dateStr}T00:00:00`
	} else {
		return `${dateStr}T23:59:59`
	}
	
}

const clearFilter = () => {
	filters.value = {
		status: "",
		readStatus: "",
		replyStatus: "",
		openDateFrom: null,
		openDateTo: null,
		dueDateFrom: null,
		dueDateTo: null,
		replyDateFrom: null,
		replyDateTo: null,
		readDateFrom: null,
		readDateTo: null,
		replyMemo: "",
		reportNm: "",
		reportNo: "",
		memo: "",
		partnerNm: "",
		minPrice: null,
		maxPrice: null,
	}
}

const closeFilter = () => {
	emit("toggle-filter")
}
</script>
<style scoped src="@/assets/css/pc/reportList.css">
</style>

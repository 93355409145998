<template>
	<common-modal @close-modal="closeModal">
		<div class="header" style="margin-bottom: 2em">
			<p style="font-size: 20px;">{{ t("message.msg_550") }} {{ t("message.msg_571") }}</p>
		</div>
		<div class="input_wrap" style="flex-direction: column; justify-content: center; align-items: flex-start;">
			<label style="width: 100%; margin-bottom: 1em;"> {{ t("message.msg_588") }} </label>
			<label style="width: 100%; margin-bottom: 1em;"> * {{ requiredFieldsContent }}{{ t("message.msg_591") }} </label>
			<div class="input_file">
				<button class="file-add" @click="downloadSample">{{ t("message.msg_589") }}</button>
			</div>
		</div>
		<div class="input_wrap" style="flex-direction: column; justify-content: center; align-items: flex-start;">
			<label style="width: 100%; margin-bottom: 1em;">{{ t("message.msg_590") }}</label>
			<div class="input_file" style="width: 100%;">
				<input :value="fileName" type="text" readonly data-index="1" />
				<button class="file-add" onclick="document.getElementById('fileInput1').click()">{{ t("message.msg_650") }}</button>
				<input
					id="fileInput1"
					type="file"
					accept=".csv"
					style="display: none;"
					@change="onFileChange"
				/>
			</div>
		</div>
		<button style="margin-top: 2em" @click="handleFileUpload">{{ t("message.msg_556") }}</button>
	</common-modal>
</template>
<script setup>
import {useI18n} from "vue-i18n"
import {ref, defineEmits} from "vue"
import partnerApi from "@/api/partner_api"

const {t} = useI18n()

const emit = defineEmits(["closeModal"])

const csvFile = ref([])
const fileName = ref("")

const header = [t("message.msg_503"), t("message.msg_550") + t("message.msg_510"), t("message.msg_567"), t("message.msg_550") + t("message.msg_505"), t("message.msg_550") + t("message.msg_516"), t("message.msg_550") + t("message.msg_516") + t("message.msg_503")] //이메일, 거래처 회사명, 거래처 코드, 거래처 사용자, 거래처 사용자 이메일
const requiredFields = [t("message.msg_503"), t("message.msg_550") + t("message.msg_510"), t("message.msg_567"), t("message.msg_550") + t("message.msg_516"), t("message.msg_550") + t("message.msg_516") + t("message.msg_503")] //필수 입력 필드
const realHeader = ["userEmail", "partnerCompanyName", "partnerCode", "partnerDivisionName", "partnerUserName", "partnerUserEmail"]

const requiredFieldsContent = ref(requiredFields.join(", "))

const closeModal = () => {
	emit("closeModal")
}

const onFileChange = (e) => {
	csvFile.value = e.target.files || e.dataTransfer.files
	fileName.value = csvFile.value[0].name
}

const downloadSample = () => {
	const csvContent = [header].map(e => e.join(",")).join("\n")
	let blob = new Blob(["\ufeff" + csvContent], {type: "text/csv;charset=utf8;"})
	const link = document.createElement("a")
	const url = URL.createObjectURL(blob)
	link.setAttribute("href", url)
	link.setAttribute("download", "sample.csv")
	link.style.visibility = "hidden"
	document.body.appendChild(link)
	link.click()
	document.body.removeChild(link)
}

const handleFileUpload = () => {
	if (csvFile.value[0]) {
		const reader = new FileReader()
		reader.onload = function (e) {
			const csvText = e.target.result
			const convertResult = csvToJson(csvText)
			if (convertResult.err.length > 0) {
				alert(convertResult.err.join(",") + t("message.msg_592"))
				return
			}
			const json = convertResult.result
			sendPartnerList(json)
		}
		reader.readAsText(csvFile.value[0], 'UTF-8')
	} else {
		alert("error: file not found")
	}
}

const sendPartnerList = (json) => {
	partnerApi.addPartnerList(json).then(() => {
		alert(t("message.msg_558"))
		closeModal()
	}).catch((error) => {
		alert(error.response.data.message)
	})
}

const csvToJson = (csv) => {
	const lines = csv.split('\n')
	const result = []
	const convertErr = []
	const headers = lines[0].split(',')

	for (let i = 1; i < lines.length; i++) { // 첫 줄은 헤더이므로 제외
		const obj = {};
		const currentLine = lines[i].split(',');

		if (currentLine.length === headers.length) {
			let isValid = true;
			for (let j = 0; j < headers.length; j++) {
				const key = headers[j].trim();
				const value = currentLine[j].trim();
				if(key == t("message.msg_567") || key == "取引先コード" || key == "PartnerCode"){
					if(value.length > 16){
						convertErr.push(i + 1)
					}
				}
				if (requiredFields.includes(key) && !value) {
					isValid = false
					break
				}
				if (j < realHeader.length)
					obj[realHeader[j]] = value
			}

			if (isValid) {
				obj["mailType"] = "MAIL"
				result.push(obj);
			} else {
				convertErr.push(i + 1)
			}
		}
	}
	return {result: result, err: convertErr};
}

</script>
<style scoped src="@/assets/css/pc/account.css">
</style>
<style scoped>
.file-add {
	background-color: var(--main-color);
    color: var(--white);
    padding: 0.8rem 0.8rem;
    border-radius: 8px;
    margin-left: 0.5rem;
    margin-right: 0.5rem;
    align-items: center;
    font-weight: 700;
}
</style>
const TOKEN_KEY = "token"
var constant = require("./constants").default

export default {
	getToken: () => {
		return JSON.parse(localStorage.getItem(TOKEN_KEY))
	},
	getAccessToken: () => {
		var token = JSON.parse(localStorage.getItem(TOKEN_KEY))
		if (token == null || token == undefined) {
			return null
		}
		return JSON.parse(localStorage.getItem(TOKEN_KEY)).accessToken
	},
	getRefreshToken: () => {
		var token = JSON.parse(localStorage.getItem(TOKEN_KEY))
		if (token == null || token == undefined) {
			return null
		}
		return JSON.parse(localStorage.getItem(TOKEN_KEY)).refreshToken
	},
	setToken: (token) => {
		localStorage.setItem(TOKEN_KEY, JSON.stringify(token))
	},
	deleteToken: () => {
		localStorage.removeItem(TOKEN_KEY)
	},
	decodeToken: (token) => {
		var base64Url = token.split(".")[1]
		var base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/")
		var jsonPayload = decodeURIComponent(
			atob(base64)
				.split("")
				.map((c) => {
					return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2)
				})
				.join("")
		)
		var sub = JSON.parse(jsonPayload).sub
		return JSON.parse(sub) //domain, email, name, type
	},
	getExp: (token) => {
		var base64Url = token.split(".")[1]
		var base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/")
		var jsonPayload = decodeURIComponent(
			atob(base64)
				.split("")
				.map((c) => {
					return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2)
				})
				.join("")
		)
		return JSON.parse(jsonPayload).exp
	},
	getUserType: (type) => {
		var roles = []
		if (type.includes(constant.ROLE_TYPE.SYSTEM)) {
			roles.push(constant.ROLE_TYPE.SYSTEM)
		}
		if (type.includes(constant.ROLE_TYPE.MANAGER)) {
			roles.push(constant.ROLE_TYPE.MANAGER)
		}
		if (type.includes(constant.ROLE_TYPE.APPROVER)) {
			roles.push(constant.ROLE_TYPE.APPROVER)
		}
		if (type.includes(constant.ROLE_TYPE.SENDER)) {
			roles.push(constant.ROLE_TYPE.SENDER)
		}
		if (type.includes(constant.ROLE_TYPE.UPLOADER)) {
			roles.push(constant.ROLE_TYPE.UPLOADER)
		}
		if (type.includes(constant.ROLE_TYPE.USER)) {
			roles.push(constant.ROLE_TYPE.USER)
		}
		return roles
	},
}

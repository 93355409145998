<template>
	<common-modal @close-modal="closeModal">
		<p>{{ $t("message.msg_708") }}</p>
		<br />
		<div v-if="company != undefined" id="explanation">
			<label>{{ $t("message.msg_510") }}</label>
			<span>{{ company.name }}</span>
		</div>
		<p style="font-size: 20px">{{ $t("message.msg_078") }}</p>
		<div id="explanation">
			<label for="mac">{{ $t("message.msg_712") }}</label>
			<input v-model="mac" name="mac" style="border-radius: 50px 0px 0px 50px; font-size: 13px; padding: 11px;" />
			<button class="inner-button" style="border-radius: 0px 50px 50px 0px;" @click="issueLicense">{{ $t("message.msg_506") }}</button>
			<!--
			<span v-html="t('message.msg_079')"></span>
			<span v-html="t('message.msg_080')"></span>
			<span v-html="t('message.msg_081')"></span>
			-->
		</div>
		<template v-if="licenseList != undefined && licenseList.length > 0">
			<p style="font-size: 20px">{{ $t("message.msg_709") }}</p>
			<template v-for="license in licenseList" :key="license">
				<div class="license-list">
					<label>{{ $t("message.msg_712") }}</label>
					<span>{{ formatMac(license) }}</span>
					<button class="inner-button" style="margin-left: auto" @click="deleteLicense(license)">{{ $t("message.msg_572") }}</button>
				</div>
			</template>
		</template>
	</common-modal>
</template>
<script setup>
import {useI18n} from "vue-i18n"
import {ref, defineEmits, defineProps, onMounted, watch} from "vue"
import companyApi from "@/api/company_api"

const {t} = useI18n()
const emit = defineEmits(["close"])
const props = defineProps({
	company: {type: Object, required: true}
})

const mac = ref("")
const licenseList = ref([])

const closeModal = () => {
  emit("close");
};

onMounted(() => {
	if(props.company != undefined) {
		getLicenseList()
	}
})

watch(mac, (newValue) => {
	mac.value = newValue.toUpperCase()
})

const issueLicense = async () => {

	if (mac.value == null || mac.value == undefined || mac.value == "") {
		alert(t("message.msg_710"))
		return
	}

	if (mac.value.replace(/-/g, "").length != 12) {
		alert(t("message.msg_711"))
		return
	}

	try {
		let res = await companyApi.getLicense({macAddr: mac.value, companyId: props.company.id})
		if (res.status !== 200) {
			alert(t("message.msg_203"))
			return
		}
		const blob = new Blob([res.data], {type: "text/plain"})
		const url = window.URL.createObjectURL(blob)
		const link = document.createElement("a")
		link.href = url
		link.download = "uploader.license"
		link.click()
		getLicenseList()
	} catch (e) {
		alert(t("message.msg_203"))
	}
}

const getLicenseList = async () => {
	let res = await companyApi.getUploaderList(props.company.id)
	licenseList.value = res.data
}

const deleteLicense = async (macAddr) => {
	await companyApi.deleteUploader({"macAddr": macAddr, "companyId": props.company.id})
	getLicenseList()
}

const formatMac = (macAddr) => {
	return macAddr.match(/.{1,2}/g).join("-")
}

</script>
<style scoped src="@/assets/css/pc/admin.css">
</style>
<style scoped>
#explanation {
	display: flex;
	flex-direction: row;
	align-items: center;
	margin-bottom: 1em;
	width: 100%;
}

#explanation span {
	margin: 0 0 1rem 0;
}

#explanation label {
	min-width: 100px;
	margin: 0 1rem 1rem 0;
}

#explanation input {
	margin: 0 0 1rem 0;
}

.inner-button {
	width: 100px !important;
	padding: 10px !important;
	margin: 0 0 1rem 0;
}

.license-list  {
	display: flex;
	flex-direction: row;
	align-items: center;
	width: 100%;
}

.license-list span {
	margin: 0 0 1rem 0;
}

.license-list  label {
	min-width: 100px;
	margin: 0 1rem 1rem 0;
}

</style>
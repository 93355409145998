export default {
  "message": {
    "msg_000": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["아이디와 비밀번호를 입력해 주세요."])},
    "msg_001": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["아이디는 이메일 주소로 입력해 주세요."])},
    "msg_002": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["비밀번호는 8문자 이상으로 설정해 주세요."])},
    "msg_003": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["회사명을 입력해 주세요."])},
    "msg_004": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["거래처를 선택해 주세요."])},
    "msg_005": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이메일, 비밀번호, 이름을 확인해 주세요."])},
    "msg_006": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["현재 비밀번호를 입력해 주세요."])},
    "msg_007": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["새 비밀번호를 입력해 주세요."])},
    "msg_008": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["비밀번호 확인을 입력해 주세요."])},
    "msg_009": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["비밀번호가 일치하지 않습니다."])},
    "msg_010": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["수신함"])},
    "msg_011": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["공유문서함"])},
    "msg_012": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["장표"])},
    "msg_013": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["검색"])},
    "msg_014": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["조회상태"])},
    "msg_015": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["회신상태"])},
    "msg_016": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["수신일시"])},
    "msg_017": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["보관기한"])},
    "msg_018": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["장표이름"])},
    "msg_019": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["고유코드"])},
    "msg_020": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["장표ID"])},
    "msg_021": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["장표메모"])},
    "msg_022": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["조회"])},
    "msg_023": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["미조회"])},
    "msg_024": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["장표상태"])},
    "msg_025": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["회신상태"])},
    "msg_026": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["상세정보"])},
    "msg_027": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["장표이력"])},
    "msg_028": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PDF장표다운로드"])},
    "msg_029": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSV다운로드"])},
    "msg_030": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["발신회사명"])},
    "msg_031": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["거래처명"])},
    "msg_032": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["장표코드"])},
    "msg_033": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["공개일시"])},
    "msg_034": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["조회일시"])},
    "msg_035": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["회신일시"])},
    "msg_036": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["회신만료일"])},
    "msg_037": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["장표보관만료일"])},
    "msg_038": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["공개중지일시"])},
    "msg_039": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["생성일시"])},
    "msg_040": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["생성자"])},
    "msg_041": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["수정일시"])},
    "msg_042": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["수정자"])},
    "msg_043": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["모두 선택"])},
    "msg_044": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["업로드ID"])},
    "msg_045": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["발신회사ID"])},
    "msg_046": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["거래처ID"])},
    "msg_047": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["메일주소 형식"])},
    "msg_048": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["메모"])},
    "msg_049": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["공개중지일시"])},
    "msg_050": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["회신조회일시"])},
    "msg_051": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["회신 메모"])},
    "msg_052": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["승인상태"])},
    "msg_053": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["승인일시"])},
    "msg_054": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["승인자"])},
    "msg_055": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["승인메모"])},
    "msg_056": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["회신확인"])},
    "msg_057": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ETC 다운로드"])},
    "msg_058": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["전체 다운로드"])},
    "msg_059": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이전"])},
    "msg_060": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["다음"])},
    "msg_061": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["새로고침"])},
    "msg_062": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["닫기"])},
    "msg_063": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["회신하기"])},
    "msg_064": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["다운로드"])},
    "msg_065": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["옵션"])},
    "msg_066": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "msg_067": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["초기화"])},
    "msg_068": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["확인"])},
    "msg_069": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["회신을 거절합니다."])},
    "msg_070": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "msg_071": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["장표 정보"])},
    "msg_072": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["승인"])},
    "msg_073": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["반려"])},
    "msg_074": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["승인함"])},
    "msg_075": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["일"])},
    "msg_076": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["월"])},
    "msg_077": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["주"])},
    "msg_078": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["업로더 라이선스 발급"])},
    "msg_079": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["* 업로더 라이선스 파일은 ReportDeliveryUploader 서비스에서 인증을 위해 사용되는 파일입니다.<br /><br />"])},
    "msg_080": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["* ReportDeliveryUploader 설치 후, 설치 폴더 아래에 있는 License 폴더에 uploader.license 파일을 저장하세요.<br /><br />"])},
    "msg_081": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["* 라이선스 파일은 회사 당 하나이며, 재발급 시 기존의 라이선스 파일은 사용하지 못합니다.<br /><br />"])},
    "msg_082": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["년"])},
    "msg_083": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["생성"])},
    "msg_084": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["수신확인"])},
    "msg_085": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["장표금액 (¥)"])},
    "msg_086": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["YYYY-MM-DD"])},
    "msg_201": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["발신함"])},
    "msg_202": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["데이터 조회에 실패했습니다."])},
    "msg_203": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["처리 중 오류가 발생하였습니다."])},
    "msg_204": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["공개상태"])},
    "msg_205": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["전체"])},
    "msg_206": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["대기중"])},
    "msg_207": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["공개"])},
    "msg_208": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["공개 중지"])},
    "msg_209": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["삭제 실패"])},
    "msg_210": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["파일 다운로드 실패"])},
    "msg_211": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["공개 실패"])},
    "msg_212": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["공개 중지 실패"])},
    "msg_213": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["조회 요청 메일 발송 실패"])},
    "msg_214": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["회신 요청 메일 발송 실패"])},
    "msg_215": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["장표 이력 조회 실패"])},
    "msg_216": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["장표 정보 조회 실패"])},
    "msg_217": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["조회상태"])},
    "msg_218": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["미조회"])},
    "msg_219": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["조회 완료"])},
    "msg_220": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["미회신"])},
    "msg_221": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["회신완료"])},
    "msg_222": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["발신회사 확인 완료"])},
    "msg_223": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["회신 취소"])},
    "msg_224": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["회신상태"])},
    "msg_225": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["공개일시"])},
    "msg_226": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["회신일시"])},
    "msg_227": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["조회일시"])},
    "msg_228": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["만료일시"])},
    "msg_229": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["장표공개"])},
    "msg_230": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["실행"])},
    "msg_231": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["새로 고침"])},
    "msg_232": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["총 건수"])},
    "msg_233": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PDF다운로드"])},
    "msg_234": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["공개중지"])},
    "msg_235": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["조회요청 메일발송"])},
    "msg_236": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["회신요청 메일발송"])},
    "msg_237": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["보관기한 재설정"])},
    "msg_238": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["모든파일"])},
    "msg_239": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSV다운로드"])},
    "msg_240": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["장표삭제"])},
    "msg_241": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["기본 정보"])},
    "msg_242": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["상태 정보"])},
    "msg_243": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["수신처 정보"])},
    "msg_244": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["발신회사 정보"])},
    "msg_245": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["회신 정보"])},
    "msg_246": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["승인 정보"])},
    "msg_247": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["초기화"])},
    "msg_248": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["를(을) 완료했습니다."])},
    "msg_249": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["선택한 장표가 없습니다. 장표를 선택 후, 다시 요청해 주세요."])},
    "msg_250": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PDF열기"])},
    "msg_300": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["발신함"])},
    "msg_301": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["장표 회신"])},
    "msg_302": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["회신 실패"])},
    "msg_303": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["공유한 문서함"])},
    "msg_304": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["공유 받은 문서함"])},
    "msg_305": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["공유문서함명"])},
    "msg_306": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["공유문서함 삭제 후 메일 전송"])},
    "msg_307": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["공유문서함 삭제"])},
    "msg_308": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["선택한 장표를 삭제하겠습니까?"])},
    "msg_309": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["공유문서함명을 확인해 주세요."])},
    "msg_310": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["해당 공유함을 삭제하시겠습니까?"])},
    "msg_311": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["추가"])},
    "msg_312": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["사용자 추가/삭제 시 메일 전송"])},
    "msg_313": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["사용자 목록을 저장해 주세요."])},
    "msg_314": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["입력 후 추가해 주세요."])},
    "msg_315": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["동일한 이메일이 존재합니다."])},
    "msg_316": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["공유문서함 생성자는 사용자 목록에 추가할 수 없습니다."])},
    "msg_317": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["장표 추가"])},
    "msg_318": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["회신확인"])},
    "msg_319": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["회신메모"])},
    "msg_320": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["공유문서함 추가"])},
    "msg_321": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["편집"])},
    "msg_322": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["사용자 편집"])},
    "msg_323": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["장표 삭제"])},
    "msg_324": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["사용자 목록"])},
    "msg_325": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["공유문서함이 없습니다."])},
    "msg_326": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["공유문서함 생성자"])},
    "msg_327": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["장표가 없습니다."])},
    "msg_328": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["공유한 문서함 목록"])},
    "msg_329": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["공유받은 문서함 목록"])},
    "msg_330": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["공유 사용자 편집"])},
    "msg_331": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["공유 사용자 목록"])},
    "msg_332": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["입력하지 않은 참조자가 있습니다."])},
    "msg_333": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["동일한 참조자가 있습니다."])},
    "msg_334": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["공유 사용자"])},
    "msg_335": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["회신 메모를 입력해 주세요."])},
    "msg_336": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이메일 주소 형식을 확인해 주세요."])},
    "msg_500": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["비밀번호"])},
    "msg_501": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["내정보"])},
    "msg_502": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이름"])},
    "msg_503": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이메일"])},
    "msg_504": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["권한"])},
    "msg_505": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["부서"])},
    "msg_506": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["저장"])},
    "msg_507": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["비밀번호 변경"])},
    "msg_508": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["현재 비밀번호"])},
    "msg_509": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["새 비밀번호"])},
    "msg_510": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["회사명"])},
    "msg_511": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["회사 법인 번호"])},
    "msg_512": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["관리자 이메일"])},
    "msg_513": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["서비스 정보"])},
    "msg_514": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["수정"])},
    "msg_515": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["확인"])},
    "msg_516": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["사용자"])},
    "msg_517": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["등록여부"])},
    "msg_518": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["만료일"])},
    "msg_519": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["생성일"])},
    "msg_520": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MANAGER"])},
    "msg_521": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["APPROVER"])},
    "msg_522": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["UPLOADER"])},
    "msg_523": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SENDER"])},
    "msg_524": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["USER"])},
    "msg_525": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["페이지를 찾을 수 없습니다."])},
    "msg_526": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["입력한 URL을 확인해 주세요."])},
    "msg_527": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["내 정보"])},
    "msg_528": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["회사 정보"])},
    "msg_529": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["회사 구성원"])},
    "msg_530": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["선택"])},
    "msg_531": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["직접입력"])},
    "msg_532": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["회원가입"])},
    "msg_533": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["저장했습니다."])},
    "msg_534": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["권한이 변경된 사용자는 다시 로그인해야 변경된 권한이 적용됩니다."])},
    "msg_535": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["회원탈퇴"])},
    "msg_536": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SENDER 권한을 삭제하면 해당 거래처의 발신자에서 삭제됩니다."])},
    "msg_550": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["거래처"])},
    "msg_551": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["발신 타입"])},
    "msg_552": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NONE"])},
    "msg_553": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MAIL"])},
    "msg_554": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ATTACH"])},
    "msg_555": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BCC"])},
    "msg_556": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["추가"])},
    "msg_557": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["입력되지 않은 항목이 있습니다. (확인해 주세요)"])},
    "msg_558": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["거래처를 추가했습니다."])},
    "msg_559": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["담당자"])},
    "msg_560": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["전자화 신청여부"])},
    "msg_561": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["신청"])},
    "msg_562": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["신청안함"])},
    "msg_563": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["선택안함"])},
    "msg_564": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["전자화 신청 일시"])},
    "msg_565": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["등록"])},
    "msg_566": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["등록 일시"])},
    "msg_567": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PartnerCode"])},
    "msg_568": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["전자화 일시"])},
    "msg_569": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["등록 일시"])},
    "msg_570": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이력"])},
    "msg_571": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["일괄 추가"])},
    "msg_572": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["삭제"])},
    "msg_573": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["했습니다."])},
    "msg_574": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["거래처를 선택해 주세요."])},
    "msg_575": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["전자화 신청을 완료했습니다."])},
    "msg_576": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["시크릿코드 입력"])},
    "msg_577": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["시크릿코드"])},
    "msg_588": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1. 샘플 파일을 다운로드 받아 내용을 입력해 주세요."])},
    "msg_589": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSV 파일 다운로드"])},
    "msg_590": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2. CSV 파일을 입력해주세요."])},
    "msg_591": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["은 필수입력입니다."])},
    "msg_592": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["번째 행의 내용이 잘못되었습니다."])},
    "msg_593": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["일괄 삭제"])},
    "msg_594": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["시크릿코드를 다시 발송했습니다"])},
    "msg_595": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["재발급"])},
    "msg_600": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["업로드"])},
    "msg_601": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["업로드 상태"])},
    "msg_602": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["업로드 추가"])},
    "msg_603": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["업로드설정 ID"])},
    "msg_604": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["업로드 옵션"])},
    "msg_605": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["장표 파일"])},
    "msg_606": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["첨부 파일"])},
    "msg_607": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["업로드 설정"])},
    "msg_608": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["장표유형코드"])},
    "msg_609": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["설명"])},
    "msg_610": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["업로드 방식"])},
    "msg_611": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["파일명 필터"])},
    "msg_612": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["파일 개수 상한"])},
    "msg_613": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["기본 장표명"])},
    "msg_614": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["기본 비고"])},
    "msg_615": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["기본 보존기간"])},
    "msg_616": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["기본 회신기간"])},
    "msg_617": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["회신"])},
    "msg_618": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["회신 메모 작성 여부"])},
    "msg_619": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["공개"])},
    "msg_620": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["승인"])},
    "msg_621": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["회신 함"])},
    "msg_622": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["회신 안함"])},
    "msg_623": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PDF"])},
    "msg_624": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ALL"])},
    "msg_625": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["유"])},
    "msg_626": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["무"])},
    "msg_627": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["필수 입력 항목을 확인해 주세요."])},
    "msg_628": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["거래처 관리"])},
    "msg_629": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["비밀번호를 입력해 주세요."])},
    "msg_630": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이름을 입력해 주세요."])},
    "msg_631": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["회사명을 입력해 주세요."])},
    "msg_632": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["로그인"])},
    "msg_633": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["비밀번호 재설정"])},
    "msg_634": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이메일 전송"])},
    "msg_635": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이메일을 입력해 주세요."])},
    "msg_636": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["비밀번호를 입력해주세요."])},
    "msg_637": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이메일 주소를 입력하면 해당 메일로"])},
    "msg_638": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["회원가입 링크를 발송합니다."])},
    "msg_639": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["회원가입 메일을 발송했습니다."])},
    "msg_640": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["회원가입을 완료했습니다."])},
    "msg_641": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["업로드 목록"])},
    "msg_642": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["로그아웃"])},
    "msg_643": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["발신회사"])},
    "msg_644": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["전달방법"])},
    "msg_645": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이력"])},
    "msg_646": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["총"])},
    "msg_647": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["건"])},
    "msg_648": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["사용자 정보"])},
    "msg_649": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["기타 정보"])},
    "msg_650": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["파일추가"])},
    "msg_651": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["파일"])},
    "msg_652": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["개"])},
    "msg_653": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["첨부파일 개수가 초과했습니다."])},
    "msg_654": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["업로드하는 장표는 PDF형식만 가능합니다."])},
    "msg_655": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["변경한 비밀번호를 발송합니다."])},
    "msg_656": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["메일을 발송했습니다."])},
    "msg_657": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["성공"])},
    "msg_658": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["실패"])},
    "msg_659": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ReportDeliveryUploader"])},
    "msg_660": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["검색된 내용이 없습니다."])},
    "msg_661": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["3개월"])},
    "msg_662": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["6개월"])},
    "msg_663": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1년"])},
    "msg_664": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["직접입력"])},
    "msg_700": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["대표 이메일"])},
    "msg_701": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["요금제"])},
    "msg_702": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["서비스 시작일"])},
    "msg_703": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["서비스 만료일"])},
    "msg_704": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["서비스 등록"])},
    "msg_705": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["사용량"])},
    "msg_706": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["라이선스 파일 관리"])},
    "msg_707": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["등록기간"])},
    "msg_708": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["업로더 라이선스 관리"])},
    "msg_709": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["업로더 라이선스 목록"])},
    "msg_710": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MAC 주소를 입력해 주세요."])},
    "msg_711": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MAC 주소 형식이 아닙니다."])},
    "msg_712": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MAC 주소"])},
    "msg_713": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["사용량 확인"])},
    "msg_714": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["회사목록"])},
    "msg_715": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["지난달 사용량"])},
    "msg_716": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이번달 사용량"])},
    "msg_717": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1년 사용량"])},
    "msg_718": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["건"])},
    "msg_719": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["장표 수"])},
    "msg_720": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["500통"])},
    "msg_721": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["500통 패키지"])},
    "msg_722": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["표준"])},
    "msg_723": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["표준 패키지"])},
    "msg_724": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["현재 요금제"])},
    "msg_725": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["미가입"])},
    "msg_726": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["년"])},
    "msg_727": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["개월"])},
    "msg_728": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["회사삭제"])},
    "msg_729": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["탈퇴하겠습니까?"])},
    "msg_730": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["탈퇴했습니다."])},
    "msg_731": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["삭제하겠습니까?"])},
    "msg_732": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["삭제했습니다."])}
  }
}
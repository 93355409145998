//mobile과 pc 공통 메서드
import {ref} from "vue"
import { useI18n } from 'vue-i18n';

export function useReceivedReportListFunctions() {
    const { t } = useI18n();
    
    const downloadType = [
        {value: "pdf", text: t("message.msg_250")},
        {value: "all", text: t("message.msg_238")},
        {value: "dl", text: t("message.msg_233")},
        {value: "csv", text: t("message.msg_239")},
        {value: "etc", text: t("message.msg_057")},
    ]
    
    const totalPages = ref(1)
    const showReportReplyModal = ref(false);
    const showReportInfoModal = ref(false);

    
    const clickMore = (event) => {
        event.stopPropagation();
        var modal = event.target.nextElementSibling;
        if (modal) {
            var flag = modal.style.display === "none" || modal.style.display === "" || modal.style === undefined;
            if (flag) {
                modal.style.display = "block";
            } else {
                modal.style.display = "none";
            }
        }
    };
    
    const onClickOutside = (event) => {
        document.querySelectorAll(".popupMenu").forEach((modal) => {
            var icon = modal.previousElementSibling
            if(event.target != icon){
                modal.style.display = "none"
            }
        })
    }

  return {
    downloadType,
    totalPages,
    showReportReplyModal,
    showReportInfoModal,
    clickMore,
    onClickOutside
  };
}

import regSecCode from "@/assets/img/pc/sereat.png"
import tableDown from "@/assets/img/pc/table_down.svg"
import tableUp from "@/assets/img/pc/table_up.svg"
import close from "@/assets/img/pc/close.svg"
import search from "@/assets/img/pc/search.svg"
import login from "@/assets/img/pc/login_img.png"
import login_logo from "@/assets/img/pc/login_logo.png"
import upload from "@/assets/img/pc/upload.svg"
import report from "@/assets/img/pc/send.svg"
import approval from "@/assets/img/pc/approval.svg"
import partner from "@/assets/img/pc/account.svg"
import received from "@/assets/img/pc/reception.svg"
import share from "@/assets/img/pc/share.svg"
import setting from "@/assets/img/pc/setting.svg"
import logo from "@/assets/img/pc/logo.png"
import modify from "@/assets/img/pc/writing.png"
import share_document from "@/assets/img/pc/share_document.png"
import uploader from "@/assets/img/pc/uploader.svg"

export default {
	regSecCode: regSecCode,
	tableDown: tableDown,
	tableUp: tableUp,
	close: close,
	search: search,
	login: login,
	login_logo: login_logo,
	upload: upload,
	report: report,
	approval: approval,
	partner: partner,
	received: received,
	share: share,
	setting: setting,
	logo: logo,
	modify: modify,
	share_document: share_document,
	uploader: uploader,
}
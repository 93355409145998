<template>
	<common-modal v-if="showModal" @close-modal="cancel">
		<p class="popTitle"> {{ $t("message.msg_237") }} </p>
		<div class="content">
			<div class="numberBox">
				<input v-model="inputDate" min="1" type="number" @change="changeText" />
			</div>
			<div class="radioBox">
				<p v-for="d in dateList" :key="d.value">
					<input :id="'radio_' + d.value" v-model="date" type="radio" name="dradios" :value="d" @change="changeText" />
					<label :for="'radio_' + d.value">{{ d.text }}</label>
				</p>
			</div>
		</div>
		<button @click.stop.prevent="confirm"> {{ $t("message.msg_515") }} </button>
	</common-modal>
</template>
<script setup>

import {ref, defineExpose} from "vue";
import {useI18n} from "vue-i18n"
const {t} = useI18n()

const showModal = ref(false)
let resolvePromise = undefined
let rejectPromise = undefined

const dateList = [
	{value: "d", text: t("message.msg_075")},
	{value: "w", text: t("message.msg_077")},
	{value: "m", text: t("message.msg_076")},
	{value: "y", text: t("message.msg_082")},
]

const inputDate = ref(1)
const date = ref({})
const dueDate = ref("")

const show = () => {
	showModal.value = true
	inputDate.value = 1
	date.value = dateList[0]
	dueDate.value = inputDate.value + date.value.value

	return new Promise((res, rej) => {
		resolvePromise = res
		rejectPromise = rej
	})
}

const confirm = () => {
	showModal.value = false
	resolvePromise(dueDate.value)
}

const cancel = () => {
	showModal.value = false
	resolvePromise(false)
}

const changeText = () => {
	dueDate.value = inputDate.value + date.value.value
}

defineExpose({
	show
})

</script>
<style scoped>
.popup_wrap {
    background: #fff;
    width: 60%;
    position: relative;
    top: 50%;
    left: 50%;
    padding: 2rem 0;
    transform: translate(-50%, -50%);
    border-radius: 40px;
}

.popup {
    max-height: 80vh;
    overflow-y: auto;
    padding: 5rem 13rem;
}

.popup>button.close_btn {
    background: none;
    background-image: url(../../assets/img/pc/side_xmark.png);
    width: 35px;
    height: 35px;
    background-size: cover;
    cursor: pointer;
    position: absolute;
    top: 2.5rem;
    right: 2.5rem;
}

.popup_inner {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.popup_inner p {
    text-align: center;
    font-size: 1.4rem;
    line-height: 1.7;
}

.popup_inner input {
    width: 500px;
    padding: 15px 20px;
    font-size: 1rem;
    border-radius: 50px;
    margin: 2rem 0 2rem;
    outline: none;
}

input[type="radio"] {
	width: fit-content !important;
	margin-right: 1em;
}

.popup_inner button {
    background-color: var(--main-color);
    color: var(--white);
    width: 500px;
    padding: 15px;
    font-size: 1rem;
    border-radius: 50px;
}

.radioBox {
	display: flex;
	width: 100%;
}

.radioBox p {
	flex: 1 1 0;
}
</style>

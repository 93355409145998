<template>
	<div id="wrap">
		<main id="main">
			<section class="main_container">
				<article class="main_left" :style="{width: '100%'}">					
					<div class="left_list_table">
						<table>
							<thead>
								<tr>
									<td class="center"><span style="cursor: pointer">{{ t("message.msg_502") }}</span></td>
									<td class="center"><span style="cursor: pointer">{{ t("message.msg_503") }}</span></td>
									<td class="center"><span style="cursor: pointer">{{ t("message.msg_505") }}{{ t("message.msg_502") }}</span></td>
								</tr>
							</thead>
							<tbody>
								<tr v-for="user in userInfoArr.content" :key="user.email">
									<th class="center" style="cursor: pointer" @click="showDialog(user.id)"><span v-html="user.name"></span></th>
									<th class="center"><span v-html="user.email"></span></th>
									<th class="center"><span v-html="user.divisionName"></span></th>
								</tr>
							</tbody>
						</table>
					</div>
				</article>
				<!-- //main_right -->
			</section>
			<div v-show="UserInfoDialog" class="modal-wrap">
				<common-modal @close-modal="close">
					<user-info class="UserInfoDialog" :select-id="selectId" @close-modal="close" />
				</common-modal>
			</div>
		</main>
		<!-- //main -->
	</div>
</template>

<script setup>
import userApi from "@/api/user_api"
import UserInfo from "./UserInfoModal.vue"
import {ref, onMounted} from "vue"
import {useI18n} from "vue-i18n"
import jwt from "@/common/jwt"
import constant from "@/common/constants"

const userInfoArr = ref([
	{
		id: "",
		name: "",
		email: "",
		password: "",
		divisionName: "",
		registerFlag: "",
		expireDate: "",
		createdDate: "",
		authority: "",
	},
])
const UserInfoDialog = ref(false)
const selectId = ref("")
const {t} = useI18n()

onMounted(() => {
	userApi
	.getCompanyUserList()
	.then((res) => {
		userInfoArr.value = res.data
	})
	.catch(async (error) => {
		alert(error)
	})
})
const showDialog = (userId) => {
	const token = jwt.getAccessToken()
    var userType = jwt.getUserType(jwt.decodeToken(token).roleType)

    if(userType.indexOf(constant.ROLE_TYPE.MANAGER) != -1){
        UserInfoDialog.value = true
		selectId.value = userId
	}
}
const close = () => {
	UserInfoDialog.value = false
	selectId.value="" //#26350 juank91 회사 맴버설정을 수정한뒤 수정버튼을 누르지 않고 모달을 닫고 열면 수정된 정보가 남아 있어 수정함
	userApi
	.getCompanyUserList()
	.then((res) => {
		userInfoArr.value = res.data
	})
	.catch(async (error) => {
		alert(error)
	})
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped src ="../../assets/css/pc/account.css">
</style>

<template>
	<div class="share_user_layout">
		<div v-if="type=='share'" class="popup_inner">
			<template v-if="mode==2">
				<p>{{ t("message.msg_307") }}</p>
				<div class="input_wrap">
					<label for="">{{ t("message.msg_305") }}</label>
					<input v-model="sharedBoxNm" type="text" name="" :disabled="mode === 2" :placeholder="t('message.msg_305')" />
				</div>
			</template>
			<template v-else>
				<p v-if="mode==0">{{ t("message.msg_320") }}</p>
				<p v-else>{{ t("message.msg_011") }} {{ t("message.msg_321") }}</p>
				<div class="input_wrap">
					<label for="">{{ t("message.msg_305") }}</label>
					<input v-model="sharedBoxNm" type="text" name="" :disabled="mode === 2" :placeholder="t('message.msg_305')" />
				</div>
			</template>
			<div class="input_wrap bcc_wrap">
				<label for="bcc">{{ t("message.msg_334") }}</label>
				<div class="bcc">
					<div class="bcc_wrap">
						<div class="bcc_plus">
							<div v-if="ccList.length == 0" @click="addUser(false)">
								<input 
									v-model="ccList[0]"
									class="bcc_list" 
									:placeholder="t('message.msg_334')"
									@input="submitAutoCompleteEmail(0, ccList[0])"
									@click="selectUser(0)"
								/>
								<button class="x_mark" @click="deleteUser(0)"></button>
								<div v-if="userListModal==0 && searchedUserList.length > 0" v-on-click-outside="onClickOutside" class="user_list">
									<user-list-modal :searched-user-list="searchedUserList" @user-info="userInfo" />
								</div>
							</div>
							<div v-for="(user, index) in ccList" :key="index">
								<input 
									v-model="ccList[index]"
									class="bcc_list" 
									:placeholder="ccList[index] === '' ? t('message.msg_334') : ''"
									:disabled="mode === 2"
									@input="submitAutoCompleteEmail(index, ccList[index])"
									@click="selectUser(index)"
								/>
								<button class="x_mark" :disabled="mode === 2" @click="deleteUser(index)"></button>
								<div v-if="userListModal == index && searchedUserList.length > 0" v-on-click-outside="onClickOutside" class="user_list">
									<user-list-modal :searched-user-list="searchedUserList" @user-info="userInfo" />
								</div>
							</div>
						</div>
					</div>
					<button class="plus" :disabled="mode === 2" @click="addUser(true)"></button>
				</div>
			</div>
			<div class="button_layout">
				<div class="mailFlagBox">
					<input id="checkAll" v-model="mailFlag" type="checkbox" value="checkAll" />
					<label for="checkAll">{{ mode === 2 ? t("message.msg_306") : t("message.msg_312") }}</label>
				</div>
				<div class="button_group">
					<button class="send_btn" @click="mode === 2 ? deleteShareBox() : save()">{{ mode === 2 ? t("message.msg_307") : t("message.msg_506") }}</button>
					<button v-if="mode !== 2" class="send_btn" @click="close">{{ t("message.msg_062") }}</button>
				</div>
			</div>	
		</div>
		<!-- 공유받은 문서함일 경우 -->
		<div v-else class="popup_inner">
			<p>{{ t("message.msg_331") }}</p>
			<div class="input_wrap bcc_wrap">
				<label for="bcc">{{ t("message.msg_326") }}</label>
				<div class="bcc">
					<div class="bcc_wrap">
						<div class="bcc_plus">
							<div class="bcc_list"><span>{{ currentCcShareBox.adminUserName }}, {{ currentCcShareBox.adminUserEmail }}</span></div>
						</div>
					</div>
				</div>
			</div>
			<div class="input_wrap bcc_wrap">
				<label for="bcc">{{ t("message.msg_334") }}</label>
				<div class="bcc">
					<div class="bcc_wrap">
						<div class="bcc_plus">
							<div v-for="(user, index) in ccList" :key="index" class="bcc_list">
								<span class="user">{{ user }}</span>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="button_layout">
				<button class="send_btn" @click="close">{{ t("message.msg_068") }}</button>
			</div>
		</div>
	</div>
</template>

<script setup>
import { ref, defineProps, defineEmits, onMounted, computed, watch } from 'vue';
import { useShareBoxStore } from "@/store/shareBoxStore.js";
import _ from "lodash";
import jwt from "@/common/jwt";
import userApi from "@/api/user_api";
import shareBoxApi from "@/api/sharebox_api.js";
import UserListModal from "./UserListModal.vue";
import { vOnClickOutside } from '@vueuse/components'
import {useI18n} from "vue-i18n"
import utils from "@/common/util"

const props = defineProps({
  type: { type: String, required: true },
  mode: { //mode - 0: 추가, 1: 편집, 2: 삭제
      type: [Number, null],
      default: null
    },
  item: {
      type: [Object, null],
      default: null
    },
});

const emit = defineEmits(["close"]);
const close = () => {
	if (!_.isEqual(startList.value, ccList.value)) {
		return alert(t("message.msg_313"))
	}
  emit("close");
};

const sharedBoxNm = ref("");
const {t} = useI18n()
const shareBoxStore = useShareBoxStore();
const {fetchShareBoxList} = shareBoxStore
const startList = ref([]);
const ccList = ref([]);
const ccEmailList = ref([]);
const mailFlag = ref(false);
const userList = ref([]);
const searchedUserList = ref([]);
const userListModal = ref(-1);
const creator = ref({});
const currentShareBox = computed(() => shareBoxStore.getCurrentShareBox);
const currentCcShareBox = computed(() => shareBoxStore.getCurrentCcShareBox);

onMounted(async() => {
	if (props.type === 'share') {
		await getUserList();
		creator.value = jwt.decodeToken(jwt.getAccessToken())
		if(props.mode != 0) {
			sharedBoxNm.value = props.item.name
		}
	}
	if(props.mode != 0){
		await getShareBoxUserList(props.type);
	}
	if(ccList.value.length == 0){
		addUser(false)
	}
});

watch(ccList, (newVal) => {
	if (newVal.length === 0) {
		addUser(false);
	}
})

const selectUser = (idx) => {
  userListModal.value = idx;
  searchedUserList.value = [...userList.value];
};

const submitAutoCompleteEmail = (index,user) => {
	ccList.value[index] = user;
	ccEmailList.value[index] = user;
	searchedUserList.value = userList.value.filter((x) => {
		return x.email.match(user) || x.name.match(user);
	});	
};

const getUserList = () => {
  userApi
    .getUserList(15)
    .then((res) => {
      const totalElements = res.data.totalElements;
      userApi.getUserList(totalElements).then((res) => {
        const filteredUsers = res.data.content.filter(user => user.email !== creator.value.email);
        userList.value = filteredUsers;
        searchedUserList.value = filteredUsers;
      });
    })
    .catch((err) => {
      console.log(err);
    });
};

const getShareBoxUserList = (type) => {
	let sharedBoxId
	if(type == 'share'){
		sharedBoxId = currentShareBox.value.sharedBoxId
	} else {
		sharedBoxId = currentCcShareBox.value.sharedBoxId
	}
  shareBoxApi
    .getShareBoxUserList(sharedBoxId)
    .then((res) => {
      startList.value = res.data.map((res) => `${res.ccEmail}`);
      ccList.value = res.data.map((res) => `${res.ccEmail}`);
      ccEmailList.value = res.data.map((res) => res.ccEmail);
    })
    .catch((err) => {
      console.log(err);
    });
};

const userInfo = (user) => {
  const index = userListModal.value;

  if (typeof index === 'number' && index >= 0 && index < ccList.value.length) {
    ccList.value[index] = user.userEmail;
    ccEmailList.value[index] = user.userEmail;
  }

  userListModal.value = -1;
};

const onClickOutside = () => {
  userListModal.value = -1;
};

const addUser = (e) => {
	if(e && ccList.value.length == 0){
		ccList.value.push("")
		ccEmailList.value.push("")
	}
	ccList.value.push("")
	ccEmailList.value.push("")
}

const deleteUser = (index) => {
  if (ccList.value.length == 1 && ccList.value[0] === "" && ccEmailList.value[0] === "") {
    ccList.value = [];
    ccEmailList.value = [];
  } else {
    ccList.value.splice(index, 1);
    ccEmailList.value.splice(index, 1);
  }
  if(ccList.value.length == 0){
	addUser(false)
  }
};

const validate = () => {
  if (ccList.value.some(value => value.trim() === '')) {
    alert(t("message.msg_332"))
    return false;
  }

  const uniqueValues = [...new Set(ccList.value)];
  if (ccList.value.length !== uniqueValues.length) {
    alert(t("message.msg_333"));
    return false;
  }

  if (ccEmailList.value.includes(creator.value.email)) {
    alert(t("message.msg_316"));
    return false;
  }

  for (let email of ccEmailList.value) {
    if (!utils.checkEmail(email)) {
      alert(t("message.msg_336"));
      return false;
    }
  }

  if(sharedBoxNm.value == "") {
	alert(t("message.msg_309"));
	return false;
  }

  for (let email of ccEmailList.value) {
	if (!utils.checkEmail(email)) {
	alert(t("message.msg_336"));
	return false;
	}
  }
  return true;
}

const save = async() => {
  if(!validate()){
	return;
  }
  if(props.mode == 0){
	await modifyShareBox("", sharedBoxNm)

  } else{
	modifyShareBox(props.item.sharedBoxId, sharedBoxNm)
  }
};

const modifyShareBox = async (sharedBoxId, name) => {
    const payload = {
        sharedBoxId: sharedBoxId,
        name: name.value,
        ccEmail: ccEmailList.value,
        mailFlag: mailFlag.value,
    };
	try{
		let response = await shareBoxApi.modifyShareBox(payload)

		if (response.status === 200) {
			if(props.mode == 0){
				shareBoxStore.setShareBox({});
			}
			await fetchShareBoxList();
			emit("close");
		}
	}catch (error) {
		if (error.response.data.message != null && error.response.data.message != undefined) {
			alert(t("message.msg_203") + "\n" + error.response.data.message)
			console.log(t("message.msg_203") + "\n" + error.response.data.message)
		} else {
			alert(error)
		}
	}
}

const deleteShareBox = async () => {
	if(confirm(t("message.msg_310"))){
		try{
			let response = await shareBoxApi.deleteShareBox(currentShareBox.value.sharedBoxId, mailFlag.value)

			if (response.status === 200) {
				close();
				shareBoxStore.setShareBox({});
				await fetchShareBoxList();
			} else {
				console.log(t("message.msg_202"))
			}
		}catch (error) {
			if (error.response.data.message != null && error.response.data.message != undefined) {
				alert(t("message.msg_203") + "\n" + error.response.data.message)
				console.log(t("message.msg_203") + "\n" + error.response.data.message)
			} else {
				alert(error)
			}
		}
	}
}

</script>

<style scoped src="@/assets/css/pc/share.css">
.layout {
  width: 30em;
  position: relative;
  z-index: 1;
}
input {
  height: 2em;
  margin: 1em 0.2em;
  padding: 0 0.4em;
  font-weight: 500;
  border: 0.1em solid #c6c6c6;
}
.name {
  width: 10em;
}
.email {
  width: 20em;
}
.user {
  border: 0.1em solid black;
  height: 1.5em;
  display: inline-block;
  margin: 0.4em 0.4em 0 0;
}
.list {
  background-color: blue;
  width: 26.8em;
  position: absolute;
  z-index: 10;
  left: 0.2em;
  top: 2.65em;
}
.inputBox {
  display: inline-block;
  position: relative;
  z-index: 2;
}
</style>

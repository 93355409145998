import constant from "@/common/constants"
import http from "@/common/http"
import axios from "axios"
import utils from "@/common/util"

export default {
	getCompanyList: () => {
		var url = constant.GET_COMPANYLIST_URL
		return axios.get(url)
	},
	getCompanyInfo: () => {
		var url = constant.GET_COMPANY_URL
		return http.get(url)
	},
	saveCompanyInfo: (payload) => {
		var url = constant.SAVE_COMPANYINFO_URL
		return http.put(url, payload)
	},
	getLicense: (payload) => {
		var url = constant.GET_LICENSE_URL
		return http.post(url, payload)
	},
	getCompanyServiceList: (filter) => {
		var url = utils.makeUrl(constant.GET_COMPANY_SERVICE_LIST_URL, filter)
		return http.get(url)
	},
	getUploaderList: (companyId) => {
		var url = utils.makeUrl(constant.GET_UPLOADER_LIST_URL, {companyId: companyId})
		return http.get(url)
	},
	deleteUploader: (payload) => {
		var url = constant.DELETE_UPLOADER_URL
		return http.post(url, payload)
	},
	registerServicePlan: (companyId, payload) => {
		var url = constant.POST_SERVICE_PLAN_URL.replace("${companyId}", companyId)
		return http.post(url, payload)
	},
	putServicePlan: (companyId, payload) => {
		var url = constant.PUT_SERVICE_PLAN_URL.replace("${companyId}", companyId)
		return http.put(url, payload)
	},
	deleteCompany: (companyId) => {
		var url = constant.DELETE_COMPANY_URL.replace("${id}", companyId)
		return http.delete(url)
	},
}

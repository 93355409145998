<template>
	<section class="main_container">
		<article class="main_left" :style="{width: infoFlag ? '74%' : '100%'}">
			<div class="title">{{ t("message.msg_074") }}</div>
			<div class="left_title">
				<div class="list_btns">
					<button class="list_up" @click="toggleFilter"></button>
				</div>
				<div class="list_search">
					<div class="list_select">
						<select id="batchRequest" v-model="selectedBatch" name="batchRequest">
							<option v-for="batchFunc in batchFuncs" :key="batchFunc.value" :value="batchFunc.value">{{ batchFunc.text }}</option>
						</select>
						<button @click.stop.prevent="onClickBatchFunc">{{ t("message.msg_230") }}</button>
					</div>
					<p class="list_total">{{ t("message.msg_232") }}<span>: {{ reportStore.getApprovalReportPageInfo.totalElements }}</span></p>
					<button class="list_reset" @click.stop.prevent="reportStore.fetchApprovalReportList"></button>
				</div>
			</div>

			<div class="left_list_table">
				<table>
					<thead>
						<tr>
							<td style="width: 1%;"><input v-model="chkSelectAll" type="checkbox" @change="selectAll" /></td>
							<td style="width: 20%;"><span @click.stop.prevent="updateSort('reportNm')">{{ t("message.msg_018") }}</span> <img :src="getSortIcon('reportNm')" alt="" /></td>
							<td><span @click.stop.prevent="updateSort('partnerNm')">{{ t("message.msg_031") }}</span> <img :src="getSortIcon('partnerNm')" alt="" /></td>
							<td><span @click.stop.prevent="updateSort('price')">{{ t("message.msg_085") }}</span> <img :src="getSortIcon('price')" alt="" /></td>										
							<td><span @click.stop.prevent="updateSort('approvalStatus')">{{ t("message.msg_052") }}</span> <img :src="getSortIcon('approvalStatus')" alt="" /></td>						
							<td><span @click.stop.prevent="updateSort('approvalDate')">{{ t("message.msg_053") }}</span> <img :src="getSortIcon('approvalDate')" alt="" /></td>
							<td><span @click.stop.prevent="updateSort('createdBy')">{{ t("message.msg_040") }}</span> <img :src="getSortIcon('createdBy')" alt="" /></td>	
							<td></td>
						</tr>
					</thead>
					<tbody v-if="reportList.length!=0">
						<template v-for="report in reportList" :key="report.reportId"> 
							<tr :class="{selected: isSelected(report)}" @click="clickReport($event, report.reportId)">
								<th><input v-model="chkSelect[report.reportId]" type="checkbox" @change="selectReport(report.reportId)" /></th>
								<th>{{ report.reportNm }}</th>
								<th>{{ report.partnerNm }}</th>
								<th>{{ report.price }}</th>
								<th>{{ getApprovalStatusText(report.approvalStatus) }}</th>
								<th v-if="report.approvalDate != null && report.approvalDate != undefined">{{ format(report.approvalDate, "yyyy-MM-dd HH:mm:ss") }}</th>
								<th v-else></th>
								<th>{{ report.createdBy }}</th>
								<th>
									<div class="list_btn">
										<button class="list_approve" :disabled="report.approvalStatus != 'READY'" :title="funcList[0].text" @click.stop.prevent="onClickFunc(report.reportId, funcList[0].value)"></button> <!-- 승인 -->
										<button class="list_deny" :disabled="report.approvalStatus != 'READY'" :title="funcList[1].text" @click.stop.prevent="onClickFunc(report.reportId, funcList[1].value)"></button> <!-- 부인 -->
										<div class="list_modal">
											<button class="list_more" @click.stop.prevent="clickMore($event)"></button>
											<div class="modal">
												<ul v-on-click-outside="onClickOutside">
													<li><a @click.stop.prevent="onClickFunc(report.reportId, funcList[0].value)">{{ funcList[0].text }}</a></li>
													<li><a @click.stop.prevent="onClickFunc(report.reportId, funcList[1].value)">{{ funcList[1].text }}</a></li>
													<li><a @click.stop.prevent="onClickDownload(report, downloadType[0].value)">{{ downloadType[0].text }}</a></li>
													<li><a @click.stop.prevent="onClickDownload(report, downloadType[1].value)">{{ downloadType[1].text }}</a></li>
													<li v-if="report.fileCount > 1"><a @click.stop.prevent="onClickDownload(report, downloadType[2].value)">{{ downloadType[2].text }}</a></li>
												</ul>
											</div>
										</div>
									</div>
								</th>
							</tr>
						</template>                           
					</tbody>
				</table>
			</div>
			<CommonPagination v-if="totalPages != undefined" ref="pagination" :total-pages="totalPages" @move-page="movePage" />
		</article>
		<article v-if="infoFlag" class="main_right">
			<ReportInfoMain @close-info="closeInfo" />
		</article>
	</section>
</template>
<script setup>
import {useI18n} from "vue-i18n"
const {t} = useI18n()
import {ref, watch, onMounted, defineEmits} from "vue"
import {useReportStore} from "@/store/reportStore"
import ReportInfoMain from "@/components/report/reportInfos/ReportInfoMain"
import CommonPagination from "@/components/common/CommonPagination"
import {useFunc, downloadReport} from "@/composable/report"
import _ from "lodash"
import jwt from "@/common/jwt"
import {format} from "date-fns"
import { vOnClickOutside } from '@vueuse/components'
import icons from "@/common/icons"

const batchFuncs = [
	{value: "APPROVE", text: t("message.msg_072")},
	{value: "DISAPPROVE", text: t("message.msg_073")}
]

const funcList = [
	{value: "APPROVE", text: t("message.msg_072")},
	{value: "DISAPPROVE", text: t("message.msg_073")}
]

const downloadType = [
	{value: "pdf", text: t("message.msg_250")},
	{value: "all", text: t("message.msg_058")},
	{value: "dl", text: t("message.msg_233")}
	//{value: "csv", text: t("message.msg_239")},
]

const reportStore = useReportStore()
const {fetchReportHistory, setReportInfo} = reportStore
const chkSelect = ref({})
const chkSelectAll = ref(false)
const reportList = ref([])
const selectedBatch = ref(batchFuncs[0].value)
const selectedReportList = ref([])

const infoFlag = ref(false)
const sortItem = ref("modifiedDate")
const sortDirection = ref("desc")
const sortCount = ref(0)
const totalPages = ref(1)
const pagination = ref(null)

const emit = defineEmits(["toggle-filter"])

const onClickBatchFunc = async () => {
	if (selectedReportList.value.length <= 0) {
		alert(t("message.msg_249"))
		return
	}
	var payload = selectedReportList.value
	if (selectedBatch.value == funcList[0].value || selectedBatch.value == funcList[1].value) {
		payload = []
		selectedReportList.value.forEach((r) => {
			var p = {
				reportId: r,
				approvalYn: selectedBatch.value == funcList[0].value,
				approvalBy: jwt.getUserType(jwt.getAccessToken()).email,
				approvalMemo: "",
			}
			payload.push(p)
		})
	}
	await useFunc(payload, selectedBatch.value)
	clear()
}

const onClickFunc = async (reportId, opt) => {
	var payload = reportId
	if (opt == funcList[0].value || opt == funcList[1].value) {
		payload = {
			reportId: reportId,
			approvalYn: opt == funcList[0].value,
			approvalBy: jwt.getUserType(jwt.getAccessToken()).email,
			approvalMemo: "",
		}
	}
	await useFunc([payload], opt)
	clear()
}

const clear = () => {
	setReportInfo("")
	selectedReportList.value = []
	chkSelect.value = []
	chkSelectAll.value = false
}

const onClickDownload = (report, type) => {
	downloadReport(report, type)
}

const selectReport = (reportId) => {
	var flag = chkSelect.value[reportId]
	if (flag) {
		selectedReportList.value.push(reportId)
		if (selectedReportList.value.length == reportList.value.length) {
			chkSelectAll.value = true
		}
	} else {
		chkSelectAll.value = false
		selectedReportList.value.splice(selectedReportList.value.findIndex((r) => r === reportId), 1)
	}
}

const selectAll = () => {
	if (chkSelectAll.value) {
		selectedReportList.value = reportList.value.map((r) => r.reportId)
	} else {
		selectedReportList.value = []
	}

	for(let r of reportList.value) {
		chkSelect.value[r.reportId] = chkSelectAll.value
	}
}

onMounted(async() => {
	await reportStore.fetchApprovalReportList()
	reportList.value = _.cloneDeep(reportStore.getApprovalReportList)
	totalPages.value = reportStore.getApprovalReportPageInfo.totalPages
})

watch(() =>	reportStore.getApprovalReportList, (newValue) => {
	reportList.value = _.cloneDeep(newValue)
	totalPages.value = reportStore.getApprovalReportPageInfo.totalPages
	pagination.value.setPage(reportStore.getApprovalReportPageInfo.currentPage)
})

watch(() => reportStore.getApprovalReportFilter, (newValue) => {
	if (newValue.sort != (sortItem.value + "," + sortDirection.value)) {
		sortDirection.value = "desc"
		sortItem.value = "modifiedDate"
		sortCount.value = 0
	} //filter reset 시 default value로 변경
})

const clickReport = (evt, reportId) => {
	if(evt.target.type != undefined && evt.target.type == "checkbox") {
		return
	}
	setReportInfo(reportId)
	fetchReportHistory(reportId)
	infoFlag.value = true
}

const toggleFilter = () => {
	emit("toggle-filter")
}

const closeInfo = () => {
	infoFlag.value = false
}

const movePage = async (payload) => {
	await reportStore.setApprovalReportFilter({page: payload.page - 1})
	await reportStore.fetchApprovalReportList()
}

const updateSort = async (item) => {
	if (sortItem.value != item) {
		sortCount.value = 0
	}
	sortItem.value = item
	sortCount.value++

	if (sortCount.value == 1) {
		sortDirection.value = "asec"
	} else if (sortCount.value == 2) {
		sortDirection.value = "desc"
	}else if (sortCount.value == 3) {
		sortCount.value = 0
		sortItem.value = null
		sortDirection.value = null
	}

	if (sortItem.value != null) {
		await reportStore.setApprovalReportFilter({sort: sortItem.value + "," + sortDirection.value})
		await reportStore.fetchApprovalReportList()
	}
}

const getSortIcon = (item) => {
  if (sortItem.value === item) {
    return sortCount.value == 1 ? icons.tableUp : icons.tableDown;
  }
  return "";
}

const getVisibility = (item) => {
  if (sortItem.value === item) {
    return 'visible';
  }
  return 'hidden';
}

const clickMore = (event) => {
	event.stopPropagation()
	var modal = event.target.nextElementSibling
	var flag = modal.style.display == "none" || modal.style == undefined || modal.style.display == ""
	if (flag){
		modal.style.display = "block"
	} else {
		modal.style.display = "none"
	}
}

const onClickOutside = (event) => {
	document.querySelectorAll(".modal").forEach((modal) => {
		var icon = modal.previousElementSibling
		if(event.target != icon){
			modal.style.display = "none"
		}
	})
}

const getApprovalStatusText = (status) => {
	if (status == "READY") {
		return t("message.msg_206")
	} else if (status == "APPROVED") {
		return t("message.msg_072")
	} else if (status == "DENIED") {
		return t("message.msg_073")
	}
	return ""
}

const isSelected = (report) => {
	return _.isEqual(reportStore.getCurrentReport, report) && infoFlag.value
}
</script>
<style scoped src="@/assets/css/pc/reportList.css">
</style>

<style scoped>
.selected {
	background-color: var(--vc-gray-100);
}
</style>
<template>
	<div id="sideMenu">
		<button class="side_close_btn" @click="closeFilter"></button>
		<button class="side_reset_btn" @click="clearFilter"></button>
		<div class="side_list">
			<div class="side_list_cont">
				<div class="side_title open_title">{{ t("message.msg_608") }}</div>
				<input v-model="filters.reportCd" type="text" />
			</div>
			<div class="side_list_cont">
				<div class="side_title">{{ t("message.msg_609") }}</div>
				<input v-model="filters.comment" type="text" />
			</div>
			<div class="side_list_cont">
				<div class="side_title">{{ t("message.msg_610") }}</div>
				<select v-model="filters.uploadMode">
					<option>{{ t("message.msg_623") }}</option>
					<option>{{ t("message.msg_624") }}</option>
					<option value="">{{ t("message.msg_563") }}</option>
				</select>
			</div>
			<button class="search" @click="fetchUploadOptionList">{{ t("message.msg_013") }}</button>
		</div>
	</div>
</template>

<script setup>
import {useUploadStore} from "@/store/uploadStore"
import {useI18n} from "vue-i18n"
import {ref, defineEmits, onUnmounted} from "vue"

const emit = defineEmits(["toggle-filter"])

const {t} = useI18n()
const uploadStore = useUploadStore()

const filters = ref(
{
    reportCd: "",
    comment: "",
    uploadMode: "",
})

onUnmounted(() => {
	clearFilter()
	fetchUploadOptionList()
})

const clearFilter = () => {
	filters.value = {
		reportCd: "",
		comment: "",
		uploadMode: "",
	}
}

const fetchUploadOptionList = async () => {
	let payload = filters.value
	payload.page = 0
    await uploadStore.setUploadOptionFilter(payload)
	await uploadStore.fetchUploadOptionList()
}

const closeFilter = () => {
	emit("toggle-filter")
}
</script>

<style scoped src="@/assets/css/pc/upload.css">
</style>
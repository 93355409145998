<template>
	<div id="sideMenu">
		<button class="side_close_btn" @click="closeFilter"></button>
		<button class="side_reset_btn" @click="clearFilter"></button>
		<div class="side_list">
			<div class="side_list_cont">
				<div class="side_title open_title">{{ t("message.msg_510") }}</div>
				<input v-model="filters.partnerCompanyName" type="text" />
			</div>
			<div class="side_list_cont">
				<div class="side_title">{{ t("message.msg_559") }} {{ t("message.msg_502") }}</div>
				<input v-model="filters.partnerUserName" type="text" />
			</div>
			<div class="side_list_cont">
				<div class="side_title">{{ t("message.msg_503") }}</div>
				<input v-model="filters.partnerEmail" type="text" />
			</div>
			<div class="side_list_cont">
				<div class="side_title">{{ t("message.msg_560") }}</div>
				<div class="side_check">
					<label class="container">{{ t("message.msg_561") }} 
						<input v-model="filters.requestFlag" type="radio" name="radio" value="true" />
						<span class="checkmark"></span>
					</label>
					<label class="container">{{ t("message.msg_562") }}
						<input v-model="filters.requestFlag" type="radio" name="radio" value="false" />
						<span class="checkmark"></span>
					</label>
					<label class="container">{{ t("message.msg_563") }}
						<input v-model="filters.requestFlag" type="radio" name="radio" value="" />
						<span class="checkmark"></span>
					</label>
				</div>
			</div>
			<div class="side_list_cont">
				<div class="side_title">{{ t("message.msg_564") }}</div>
				<div class="side_date">
					<VDatePicker v-model="filters.requestFrom" :disabled-dates="disabledDatesRequestFrom" :masks="masks" :popover="popover" :color="selectedColor"> 
						<template #default="{ inputValue, inputEvents }">
							<input :value="inputValue" :placeholder="t('message.msg_086')" v-on="inputEvents" />
						</template>
					</VDatePicker>
					~
					<VDatePicker v-model="filters.requestTo" :disabled-dates="disabledDatesRequestTo" :masks="masks" :popover="popover" :color="selectedColor">
						<template #default="{ inputValue, inputEvents }">
							<input :value="inputValue" :placeholder="t('message.msg_086')" v-on="inputEvents" />
						</template>
					</VDatePicker>
				</div>
			</div>
			<div class="side_list_cont">
				<div class="side_title">{{ t("message.msg_517") }}</div>
				<div class="side_check">
					<label class="container">{{ t("message.msg_561") }}
						<input v-model="filters.registerFlag" value="true" type="radio" checked="checked" name="radio2" />
						<span class="checkmark"></span>
					</label>
					<label class="container">{{ t("message.msg_562") }}
						<input v-model="filters.registerFlag" value="false" type="radio" name="radio2" />
						<span class="checkmark"></span>
					</label>
					<label class="container">{{ t("message.msg_563") }}
						<input v-model="filters.registerFlag" value="" type="radio" name="radio2" />
						<span class="checkmark"></span>
					</label>
				</div>
			</div>
			<div class="side_list_cont">
				<div class="side_title">{{ t("message.msg_566") }}</div>
				<div class="side_date">
					<VDatePicker v-model="filters.registerFrom" :disabled-dates="disabledDatesRegisterFrom" :masks="masks" :popover="popover" :color="selectedColor"> 
						<template #default="{ inputValue, inputEvents }">
							<input :value="inputValue" :placeholder="t('message.msg_086')" v-on="inputEvents" />
						</template>
					</VDatePicker>
					~
					<VDatePicker v-model="filters.registerTo" :disabled-dates="disabledDatesRegisterTo" :masks="masks" :popover="popover" :color="selectedColor">
						<template #default="{ inputValue, inputEvents }">
							<input :value="inputValue" :placeholder="t('message.msg_086')" v-on="inputEvents" />
						</template>
					</VDatePicker>
				</div>
			</div>


			<button class="search" @click="fetchPartnerList()">{{ t("message.msg_013") }}</button>
		</div>
	</div>
</template>
<script setup>
import {usePartnerStore} from "@/store/partnerStore"
import {useI18n} from "vue-i18n"
import {ref, defineEmits, onUnmounted, watch} from "vue"
import {format} from "date-fns"

const emit = defineEmits(["toggle-filter"])
const {t} = useI18n()
const masks = ref({ input: "YYYY-MM-DD" });
const selectedColor = ref('green');
const popover= ref({
				visibility: "focus",
				placement: "bottom",
				autoHide: "boolean",
			});
const partnerStore = usePartnerStore()
const filters = ref(
{
    partnerCompanyName: "",
    partnerUserName: "",
    partnerEmail: "",
    registerFlag: "",
    requestFlag: "",
    requestFrom: "",
    requestTo: "",
    registerFrom: "",
    registerTo: "",
    size: 15,
    page: 0,
    sort: "",
})

const disabledDatesRequestFrom = ref(null)
const disabledDatesRequestTo = ref(null)
const disabledDatesRegisterFrom = ref(null)
const disabledDatesRegisterTo = ref(null)

onUnmounted(() => {
	clearFilter()
	fetchPartnerList()
})

watch(() => filters.value.requestFrom, (newValue) => {
	if (newValue == null) {
		disabledDatesRequestTo.value = null
	} else {
		let date = new Date(newValue)
		disabledDatesRequestTo.value = [{end: date.setDate(date.getDate() - 1)}]
	}
})

watch(() => filters.value.requestTo, (newValue) => {
	if (newValue == null) {
		disabledDatesRequestFrom.value = null
	} else {
		let date = new Date(newValue)
		disabledDatesRequestFrom.value = [{start: date.setDate(date.getDate() + 1)}]
	}
})

watch(() => filters.value.registerFrom, (newValue) => {
	if (newValue == null) {
		disabledDatesRegisterTo.value = null
	} else {
		let date = new Date(newValue)
		disabledDatesRegisterTo.value = [{end: date.setDate(date.getDate() - 1)}]
	}
})

watch(() => filters.value.registerTo, (newValue) => {
	if (newValue == null) {
		disabledDatesRegisterFrom.value = null
	} else {
		let date = new Date(newValue)
		disabledDatesRegisterFrom.value = [{start: date.setDate(date.getDate() + 1)}]
	}
})

const fetchPartnerList = async () => {
	let payload = filters.value
	payload.requestFrom = makeDateStr(filters.value.requestFrom, true)
	payload.requestTo = makeDateStr(filters.value.requestTo, false)
	payload.registerFrom = makeDateStr(filters.value.registerFrom, true)
	payload.registerTo = makeDateStr(filters.value.registerTo, false)
	payload.page = 0
    await partnerStore.setPartnerFilter(payload)
	await partnerStore.fetchPartnerList()
}

const makeDateStr = (date, flag) => {
        if(date == null || date == undefined || date == "") {
            return ""
        }
        let dateStr = format(date, "yyyy-MM-dd")
        if (flag) {
            return `${dateStr} 00:00:00`
        } else {
            return `${dateStr} 23:59:59`
        }
        
}

const clearFilter = () => {
	filters.value = {
		partnerCompanyName: "",
        partnerUserName: "",
        partnerEmail: "",
        registerFlag: "",
        requestFlag: "",
        requestFrom: "",
        requestTo: "",
        registerFrom: "",
        registerTo: "",
        size: "",
        page: 0,
        sort: "",
	}
}

const closeFilter = () => {
	emit("toggle-filter")
}
</script>
<style scoped src="@/assets/css/pc/account.css">
</style>
import constant from "@/common/constants"
import axios from "axios"
import http from "@/common/http"
import utils from "@/common/util"

export default {
	login: (id, pwd) => {
		var url = constant.USER_LOGIN_URL
		var temp = {email: id, password: pwd}
		var payload = utils.encrypt(JSON.stringify(temp))
		return axios.post(url, {payload: payload})
	},
	sendRegistEmail: (email) => {
		var cryptedemail = utils.encryptUrl(email)
		var temp = {email: email, cryptedEmail: cryptedemail}
		var payload = utils.encrypt(JSON.stringify(temp))
		var url = constant.USER_RESISTEMAIL_URL
		return axios.post(url, {payload: payload}, {headers: {"content-type": "application/json", "Referer-path": location.origin}})
	},
	joinUser: (payload) => {
		var url = constant.USER_JOIN_URL
		return axios.post(url, payload)
	},
	getUserInfo: () => {
		var url = constant.GET_USER_URL
		return http.get(url)
	},
	saveUserInfo: (payload) => {
		var url = constant.SAVE_USERINFO_URL
		return http.put(url, payload)
	},
	saveUserInfoByAdim: (payload, id) => {
		var url = constant.SAVE_USERINFO_BY_ADMIN_URL.replace("${id}", id)
		return http.put(url, payload)
	},
	saveNewPassword: (payload) => {
		var url = constant.SAVE_NEWPASSWORD_URL
		return http.put(url, payload)
	},
	getCompanyUserList: () => {
		var url = constant.GET_COMPANY_USERLIST_URL
		return http.get(url)
	},
	getUserInfobyId: (id) => {
		var url = constant.GET_USERBYID_URL.replace("${id}", id)
		return http.get(url)
	},
	getUserList: (size) => {
		var url = constant.GET_USER_LIST_URL.replace("${size}", size)
		return http.get(url)
	},
	sendNewPasswordEmail: (email) =>{
		var temp = {email: email}
		var payload = utils.encrypt(JSON.stringify(temp))
		var url = constant.USER_GET_NEW_PASSWORD_URL
		return axios.post(url, {payload: payload})
	},
	deleteUser : () => {
		var url = constant.DELETE_USER_URL
		return http.delete(url)
	},
	deleteUserByAdmin : (id) => {
		var url = constant.DELETE_USER_BY_ADMIN_URL.replace("${id}", id)
		return http.delete(url)
	},
}

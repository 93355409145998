<template>
	<header>
		<div class="headerWrap">
			<div class="header_logo">
				<h1 class="logo">
					<a>
						<img :src="$icons.logo" alt="logo" />
					</a>
				</h1>
			</div>
			<div class="header_nav">
				<ul>
					<li v-if="showMenu(constant.ROLE_TYPE.SENDER)">
						<a><img :src="$icons.upload" alt="upload" />{{ t("message.msg_600") }}</a>
						<ul class="submenu">
							<router-link to="/upload"><li><a>{{ t("message.msg_641") }}</a></li></router-link>
							<router-link to="/uploadoption"><li><a>{{ t("message.msg_607") }}</a></li></router-link>
						</ul>
					</li>
					<li v-if="showMenu(constant.ROLE_TYPE.SENDER)"><router-link to="/report"><a><img :src="$icons.report" alt="send" />{{ t("message.msg_201") }}</a></router-link></li>
					<li v-if="showMenu(constant.ROLE_TYPE.APPROVER)"><router-link to="/approval"><a><img :src="$icons.approval" alt="approval" />{{ t("message.msg_074") }}</a></router-link></li>
					<li v-if="showMenu(constant.ROLE_TYPE.MANAGER)"><router-link to="/partner"><a><img :src="$icons.partner" alt="account" />{{ t("message.msg_628") }}</a></router-link></li>
					<li v-if="showMenu(constant.ROLE_TYPE.USER)"><router-link to="/received"><a><img :src="$icons.received" alt="reception" />{{ t("message.msg_010") }}</a></router-link></li>
					<li v-if="showMenu(constant.ROLE_TYPE.USER)">
						<a><img :src="$icons.share" alt="share" />{{ t("message.msg_011") }}</a>
						<ul class="submenu">
							<router-link :to="{ name: 'shared-box-main', params: { type: 'share' } }"><li><a>{{ t("message.msg_303") }}</a></li></router-link>
							<router-link :to="{ name: 'shared-box-main', params: { type: 'received' } }"><li><a>{{ t("message.msg_304") }}</a></li></router-link>
						</ul>
					</li>
				</ul>
			</div>
			<div class="header_admin">
				<!-- <button class="setting"></button> -->
				<a href="#" class="setting">
					<img :src="$icons.setting" alt="" @click.stop.prevent="clickMore($event)" />
					<div class="modal">
						<ul v-on-click-outside="onClickOutside">
							<li @click="logout">{{ t("message.msg_642") }}</li>
							<li id="settingMenu" @click="moveSetting('/personalsetting')">{{ t("message.msg_501") }}</li>
							<li v-if="showMenu(constant.ROLE_TYPE.MANAGER)" id="settingMenu" @click="moveSetting('/company')">{{ t("message.msg_528") }}</li>
							<li id="settingMenu" @click="moveSetting('/companymember')">{{ t("message.msg_529") }}</li>
						</ul>
					</div>
				</a>
			</div>
		</div>
	</header>
</template>

<script setup>
import { ref } from "vue"
import { useI18n } from "vue-i18n";
import jwt from "@/common/jwt"
import {useRouter} from "vue-router"
import constant from "@/common/constants"
import { vOnClickOutside } from '@vueuse/components'
import IssueUploaderLicense from "@/components/setting/IssueUploaderLicense"

const { t } = useI18n();
const router = useRouter()

const clickMore = (event) => {
    event.stopPropagation()
    var modal = event.target.nextElementSibling
    var flag = modal.style.display == "none" || modal.style == undefined || modal.style.display == ""
    if (flag){
        modal.style.display = "block"
    } else {
        modal.style.display = "none"
    }
}

const logout = () => {
    jwt.deleteToken()
    router.replace("/login")
}

const moveSetting = (path) => {
    router.replace(path)
}

const showMenu = (auth) => {
    const token = jwt.getAccessToken()
    var userType = jwt.getUserType(jwt.decodeToken(token).roleType)

    if(userType.indexOf(auth) == -1)
        return false
    else
        return true
}

const onClickOutside = (event) => {
    document.querySelectorAll(".modal").forEach((modal) => {
        var icon = modal.previousElementSibling
        if(event.target != icon){
            modal.style.display = "none"
        }
    })
}
</script>

<style scoped>
/* box-sizing 초기화 */
*,
*::after,
*::before {
    box-sizing: border-box;
    list-style: none;
    margin: 0;
    padding: 0;
}

/* 폰트 초기화 */
body,
button,
input,
select,
table,
textarea {
    font-family: var(--main-font), 'AppleSDGothicNeo-Regular', 'Malgun Gothic', '맑은 고딕', 'dotum', '돋움';
}

/* 링크 초기화 */
a {
    text-decoration: none;
    color: var(--color__black);
}


/* 제목 초기화 */
h1,
h2,
h3,
h4,
h5,
h6 {
    font-weight: normal;
    font-size: 1rem;
}


/* 이미지 초기화 */
img {
    width: 100%;
    vertical-align: top;
}

/* 보더 초기화 */
img,
fieldset,
button {
    border: 0;
}

/* 테이블 초기화 */
table {
    width: 100%;
    border-spacing: 0;
    border-collapse: collapse;
}


:root {
    --main-font: 'Noto Sans KR', sans-serif;

    --main-color: #1a8758;
    --mainBg-color: #272727;

    --black: #000;
    --white: #fff;

    font-size: 16px;
    line-height: 1.5;
    font-weight: 400;
}

body {
    min-width: 1024px;
}

/*익스플로러 기본 화살표 없애기*/
select::-ms-expand {
    display: none;
}

/*화살표 기본 css 없애기*/
select {
    -o-appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background: url('@/assets/img/pc/select_down.svg') no-repeat right 15px center;
    padding-left: 15px;
    outline: none;
    border: solid 1px #e1e1e1;
}

input {
    border: solid 1px #e1e1e1;
}

header {
    position: relative;
    z-index: 2;
}

.headerWrap {
    background-color: var(--mainBg-color);
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: stretch;
    height: 100px;
}

.headerWrap .header_logo {
    background-color: var(--main-color);
    width: 300px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-top-right-radius: 100px;
    border-bottom-right-radius: 100px;
}

.header_logo .logo {
    width: 75%;
    margin-right: 1rem;
}

.header_logo .logo a {
    display: block;
}

.headerWrap .header_nav {
    width: 60%;
    display: flex;
}

.header_nav ul {
    display: flex;
    flex-direction: row;
    align-items: stretch;
    justify-content: space-between;
    width: 100%;
}

.header_nav>ul>li {
    position: relative;
    width: 16.66%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.header_nav>ul>li:hover .submenu {
    display: block;
}
.header_nav>ul>li a {
    display: flex;
    width: 100%;
    height: 100%;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    color: var(--white);
    font-weight: 600;
    font-size: 16px;    
}

.header_nav>ul>li a img {
    width: 21px;
    margin-right: 10px;
}

.header_nav>ul>li>ul {
    position: absolute;
    left: 0;
    top: 98px;
    height: auto !important;
    background-color: var(--mainBg-color);
    width: 100%;
    text-align: center;
    display: none;
    padding-bottom: 20px !important;
}

.header_nav ul li ul li a {
    padding: 10px 20px;
    display: block;
}

.header_nav ul li ul li a:hover {
    color: var(--main-color);
}


.headerWrap .header_admin {
    width: 300px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    margin-right: 2rem;
}

.header_admin a {
    position: absolute;
    display: block;
    margin-left: 1.1rem;
}

.header_admin a img {
    width: 25px;
}

.header_admin a.profile img {
    width: 36px;
}

.modal {
    position: absolute;
    width: 500%;
    background-color: var(--white);
    box-shadow: 1px 1px 4px #00000033;
    border-radius: 10px;
    padding: 5px 0;
    left: -350%;
    display: none;
    z-index: 5;
    height: auto;
}

.modal li {
    font-size: 0.8rem;
    padding: 0px 10px;
    display: block;
    margin: 0;
    font-weight: bold;
}

#settingMenu{
    margin-top: 10px;
}
@media screen and (max-width: 1330px) {
    .headerWrap {
        height: 70px;
    }

    .headerWrap .header_logo {
        width: 230px;
    }

    .headerWrap .header_nav {
        width: 70%;
        margin: 0 1.5rem;
    }


    .header_nav ul li a {
        font-size: 11px;
    }

    .header_nav ul li a img {
        width: 15px;
    }


    .header_nav>ul>li>ul {
        top: 70px;
    }

    .header_nav>ul>li>ul>li>a {
        padding: 10px;
        display: block;
    }

    .headerWrap .header_admin {
        width: auto;
    }

    .header_admin a {
        margin-left: 1rem;
    }

    .header_admin a img {
        width: 20px;
    }

    .header_admin a.profile img {
        width: 30px;
    }
}
</style>
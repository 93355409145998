<template>
	<div id="joinWrap">
		<div class="join_cont join_input">
			<h2>{{ t("message.msg_532") }}</h2>
			<div class="join_text">
				<input v-model="email" :disabled="true" type="email" :placeholder="t('message.msg_503')" />
				<input v-model="name" type="text" :placeholder="t('message.msg_630')" />
				<input v-model="password" type="password" :placeholder="t('message.msg_629')" />
				<input v-model="checkPassword" type="password" :placeholder="t('message.msg_008')" />	
				<input v-model="companyName" type="text" :placeholder="t('message.msg_510')" />
				<!-- <input v-model="companyNo" type="text" :placeholder="t('message.msg_511')" /> -->
			</div>
			<div class="join_btn">
				<button @click="signUp">{{ t("message.msg_532") }}</button>
			</div>
		</div>
		<div class="join_cont join_img">
			<img :src="$icons.login" alt="" />
		</div>
	</div>
</template>
<script setup>
import userAPI from "@/api/user_api"
import companyAPI from "@/api/company_api"
import utils from "@/common/util"
import {useI18n} from "vue-i18n"
import {ref, onMounted} from "vue"
import {useRoute} from "vue-router"
import {useRouter} from "vue-router"

const companyInfoArr = ref([{name: "", companyNo: ""}])
const email = ref("")
const password = ref("")
const checkPassword = ref("")
const name = ref("")
const companyName = ref("")
const companyNo = ref("")
const {t} = useI18n()
const route = useRoute()
const router = useRouter()

onMounted(() => {
	companyAPI
		.getCompanyList()
		.then((res) => {
			companyInfoArr.value = res.data
		})
		.catch(async (error) => {
			alert(error)
		})
	email.value = utils.decryptUrl(route.query["info"])
})
const signUp = async () => {
	let payload = {
		name: name.value,
		email: email.value,
		password: password.value,
		companyName: companyName.value,
		companyNo: companyNo.value,
	}

	//24.05.10 #26352 비밀번호 글자 수 체크 추가
	if(password.value.length < 8) {
		alert(t("message.msg_002"))
	}
	else if(password.value != checkPassword.value) {
		alert(t("message.msg_009"))
	}
	//24.01.23 #25910 juank 이메일, 비밀번호, 이름 빈칸 체크
	else if (email.value != "" && password.value != "" && name.value != "") {
		//24.01.23 #25910 juank 회사이름(법인번호) 직접입력 확인
		if (companyName.value != "") {
			if (companyNo.value == "") payload.companyNo = null
			try {
				let response = await userAPI.joinUser(payload)
				if (response.status === 200) {
					alert(t("message.msg_640"))
					router.push("login")
				} else {
					alert(t("message.msg_202"))
				}
			} catch (error) {
				alert(error.response.data.message)
			}
		} else {
			alert(t("message.msg_003")) //24.01.23 #25910 juank 회사를 입력해주세요
		}
	} else {
		alert(t("message.msg_005")) //24.01.23 #25910 juank 이메일,비밀번호,이름을 확인해주세요
	}
}
</script>

<style scoped src ="@/assets/css/pc/join.css">
</style>

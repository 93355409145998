import {defineStore} from "pinia"
import {reactive, computed} from "vue"
import companyApi from "@/api/company_api"
import {useI18n} from "vue-i18n"

export const useCompanyStore = defineStore("companies", () => {
	const {t} = useI18n()

	const state = reactive({
		companyList: [],
		companyPageInfo: {},
		companyFilter: {
			size: 15,
			page: 0,
			sort: "modifiedDate,desc"
		},
	})

	const fetchCompanyList = async () => {
		try {
			let response = await companyApi.getCompanyServiceList(state.companyFilter)

			if (response.status === 200) {
				state.companyPageInfo = {totalPages: response.data.totalPages, totalElements: response.data.totalElements, currentPage: response.data.pageable.pageNumber + 1}
				state.companyList = response.data.content
			} else {
				console.log(t("message.msg_202"))
			}
		} catch (error) {
			if (error.response.data.message != null && error.response.data.message != undefined) {
				alert(t("message.msg_203") + "\n" + error.response.data.message)
				console.log(t("message.msg_203") + "\n" + error.response.data.message)
			} else {
				alert(error)
			}
		}
	}

	const setCompanyFilter = (filter) => {
		const newFilters = { ...state.companyFilter }
		for (const key in filter) {
			if (Object.prototype.hasOwnProperty.call(filter, key)) {
				newFilters[key] = filter[key]
			}
		}

		state.companyFilter = newFilters
	}

	const getCompanyList = computed(() => state.companyList)
	const getCompanyPageInfo = computed(() => state.companyPageInfo)

	return {
		fetchCompanyList,
		setCompanyFilter,
		getCompanyList,
		getCompanyPageInfo
	}
})
<template>
	<div id="Modal" style="width: 100%; height: 100%; align-items: center; display: flex; flex-direction: column;">
		<div class="input_wrap">
			<label for="">{{ t("message.msg_608") }}</label>
			<input v-model="reportCd" type="text" :disabled="true" />
		</div>
		<div class="input_wrap">
			<label for="">{{ t("message.msg_609") }}</label>
			<input v-model="comment" type="text" />
		</div>
		<!-- <div class="input_wrap">
			<label for=""><span>*</span>{{ t("message.msg_610") }}</label>
			<div class="side_check">
				<label class="container">{{ t("message.msg_624") }}
					<input v-model="uploadMode" type="radio" value="ALL" />
					<span class="checkmark"></span>
				</label>
				<label class="container">{{ t("message.msg_623") }}
					<input v-model="uploadMode" type="radio" value="PDF" />
					<span class="checkmark"></span>
				</label>
			</div>
		</div> -->
		<div class="input_wrap">
			<label for="">{{ t("message.msg_611") }}</label>
			<input v-model="fileFilter" type="text" />
		</div>
		<div class="input_wrap">
			<label for=""><span>*</span>{{ t("message.msg_612") }}</label>
			<input v-model="maxFile" type="text" placeholder="0" />
		</div>
		<div class="input_wrap">
			<label for=""><span>*</span>{{ t("message.msg_613") }}</label>
			<input v-model="defaultReportName" type="text" />
		</div>
		<div class="input_wrap">
			<label for="">{{ t("message.msg_614") }} </label>
			<input v-model="defaultMemo" type="text" />
		</div>
		<div class="input_wrap">
			<label for=""><span>*</span>{{ t("message.msg_615") }}</label>
			<input v-model="defaultRetentionPeriod" type="text" style="width: 50%;" /><select id="defaultRetentionPeriod" v-model="option1" style="width: 20%;"><option value="d">d</option><option value="w">w</option><option value="m">m</option><option value="y">y</option></select>
		</div>
		<div class="input_wrap">
			<label for=""><span>*</span>{{ t("message.msg_616") }}</label>
			<input v-model="defaultReplyPeriod" type="text" style="width: 50%;" /><select id="defaultReplyPeriod" v-model="option2" style="width: 20%;"><option value="d">d</option><option value="w">w</option><option value="m">m</option><option value="y">y</option></select>
		</div>
		<div class="input_wrap">
			<label for=""><span>*</span>{{ t("message.msg_617") }}</label>
			<div class="side_check">
				<label class="container">{{ t("message.msg_625") }}
					<input v-model="replyFlag" type="checkbox" true-value="true" false-value="" />
					<span class="checkmark"></span>
				</label>
				<label class="container">{{ t("message.msg_626") }}
					<input v-model="replyFlag" type="checkbox" true-value="false" false-value="" />
					<span class="checkmark"></span>
				</label>
			</div>
		</div>
		<!-- <div class="input_wrap">
			<label for=""><span>*</span>{{ t("message.msg_618") }}</label>
			<div class="side_check">
				<label class="container">{{ t("message.msg_625") }}
					<input v-model="replyCommentFlag" type="checkbox" true-value="true" false-value="false/" />
					<span class="checkmark"></span>
				</label>
				<label class="container">{{ t("message.msg_626") }}
					<input v-model="replyCommentFlag" type="checkbox" true-value="false" false-value="true/" />
					<span class="checkmark"></span>
				</label>
			</div>
		</div> -->
		<div class="input_wrap">
			<label for=""><span>*</span>{{ t("message.msg_619") }}</label>
			<div class="side_check">
				<label class="container">{{ t("message.msg_625") }}
					<input v-model="releaseFlag" type="checkbox" true-value="true" false-value="" />
					<span class="checkmark"></span>
				</label>
				<label class="container">{{ t("message.msg_626") }}
					<input v-model="releaseFlag" type="checkbox" true-value="false" false-value="" />
					<span class="checkmark"></span>
				</label>
			</div>
		</div>
		<div class="input_wrap">
			<label for=""><span>*</span>{{ t("message.msg_620") }}</label>
			<div class="side_check">
				<label class="container">{{ t("message.msg_625") }}
					<input v-model="approvalFlag" type="checkbox" true-value="true" false-value="" />
					<span class="checkmark"></span>
				</label>
				<label class="container">{{ t("message.msg_626") }}
					<input v-model="approvalFlag" type="checkbox" true-value="false" false-value="" />
					<span class="checkmark"></span>
				</label>
			</div>
		</div>
		<button type="submit" @click="modify">{{ t("message.msg_514") }}</button>
	</div>
</template>
<script setup>
import uploadAPI from "@/api/upload_api"
import {ref, watch, defineProps, defineEmits} from "vue"
import {useI18n} from "vue-i18n"

const {t} = useI18n()
const props = defineProps({
	selectId: {type: String, required: true}
})
const id = ref("")
const reportCd = ref("")
const comment = ref("")
const uploadMode = ref("PDF")
const fileFilter = ref("")
const maxFile = ref(0)
const defaultReportName = ref("")
const defaultMemo = ref("")
const defaultRetentionPeriod = ref(0)
const defaultReplyPeriod = ref(0)
const option1 = ref("") //defaultRetentionPeriod select option
const option2 = ref("") //defaultReplyPeriod select option
const replyFlag = ref("")
const replyCommentFlag = ref("true")
const releaseFlag = ref("")
const approvalFlag = ref("")

const emit = defineEmits(["closeModal"])

watch(() => defaultRetentionPeriod.value, (newVal) => {
	defaultRetentionPeriod.value = (String (defaultRetentionPeriod.value)).replace(/[^0-9]/g, '') //24.06.03 #26423 juank 숫자 이외에 값은 입력 안되도록
})

watch(() => defaultReplyPeriod.value, () => {
	defaultReplyPeriod.value = (String (defaultReplyPeriod.value)).replace(/[^0-9 ]/g, '') //24.06.03 #26423 juank 숫자 이외에 값은 입력 안되도록
})

watch(() => maxFile.value, () => {
	maxFile.value = (String (maxFile.value)).replace(/[^0-9 ]/g, '') //24.06.03 #26423 juank 숫자 이외에 값은 입력 안되도록
})

const closeModal = () => {
	emit("closeModal");
}

const modify = async () => {
	if(reportCd.value == "" || uploadMode.value == "" || maxFile.value == null || defaultReportName.value == "" || defaultRetentionPeriod.value == "" || option1.value == "" ||defaultReplyPeriod.value == "" || option2.value == "" || replyFlag.value == "" || releaseFlag.value == "" || approvalFlag.value == "") {
		alert(t("message.msg_627"))
        return
    }
    let payload = 
    {
        id: id.value,
        reportCd: reportCd.value,
        comment: comment.value,
        uploadMode: uploadMode.value,
        fileFilter: fileFilter.value,
        maxFile: maxFile.value*1,
        defaultReportName: defaultReportName.value,
        defaultMemo: defaultMemo.value,
        defaultRetentionPeriod: (defaultRetentionPeriod.value*1) + option1.value,
        defaultReplyPeriod: (defaultReplyPeriod.value*1) + option2.value,
        replyFlag: replyFlag.value,
        replyCommentFlag: replyCommentFlag.value,
        releaseFlag: releaseFlag.value,
        approvalFlag: approvalFlag.value,
		
    }

	try {
		let response = await uploadAPI.modifyUploadOption(payload)
		if (response.status === 200) {   
			alert(t("message.msg_533"))
			closeModal()
		}
		else{
			alert(response.status + t("message.msg_203"))
		}
	} catch (error) {
		alert(error.response.data.message)
	}
    
}
watch(
	() => props.selectId,
	(selectId) => {
		let element = document.getElementById('Modal');    
		element.scrollIntoView(false); //24.06.10 모달을 열때 스크롤을 맨위로 올리도록 수정
		if (selectId) {
			uploadAPI.getUploadOptionInfo(props.selectId)
            .then((res) => {
                id.value = res.data.id
                reportCd.value = res.data.reportCd
                comment.value = res.data.comment
                uploadMode.value = res.data.uploadMode
                fileFilter.value = res.data.fileFilter
                maxFile.value = res.data.maxFile
                defaultReportName.value = res.data.defaultReportName
                defaultMemo.value = res.data.defaultMemo
                defaultRetentionPeriod.value = res.data.defaultRetentionPeriod
                defaultReplyPeriod.value = res.data.defaultReplyPeriod
                replyFlag.value = String(res.data.replyFlag)
                replyCommentFlag.value = String(res.data.replyCommentFlag)
                releaseFlag.value = String(res.data.releaseFlag)
                approvalFlag.value = String(res.data.approvalFlag)
				let letter = ["d","w","m","y"]
				let index
				for (let i = 0; i < letter.length; i++)
				{
					index = defaultRetentionPeriod.value.indexOf(letter[i])
					if (index != -1) {
						option1.value =  defaultRetentionPeriod.value.substr(index);
						defaultRetentionPeriod.value = defaultRetentionPeriod.value.substr(0, index);
						break;
					}
				}

				for (let i = 0; i < letter.length; i++)
				{
					index = defaultReplyPeriod.value.indexOf(letter[i])
					if (defaultReplyPeriod.value.indexOf(letter[i]) != -1) {
						option2.value =  defaultReplyPeriod.value.substr(index);
						defaultReplyPeriod.value = defaultReplyPeriod.value.substr(0, index);
						break;
					}
				}							
            })
            .catch(async (error) => {
                alert(error.response.data.message);
            })
		}
	}
)
</script>

<style scoped src="@/assets/css/pc/upload.css">
</style>
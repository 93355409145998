<template>
	<div class="wrap">
		<template v-if="menu">
			<div class="menu">
				<navigation-main />
			</div>
		</template>
		<div class="contents">
			<router-view />
		</div>
	</div>
</template>

<script setup>
import NavigationMain from "@/components/menu/NavigationMain";
import {ref} from "vue"

const menu = ref(true);

const menuHandler = (show) => {
  menu.value = show;
};
</script>

<style scoped>
.wrap {
	min-width: 1024px;
}
</style>
//mobile과 pc 공통 메서드
import 'v-calendar/style.css';
import {useReportStore} from "@/store/reportStore.js"
import {ref, watch} from "vue"
import _ from "lodash"
import {format} from "date-fns"
import {ko} from "date-fns/locale"

export function useReceivedReportFilterFunctions() {
	const selectedColor = ref('green');
	const reportStore = useReportStore()
	const masks = ref({ input: "YYYY-MM-DD" });
	const popover= ref({
					visibility: "focus",
					placement: "bottom",
					autoHide: "boolean",
				});
	const filters = ref({
			readStatus: "",
			replyStatus: "",
			openDateFrom: null,
			openDateTo: null,
			dueDateFrom: null,
			dueDateTo: null,
			replyMemo: "",
			reportNm: "",
			//reportNo: "",
			//reportId: "",
			memo: "",
			minPrice: null,
			maxPrice: null,
		})

	const disabledOpenDateFrom = ref(null)
	const disabledOpenDateTo = ref(null)
	const disabledDueDateFrom = ref(null)
	const disabledDueDateTo = ref(null)

	const locale = ref(ko)

	watch(() => filters.value.openDateFrom, (newValue) => {
		if (newValue == null) {
			disabledOpenDateTo.value = null
		} else {
			let date = new Date(newValue)
			disabledOpenDateTo.value = [{end: date.setDate(date.getDate() - 1)}]
		}
	})

	watch(() => filters.value.openDateTo, (newValue) => {
		if (newValue == null) {
			disabledOpenDateFrom.value = null
		} else {
			let date = new Date(newValue)
			disabledOpenDateFrom.value = [{start: date.setDate(date.getDate() + 1)}]
		}
	})
	
	watch(() => filters.value.dueDateFrom, (newValue) => {
		if (newValue == null) {
			disabledDueDateTo.value = null
		} else {
			let date = new Date(newValue)
			disabledDueDateTo.value = [{end: date.setDate(date.getDate() - 1)}]
		}
	})

	watch(() => filters.value.dueDateTo, (newValue) => {
		if (newValue == null) {
			disabledDueDateFrom.value = null
		} else {
			let date = new Date(newValue)
			disabledDueDateFrom.value = [{start: date.setDate(date.getDate() + 1)}]
		}
	})
	
	const fetchReceivedReportList = async () => {
		let payload = _.cloneDeep(filters.value)
		payload.openDateFrom = makeDateStr(filters.value.openDateFrom, true)
		payload.openDateTo = makeDateStr(filters.value.openDateTo, false)
		payload.dueDateFrom = makeDateStr(filters.value.dueDateFrom, true)
		payload.dueDateTo = makeDateStr(filters.value.dueDateTo, false)
		payload.page = 0
		payload.sort = "createdDate,desc"
		await reportStore.setReceivedReportFilter(payload)
		reportStore.fetchReceivedReportList()
	}
	
	const makeDateStr = (date, flag) => {
		if(date == null || date == undefined || date == "") {
			return ""
		}
		let dateStr = format(date, "yyyy-MM-dd")
		if (flag) {
			return `${dateStr}T00:00:00`
		} else {
			return `${dateStr}T23:59:59`
		}
		
	}
	
	const clearFilter = () => {
		filters.value = {
			readStatus: "",
			replyStatus: "",
			openDateFrom: null,
			openDateTo: null,
			dueDateFrom: null,
			dueDateTo: null,
			replyMemo: "",
			reportNm: "",
			//reportNo: "",
			//reportId: "",
			memo: "",
			minPrice: null,
			maxPrice: null,
		}
	}

	const onChangeMinPrice = () => {
		if (filters.value.minPrice == "") {
			filters.value.minPrice = null
			return
		}
		if(filters.value.maxPrice != null) {
			if (filters.value.minPrice > filters.value.maxPrice) {
				filters.value.minPrice = filters.value.maxPrice
			}
		}
	}

	const onChangeMaxPrice = () => {
		if (filters.value.maxPrice == "") {
			filters.value.maxPrice = null
			return
		}
		if(filters.value.minPrice != null) {
			if (filters.value.maxPrice < filters.value.minPrice) {
				filters.value.maxPrice = filters.value.minPrice
			}
		}
	}

  return {
	selectedColor,
	reportStore,
	masks,
	popover,
	filters,
	disabledOpenDateFrom,
	disabledOpenDateTo,
	disabledDueDateFrom,
	disabledDueDateTo,
	locale,
	fetchReceivedReportList,
	clearFilter,
	onChangeMinPrice,
	onChangeMaxPrice
  };
}

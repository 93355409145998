<template>
	<div id="sideMenu">
		<button class="side_close_btn" @click="closeFilter"></button>
		<button class="side_reset_btn" @click="clearFilter"></button>
		<div class="side_list">
			<div class="side_list_cont">
				<div class="side_title open_title">{{ t("message.msg_217") }}</div>
				<div class="side_check">
					<label class="container">{{ t("message.msg_022") }}
						<input id="radio1" v-model="filters.readStatus" type="radio" name="readStatus" value="READ" />
						<span class="checkmark"></span>
					</label>
					<label class="container">{{ t("message.msg_023") }}
						<input id="radio2" v-model="filters.readStatus" type="radio" name="readStatus" value="READY" />
						<span class="checkmark"></span>
					</label>
				</div>
			</div>
			<div class="side_list_cont">
				<div class="side_title">{{ t("message.msg_224") }}</div>
				<div class="side_check">
					<label class="container">{{ t("message.msg_220") }}
						<input id="radio3" v-model="filters.replyStatus" type="radio" name="replyStatus" value="READY" />
						<span class="checkmark"></span>
					</label>
					<label class="container">{{ t("message.msg_221") }}
						<input id="radio4" v-model="filters.replyStatus" type="radio" name="replyStatus" value="REPLIED" />
						<span class="checkmark"></span>
					</label>
				</div>
			</div>
			<div class="side_list_cont">
				<div class="side_title">{{ t("message.msg_016") }}</div>
				<div class="side_date">
					<VDatePicker v-model="filters.openDateFrom" :disabled-dates="disabledOpenDateFrom" :masks="masks" :popover="popover" :color="selectedColor">
						<template #default="{ inputValue, inputEvents }">
							<input :value="inputValue" :placeholder="t('message.msg_086')" v-on="inputEvents" />
						</template>
					</VDatePicker>
					~
					<VDatePicker v-model="filters.openDateTo" :disabled-dates="disabledOpenDateTo" :masks="masks" :popover="popover" :color="selectedColor">
						<template #default="{ inputValue, inputEvents }">
							<input :value="inputValue" :placeholder="t('message.msg_086')" v-on="inputEvents" />
						</template>
					</VDatePicker>
				</div>
			</div>
			<div class="side_list_cont">
				<div class="side_title">{{ t("message.msg_017") }}</div>
				<div class="side_date">
					<VDatePicker v-model="filters.dueDateFrom" :disabled-dates="disabledDueDateFrom" :masks="masks" :popover="popover" :color="selectedColor">
						<template #default="{ inputValue, inputEvents }">
							<input :value="inputValue" :placeholder="t('message.msg_086')" v-on="inputEvents" />
						</template>
					</VDatePicker>
					~
					<VDatePicker v-model="filters.dueDateTo" :disabled-dates="disabledDueDateTo" :masks="masks" :popover="popover" :color="selectedColor">
						<template #default="{ inputValue, inputEvents }">
							<input :value="inputValue" :placeholder="t('message.msg_086')" v-on="inputEvents" />
						</template>
					</VDatePicker>
				</div>
			</div>
			<div class="side_list_cont">
				<div class="side_title">{{ t("message.msg_018") }}</div>
				<input v-model="filters.reportNm" type="text" />
			</div>
			<!--
			<div class="side_list_cont">
				<div class="side_title">{{ t("message.msg_020") }}</div>
				<input v-model="filters.reportId" type="text" />
			</div>
			<div class="side_list_cont">
				<div class="side_title">{{ t("message.msg_019") }}</div>
				<input v-model="filters.reportNo" type="text" />
			</div>
			-->
			<div class="side_list_cont">
				<div class="side_title">{{ t("message.msg_021") }}</div>
				<input v-model="filters.memo" type="text" />
			</div>
			<div class="side_list_cont">
				<div class="side_title">{{ t("message.msg_085") }}</div>
				<div class="side_date">
					<input v-model="filters.minPrice" min="0" type="number" @change="onChangeMinPrice" />
					~
					<input v-model="filters.maxPrice" min="0" type="number" @change="onChangeMaxPrice" />
				</div>
			</div>
			<button class="search" @click="fetchReceivedReportList">
				<img :src="$icons.search" alt="" /> {{ t("message.msg_013") }}
			</button>
		</div>
	</div>
</template>

<script setup>
import {defineEmits, onUnmounted} from "vue"
import { useReceivedReportFilterFunctions } from "@/composable/receivedReportFilter";
import {useI18n} from "vue-i18n"
const {
	selectedColor,
    reportStore,
    masks,
    popover,
    filters,
	disabledOpenDateFrom,
	disabledOpenDateTo,
	disabledDueDateFrom,
	disabledDueDateTo,
    locale,
    fetchReceivedReportList,
    clearFilter,
    onChangeMinPrice,
	onChangeMaxPrice 
} = useReceivedReportFilterFunctions();

const {t} = useI18n()

const emit = defineEmits(["toggle-filter"]);

onUnmounted(() => {
	clearFilter()
	fetchReceivedReportList()
})

const closeFilter = () => {
	emit("toggle-filter")
}
</script>
<style scoped src="@/assets/css/mobile/ticket.css">
</style>

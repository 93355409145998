<template>
	<main id="main">
		<UploadFilter @toggle-filter="toggleFilter(false)" />
		<UploadList @toggle-filter="toggleFilter(true)" />
	</main> 
</template>

<script setup>
import UploadList from "./UploadList.vue"
import UploadFilter from "./UploadFilter.vue"

const toggleFilter = (flag) => {
	var sideMenu = document.getElementById('sideMenu');
	if (flag) { //open filter
		sideMenu.style.transition = 'left 0.5s';
		sideMenu.style.left = '0';
	} else { //close filter
		sideMenu.style.left = '-450px';
	}
}
</script>
<style scoped src="@/assets/css/pc/addUpload.css">
</style>
import {createRouter, createWebHistory} from "vue-router"

import util from "@/common/util"
import ReportDeliveryMain from "../components/ReportDeliveryMain"
import NotFound from "@/404.vue"
import Login from "../components/LogIn.vue"
import Signup from "../components/SignUp.vue"
import ReportPage from "@/components/report/ReportPage"
import ReceivedReportPage from "@/components/receivedReport/ReceivedReportPage"
import PersonalSetting from "../components/setting/PersonalSetting.vue"
import Company from "../components/setting/CompanyInfo.vue"
import CompanyMember from "../components/setting/CompanyMember.vue"
import ApprovalReportListMain from "@/components/approvalReport/ApprovalReportListMain"
import Partner from "../components/partner/PartnerManagementMain.vue"
import Upload from "../components/upload/UploadMain.vue"
import UploadOption from "../components/upload/UploadOption.vue"
import constant from "@/common/constants"
import SharedBoxMain from "@/components/sharedBox/SharedBoxMain"

import AdminPageMain from "@/components/admin/AdminPageMain"
import CompanyList from "@/components/admin/CompanyList"

import MobileLogin from "@/m_components/MobileLogin.vue"
import MobileReportDeliveryMain from "../m_components/MobileReportDeliveryMain"
import MobileReceivedReportPage from "../m_components/m_receivedReport/MobileReceivedReportPage"

import Version from '@/Version.vue'

const redirectMain = () => (to, from, next) => {
	const jwt = require("@/common/jwt").default
	const token = jwt.getAccessToken()
	if (token == null || token === undefined || token.length === 0) {
		next({name: "login"})
	} else {
		var userType = jwt.getUserType(jwt.decodeToken(token).roleType)

		if (to.path != "/main") {
			if (!userType.some(type => to.meta.requireAuth.includes(type))) {
				next({name: "not-found"})
			} else {
				next()
			}
		} else {
			if (userType.includes(constant.ROLE_TYPE.SYSTEM)) {
				next({name: "company-list"})
			} //system 사용자의 경우 회사 목록으로 이동
			next({name: "received"}) //path가 main일 경우 수신함으로
		}
	}
}

// 라우터 설계
const d_routes = [
	{path: "/", redirect: "/login"},
	{
		path: "/main",
		beforeEnter: redirectMain(),
		component: ReportDeliveryMain,
		children: [
			{path: "/report", name: "report", component: ReportPage, meta: {requireAuth: [constant.ROLE_TYPE.SENDER]}}, //발신함
			{path: "/received", name: "received", component: ReceivedReportPage, meta: {requireAuth: [constant.ROLE_TYPE.USER]}}, //수신함
			{path: "/partner", name: "partner", component: Partner, meta: {requireAuth: [constant.ROLE_TYPE.USER, constant.ROLE_TYPE.MANAGER]}}, //거래처
			{path: "/personalsetting", name: "personal-setting-main", component: PersonalSetting, meta: {requireAuth: [constant.ROLE_TYPE.USER]}}, //개인설정
			{path: "/company", name: "company", component: Company, meta: {requireAuth: [constant.ROLE_TYPE.MANAGER]}}, //회사정보
			{path: "/companymember", name: "companymember", component: CompanyMember, meta: {requireAuth: [constant.ROLE_TYPE.USER]}}, //회사 구성원
			{path: "/approval", name: "approval-report-main", component: ApprovalReportListMain, meta: {requireAuth: [constant.ROLE_TYPE.APPROVER]}}, //승인
			{path: "/upload", name: "upload", component: Upload, meta: {requireAuth: [constant.ROLE_TYPE.SENDER]}}, //업로드
			{path: "/uploadoption", name: "uploadoption", component: UploadOption, meta: {requireAuth: [constant.ROLE_TYPE.SENDER]}}, //업로드 설정
			{path: "/shared/:type", name: "shared-box-main", component: SharedBoxMain, props: true, meta: {requireAuth: [constant.ROLE_TYPE.USER]}}, //공유 문서함
		],
	},
	{
		path: "/admin",
		beforeEnter: redirectMain(),
		component: AdminPageMain,
		children: [
			{path: "/company-list", name: "company-list", component: CompanyList, meta: {requireAuth: [constant.ROLE_TYPE.SYSTEM]}}
		]
	},
	{path: "/:pathMatch(.*)*", component: NotFound, meta: {requireAuth: false}},
	{path: "/login", name: "login", component: Login},
	{path: "/signup", name: "signup", component: Signup},
	{ path: '/version', name: 'version', component: Version},
	{path: "/*", name: "not-found", component: NotFound},
]

const m_routes = [
	{path: "/", redirect: "/login"},
	{
		path: "/main",
		beforeEnter: redirectMain(),
		component: MobileReportDeliveryMain,
		children: [
			{path: "/received", name: "received", component: MobileReceivedReportPage, meta: {requireAuth: [constant.ROLE_TYPE.USER]}}, //수신함 문서함
		],
	},
	{path: "/:pathMatch(.*)*", component: NotFound, meta: {requireAuth: false}},
	{path: "/login", name: "mobile-login", component: MobileLogin},
	{path: "/*", name: "not-found", component: NotFound},
]

// 라우터 생성
const md = util.getDevice()
const router = createRouter({
	history: createWebHistory(),
	routes: md.mobile() && !md.tablet() ? m_routes : d_routes,
  });

// 라우터 추출 (main.js에서 import)
export default router

<template>
	<common-modal @close-modal="closeModal">
		<p>{{ t("message.msg_704") }}</p>
		<br />
		<div v-if="company != undefined" class="explanation">
			<label>{{ t("message.msg_510") }}</label>
			<span>{{ company.name }}</span>
		</div>
		<div v-if="company != undefined" class="explanation">
			<label>{{ t("message.msg_724") }}</label>
			<span v-if="company.servicePlan == null">{{ t("message.msg_725") }}</span>
			<span v-else>{{ currentPlan.text }}, {{ company.serviceStart.replace("T", " ") }} ~ {{ company.serviceEnd.replace("T", " ") }}</span>
		</div>
		<div class="explanation">
			<label>{{ t("message.msg_701") }}</label>
			<select v-model="selectedPlan">
				<option v-for="plan in servicePlan" :key="plan.text" :value="plan.value">{{ plan.text }}</option>
			</select>
		</div>
		<div class="explanation">
			<label>{{ t("message.msg_707") }}</label>
			<input v-model="date" type="number" min="1" style="padding: 10px; font-size: 13px; border-radius: 0px;" />
			<select v-model="period" style="width: 20%">
				<option v-for="p in periodList" :key="p.text" :value="p.value">{{ p.text }}</option>
			</select>
		</div>
		<button @click="registerService">{{ t("message.msg_565") }}</button>
	</common-modal>
</template>
<script setup>
import {useI18n} from "vue-i18n"
import {ref, defineEmits, defineProps, onMounted, computed} from "vue"
import companyApi from "@/api/company_api"
import _ from "lodash"

const {t} = useI18n()
const emit = defineEmits(["close"])
const props = defineProps({
	company: {type: Object, required: true}
})

const servicePlan = ref([
	{text: t("message.msg_721"), value: "LIGHT"},
	{text: t("message.msg_723"), value: "STANDARD"},
])

const periodList = ref([
	{text: t("message.msg_726"), value: "y"},
	{text: t("message.msg_727"), value: "m"}
])

const selectedPlan = ref({})
const date = ref(1)
const period = ref({})

const currentPlan = computed(() => {
	return servicePlan.value.find((p) => p.value == props.company.servicePlan)
})

const closeModal = () => {
  emit("close");
};

const registerService = () => {
	if (_.isEmpty(selectedPlan.value) || _.isEmpty(period.value)) {
		alert(t("message.msg_557"))
		return
	}

	var payload = {
		type: selectedPlan.value,
		date: date.value,
		period: period.value,
		price: 0,
	}

	if (props.company.servicePlan == null) {
		registerNewPlan(payload)
	} else {
		modifyPlan(payload)
	}
}

const registerNewPlan = (payload) => {
	companyApi.registerServicePlan(props.company.id, payload).then(() => {
		closeModal()
	})
}

const modifyPlan = (payload) => {
	companyApi.putServicePlan(props.company.id, payload).then(() => {
		closeModal()
	})
}

</script>
<style scoped src="@/assets/css/pc/admin.css">
</style>
<style scoped>
.explanation {
	display: flex;
	flex-direction: row;
	align-items: center;
	margin-bottom: 1em;
	width: 100%;
}

.explanation span {
	margin: 0 0 1rem 0;
}

.explanation label {
	min-width: 90px;
	margin: 0 0 1rem 0;
}

.explanation select {
	width: 100%;
	padding: 10px;
	margin: 0 0 1rem 0;
}

.explanation input {
	margin: 0 0 1rem 0;
}

.explanation :nth-child(2) {
	margin-left: 1.5rem !important;
}

</style>
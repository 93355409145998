<template>
	<ul class="pagination">
		<li class="pagi first" @click="moveFirst"><a></a></li>
		<li class="pagi prev" @click="movePrev"><a></a></li>
		<template v-if="pageList.length > 0">
			<li v-for="i in pageList" :key="i" @click="movePage(i)"><a :class="{active: currentPage == i}">{{ i }}</a></li>
		</template>
		<li class="pagi next" @click="moveNext"><a></a></li>
		<li class="pagi last" @click="moveLast"><a></a></li>
	</ul>
</template>
<script setup>
import {ref, defineEmits, defineProps, onMounted, watch, defineExpose} from "vue"

const startIndex = ref(1)
const endIndex = ref(1)
const currentPage = ref(1)
const limit = ref(5)
const pageList = ref([])

const props = defineProps({
	totalPages: {type: Number, required: true}
})

const emit = defineEmits(["move-page"])

onMounted(() => {
	makePageList()
})

watch(() => props.totalPages, () => {
	currentPage.value = 1
	startIndex.value = 1
	makePageList()
})

const makePageList = () => {
	if(props.totalPages == 0) {
		endIndex.value = 1
		pageList.value = []
		pageList.value.push(1)
		return
	}

	endIndex.value = startIndex.value + limit.value <= props.totalPages ? startIndex.value + limit.value - 1 : props.totalPages
	if (endIndex.value == 0) {
		endIndex.value = 1
	}
	if (endIndex.value > props.totalPages) {
		endIndex.value = props.totalPages
	}
	pageList.value = []
	for (var i = startIndex.value; i <= endIndex.value; i++) {
		pageList.value.push(i)
	}
}

const setPage = (page) => {
	currentPage.value = page
}

const movePage = (page) => {
	if(page == currentPage.value){
		return
	}
	setPage(page)
	emit("move-page", {page: currentPage.value})
}

const movePrev = () => {
	if (currentPage.value > 1) {
		currentPage.value--
		if (startIndex.value > currentPage.value) {
			startIndex.value = currentPage.value
			makePageList()
		}
		emit("move-page", {page: currentPage.value})
	}
}

const moveNext = () => {
	if (currentPage.value < props.totalPages) {
		currentPage.value++
		if (endIndex.value < currentPage.value) {
			startIndex.value = currentPage.value - limit.value + 1
			if (startIndex.value < 1) {
				startIndex.value = 1
			}
			makePageList()
		}
		emit("move-page", {page: currentPage.value})
	}
}

const moveFirst = () => {
	if (currentPage.value > 1) {
		if (currentPage.value > startIndex.value) {
			currentPage.value = startIndex.value
		} else {
			currentPage.value = startIndex.value - limit.value
			if (currentPage.value < 1) {
				currentPage.value = 1
			}
			startIndex.value = currentPage.value
			makePageList()
		}
		emit("move-page", {page: currentPage.value})
	}
}

const moveLast = () => {
	if (currentPage.value < props.totalPages) {
		if (currentPage.value < endIndex.value) {
			currentPage.value = endIndex.value
		} else {
			currentPage.value = endIndex.value + limit.value
			if (currentPage.value > props.totalPages) {
				currentPage.value = props.totalPages
			}
			startIndex.value = currentPage.value - limit.value + 1
			makePageList()
		}
		emit("move-page", {page: currentPage.value})
	}
}

defineExpose({
	setPage
})

</script>
<style scoped src="@/assets/css/pc/reportList.css">
</style>
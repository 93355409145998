import {defineStore} from "pinia"
import {reactive, computed} from "vue"
import reportApi from "@/api/report_api"
import {useI18n} from "vue-i18n"
import {useShareBoxStore} from "@/store/shareBoxStore"
import jwt from "@/common/jwt"

export const useReportStore = defineStore("reports", () => {
	const {t} = useI18n()
	const shareBoxStore = useShareBoxStore()
	const currentShareBox = computed(() => shareBoxStore.getCurrentShareBox);
	//state
	const state = reactive({
		reportList: [],
		reportPageInfo: {},
		reportFilter: {
			size: 15,
			page: 0,
		},
		receivedReportList: [],
		receivedReportPageInfo: {},
		receivedReportFilter: {
			size: 15,
			page: 0,
		},
		approvalReportList: [],
		approvalReportPageInfo: {},
		approvalReportFilter: {
			sort: "createdDate,desc",
			size: 15,
			page: 0
		},
		reportHistory: [],
		currentReport: {},
	})

	//actions -- 발신
	const fetchReportList = async () => {
		try {
			if(jwt.getAccessToken() == null) {
				return null
			} //unmounted에서 호출 시 logout 시에도 호출되는 경우가 있어 체크
			let response = await reportApi.getReportList(state.reportFilter)

			if (response.status === 200) {
				state.reportPageInfo = {totalPages: response.data.totalPages, totalElements: response.data.totalElements, currentPage: response.data.pageable.pageNumber + 1}
				state.reportList = response.data.content
				if (state.reportList != null && state.reportList.length > 0) {
					state.currentReport = state.reportList[0]
					//fetchReportHistory(state.reportList[0].reportId)
				} else {
					state.currentReport = {}
					state.reportHistory = []
				}
			} else {
				console.log(t("message.msg_202"))
			}
		} catch (error) {
			if (error.response.data.message != null && error.response.data.message != undefined) {
				alert(t("message.msg_203") + "\n" + error.response.data.message)
				console.log(t("message.msg_203") + "\n" + error.response.data.message)
			} else {
				alert(error)
			}
		}
	}
	const setReportInfo = (reportId) => {
		const report = state.reportList.filter((reportItem) => reportItem.reportId === reportId)
		if (report.length > 0) {
			state.currentReport = report[0]
		}
	}
	const setReportFilter = (filters) => {
		const newFilters = { ...state.reportFilter }
		for (const key in filters) {
			if (Object.prototype.hasOwnProperty.call(filters, key)) {
				newFilters[key] = filters[key]
			}
		}

		state.reportFilter = newFilters
	}

	//actions -- 수신
	const fetchReceivedReportList = async () => {
		try {
			if(jwt.getAccessToken() == null) {
				return null
			} //unmounted에서 호출 시 logout 시에도 호출되는 경우가 있어 체크
			let response = await reportApi.getReceivedReportList(state.receivedReportFilter)

			if (response.status === 200) {
				state.receivedReportPageInfo = {totalPages: response.data.totalPages, totalElements: response.data.totalElements, currentPage: response.data.pageable.pageNumber + 1}
				state.receivedReportList = response.data.content
				if (state.receivedReportList != null && state.receivedReportList.length > 0) {
					state.currentReport = state.receivedReportList[0]
				} else {
					state.currentReport = {}
					state.reportHistory = []
				}
			} else {
				console.log(t("message.msg_202"))
			}
		} catch (error) {
			if (error.response.data.message != null && error.response.data.message != undefined) {
				alert(t("message.msg_203") + "\n" + error.response.data.message)
				console.log(t("message.msg_203") + "\n" + error.response.data.message)
			} else {
				alert(error)
			}
		}
	}
	const fetchCheckReceivedReportList = async () => {
		try {
			let response = await reportApi.getCheckReceivedReportList(currentShareBox.value.sharedBoxId, state.receivedReportFilter)

			if (response.status === 200) {
				state.receivedReportPageInfo = {totalPages: response.data.totalPages, totalElements: response.data.totalElements, currentPage: response.data.pageable.pageNumber + 1}
				state.receivedReportList = response.data.content
				if (state.receivedReportList != null && state.receivedReportList.length > 0) {
					state.currentReport = state.receivedReportList[0]
				} else {
					state.currentReport = {}
					state.reportHistory = []
				}
			} else {
				console.log(t("message.msg_202"))
			}
		} catch (error) {
			if (error.response.data.message != null && error.response.data.message != undefined) {
				alert(t("message.msg_203") + "\n" + error.response.data.message)
				console.log(t("message.msg_203") + "\n" + error.response.data.message)
			} else {
				alert(error)
			}
		}
	}
	function setReceivedReportInfo(reportId) {
		const report = state.receivedReportList.filter((reportItem) => reportItem.reportId === reportId)
		if (report.length > 0) {
			state.currentReport = report[0]
		}
	}
	const setReceivedReportFilter = (filters) => {
		const newFilters = { ...state.receivedReportFilter }
		for (const key in filters) {
			if (Object.prototype.hasOwnProperty.call(filters, key)) {
				newFilters[key] = filters[key]
			}
		}

		state.receivedReportFilter = newFilters
	}

	//actions -- 승인
	const fetchApprovalReportList = async () => {
		try {
			if(jwt.getAccessToken() == null) {
				return null
			} //unmounted에서 호출 시 logout 시에도 호출되는 경우가 있어 체크
			let response = await reportApi.getApprovalReportList(state.approvalReportFilter)

			if (response.status === 200) {
				state.approvalReportList = response.data.content
				state.approvalReportPageInfo = {totalPages: response.data.totalPages, totalElements: response.data.totalElements, currentPage: response.data.pageable.pageNumber + 1}
				if (state.approvalReportList != null && state.approvalReportList.length > 0) {
					state.currentReport = state.approvalReportList[0]
					//fetchReportHistory(state.approvalReportList[0].reportId)
				} else {
					state.currentReport = {}
					state.reportHistory = []
				}
			} else {
				console.log(t("message.msg_202"))
			}
		} catch (error) {
			if (error.response.data.message != null && error.response.data.message != undefined) {
				alert(t("message.msg_203") + "\n" + error.response.data.message)
				console.log(t("message.msg_203") + "\n" + error.response.data.message)
			} else {
				alert(error)
			}
		}		
	}

	const setApprovalReportFilter = async (filters) => {
		const newFilters = { ...state.approvalReportFilter }
		for (const key in filters) {
			if (Object.prototype.hasOwnProperty.call(filters, key)) {
				newFilters[key] = filters[key]
			}
		}

		state.approvalReportFilter = newFilters
	}

	//actions -- 공통
	const fetchReportHistory = async (reportId) => {
		try {
			const response = await reportApi.getReportHistory(reportId)
			if (response.status === 200) {
				state.reportHistory = response.data.content
			} else {
				console.log(t("message.msg_215"))
			}
		} catch (error) {
			alert(t("message.msg_203") + "\n" + error.response.data.message)
			console.log(t("message.msg_203") + "\n" + error.response.data.message)
		}
	}

	const setCurrentReport = (newCurrentReport) => {
		state.currentReport = newCurrentReport;
	};

	//getters--발신
	const getReportList = computed(() => state.reportList)
	const getReportPageInfo = computed(() => state.reportPageInfo)
	const getReportFilter = computed(() => state.reportFilter)

	//getters--수신
	const getReceiptedReportList = computed(() => state.receivedReportList)
	const getReceivedReportPageInfo = computed(() => state.receivedReportPageInfo)
	const getReceivedReportFilter = computed(() => state.receivedReportFilter)

	//getters--승인
	const getApprovalReportList = computed(() => state.approvalReportList)
	const getApprovalReportPageInfo = computed(() => state.approvalReportPageInfo)
	const getApprovalReportFilter = computed(() => state.approvalReportFilter)

	//getters--공통
	const getReportHistory = computed(() => state.reportHistory)
	const getCurrentReport = computed(() => state.currentReport)

	return { 
		fetchReportList, 
		fetchReceivedReportList, 
		fetchApprovalReportList, 
		fetchReportHistory, 
		fetchCheckReceivedReportList,
		setReportInfo,
		setReportFilter,
		setReceivedReportInfo,
		setReceivedReportFilter,
		setApprovalReportFilter,
		setCurrentReport,
		getReportList,
		getReportPageInfo,
		getReportFilter,
		getReceiptedReportList,
		getReceivedReportPageInfo,
		getReceivedReportFilter, 
		getApprovalReportList,
		getApprovalReportPageInfo,
		getApprovalReportFilter,
		getReportHistory, 
		getCurrentReport,
	}
})
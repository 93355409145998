<template>
	<div id="wrap">
		<main id="main">
			<section class="main_container">
				<article class="main_left" :style="{width: infoFlag ? '74%' : '100%'}">
					<div class="title">{{ t("message.msg_628") }}</div>
					<div class="left_title">
						<div class="list_btns">
							<button class="list_up" @click="toggleFilter"></button>
							<button type="button" @click="partnerAdd">
								<img :src="require('../../assets/img/pc/add.png')" alt="" />
								{{ t("message.msg_550") }} {{ t("message.msg_556") }}
							</button>
							<button type="button" @click="partnerAddBulkFlag = true">
								<img :src="require('../../assets/img/pc/addall.png')" alt="" />
								{{ t("message.msg_550") }} {{ t("message.msg_571") }}
							</button>
							<button type="button" @click="partnerDeleteBulkFlag = true">
								<img :src="require('../../assets/img/pc/share_delete.png')" alt="" />
								{{ t("message.msg_550") }} {{ t("message.msg_593") }}
							</button>
						</div>

						<div class="list_search">
							<div class="list_select">
								<button type="submit" @click="regist">{{ t("message.msg_565") }}</button>
								<button class="delete" @click="deletePartner">{{ t("message.msg_572") }}</button>
							</div>
						</div>
					</div>

					<div class="left_list_table">
						<table>
							<thead>
								<tr>
									<td style="width: 1%;"><input v-model="allSelected" type="checkbox" /></td>
									<td style="width: 12%;"><span style="cursor: pointer" @click.stop.prevent="updateSort('partnerCompanyName')">{{ t("message.msg_510") }}</span><img :src="getSortIcon('partnerCompanyName')" alt="" /></td>
									<td style="width: 20%;" class="center"><span style="cursor: pointer" @click.stop.prevent="updateSort('partnerUserName')">{{ t("message.msg_559") }} {{ t("message.msg_502") }}</span><img :src="getSortIcon('partnerUserName')" alt="" /></td>
									<td class="center"><span style="cursor: pointer" @click.stop.prevent="updateSort('partnerUserEmail')">{{ t("message.msg_503") }}</span><img :src="getSortIcon('partnerUserEmail')" alt="" /></td>
									<td class="center"><span style="cursor: pointer" @click.stop.prevent="updateSort('registerFlag')">{{ t("message.msg_517") }}</span><img :src="getSortIcon('registerFlag')" alt="" /></td>
									<td class="center"><span style="cursor: pointer" @click.stop.prevent="updateSort('registeredDate')">{{ t("message.msg_569") }}</span><img :src="getSortIcon('registeredDate')" alt="" /></td>
									<td class="center"><span style="cursor: pointer" @click.stop.prevent="updateSort('requestFlag')">{{ t("message.msg_560") }}</span><img :src="getSortIcon('requestFlag')" alt="" /></td>
									<td class="center"><span style="cursor: pointer" @click.stop.prevent="updateSort('requestedDate')">{{ t("message.msg_568") }}</span><img :src="getSortIcon('requestedDate')" alt="" /></td>
									<td class="center"><span></span>{{ t("message.msg_577") }}</td>
									<td class="center"><span></span>{{ t("message.msg_514") }}</td>
								</tr>
							</thead>
							<tbody>
								<tr v-for="(partner, index) in partnerList" :key="index" :class="{selected: isSelected(partner.id)}">
									<th><input v-model="selectList" type="checkbox" :value="partner" /></th>
									<th style="cursor: pointer" @click="clickPartner(partner.id)"><span v-html="partner.partnerCompanyName"></span></th>
									<th class="center"><span v-html="partner.partnerUserName"></span></th>
									<th class="center"><span v-html="partner.partnerEmail"></span></th>
									<th class="center"><div v-show="partner.registerFlag" class="check"></div><div v-show="!partner.registerFlag" class="check_not"></div></th>
									<th class="center"><span>{{ makeDate(partner.registeredDate) }}</span></th>
									<th class="center"><div v-show="partner.requestFlag" class="check"></div><div v-show="!partner.requestFlag" class="check_not"></div></th>
									<th class="center"><span>{{ makeDate(partner.requestedDate) }}</span></th>
									<th class="center">
										<button v-show="!partner.requestFlag" id="secret" type="button" @click="sendSecretCode(partner.id) ">
											{{ t("message.msg_595") }}
										</button>
									</th>
									<th class="center"><div class="modify" style="cursor: pointer" @click="showPartnerInfo(partner.id)"></div></th>
								</tr>
							</tbody>
						</table>
					</div>
					<CommonPagination v-if="totalPages != undefined" ref="pagination" :total-pages="totalPages" @move-page="movePage" />
				</article>
				<article v-if="infoFlag" class="main_right">
					<PartnerInfoMain @close-info="closeInfo" />
				</article>
				<!-- //main_right -->
			</section>
		</main>
		<!-- //main -->
		<div v-show="PartnerInfoDialog" class="modal-wrap">
			<common-modal @close-modal="hideInfoDialog">
				<partner-info-modal class="PartnerInfoDialog" :select-id="selectId" @close-modal="hideInfoDialog" />
			</common-modal>
		</div>
		<div v-if="parterAddFlag" class="modal-wrap">
			<common-modal @close-modal="hidePartnerAdd">
				<partner-add-modal class="PartnerAdd" @close-modal="hidePartnerAdd" />
			</common-modal>
		</div>
		<template v-if="partnerAddBulkFlag">
			<partner-add-bulk-modal @close-modal="hidePartnerAddBulk" />
		</template>
		<template v-if="partnerDeleteBulkFlag">
			<partner-delete-bulk-modal @close-modal="hidePartnerDeleteBulk" />
		</template>
	</div>
</template>

<script setup>
import partnerAPI from "@/api/partner_api"
import PartnerInfoModal from "./PartnerInfoModal.vue"
import PartnerAddModal from "./PartnerAdd.vue"
import PartnerAddBulkModal from "./PartnerAddBulkModal.vue"
import PartnerDeleteBulkModal from "./PartnerDeleteBulkModal.vue"
import {ref, onMounted, computed, defineEmits, watch} from "vue"
import {usePartnerStore} from "@/store/partnerStore"
import {useI18n} from "vue-i18n"
import CommonPagination from "@/components/common/CommonPagination"
import PartnerInfoMain from "./partnerInfos/PartnerInfoMain.vue"
import _ from "lodash"
import icons from "@/common/icons"
import {format} from "date-fns"

const {t} = useI18n()
const partnerStore = usePartnerStore()
const {setCurrentPartnerInfo, fetchPartnerHistory} = partnerStore
const selectList = ref([])
const PartnerInfoDialog = ref(false)
const selectId = ref("")
const parterAddFlag = ref(false)
const emit = defineEmits(["toggle-filter"])
const infoFlag = ref(false)
const sortItem = ref("modifiedDate")
const sortDirection = ref("desc")
const sortCount = ref(0)
const totalPages = ref(1)
const partnerHistory = ref ([])
const partnerList = ref([])
const partnerAddBulkFlag = ref(false)
const partnerDeleteBulkFlag = ref(false)
const pagination = ref(null)

onMounted(async() =>{
    await partnerStore.fetchPartnerList()
	partnerList.value = _.cloneDeep(partnerStore.getPartnerList)
	totalPages.value = partnerStore.getPartnerPageInfo.totalPages
})

const allSelected = computed({
    get: () => {
        let length = 0
        if (partnerList.value != undefined)
            length = partnerList.value.length
        return length === selectList.value.length
    },
    set: val => {
        selectList.value = val ? partnerList.value : []
    },
})

watch(() =>	partnerStore.getPartnerList, (newValue) => {
	partnerList.value = _.cloneDeep(newValue)
	totalPages.value = partnerStore.getPartnerPageInfo.totalPages
	pagination.value.setPage(partnerStore.getPartnerPageInfo.currentPage)
})


watch(() => partnerStore.getPartnerFilter, (newValue) => {
	if (newValue.sort != (sortItem.value + "," + sortDirection.value)) {
		sortDirection.value = ""
		sortItem.value = ""
		sortCount.value = 0
	} //filter reset 시 default value로 변경
})

const clear = () => {
	closeInfo()
	selectList.value = []
	selectId.value = ""
}

//24.02.19 #26046 juank 거래처 history정보를 가져오는 함수
const history = async () => {
    try{
		await fetchPartnerHistory(selectId.value)
		partnerHistory.value =  partnerStore.getPartnerHistory
    }
    catch(error){
		alert(error.response.data.message);
	}
}

//24.02.19 #26046 juank 거래처 추가 함수
const partnerAdd = () => {
    parterAddFlag.value = true
}

const hidePartnerAdd = () => {
    parterAddFlag.value = false
    clear()
    partnerStore.fetchPartnerList()
}

//24.02.19 #26046 juank 거래처 삭제 함수
const deletePartner = async () => {
    let payload = 
    {
        id: [],
    }
    try {
        if(selectList.value.length > 0) {
            //24.02.19 #26046 juank 선택된 거래처 id값 확인
            for(var i = 0; i< selectList.value.length; i++)
            {
                payload.id[i] = selectList.value[i].id
            }
            let response = await partnerAPI.deletePartner(payload)
            if (response.status === 200) {   
                alert(t("message.msg_572")+t("message.msg_573"))
                clear()
                partnerStore.fetchPartnerList()
            }
            else{
                alert(response.status + t("message.msg_203"))
            }
        }
        else{
            alert(t("message.msg_574")) 
        }
    } catch (error) {
        alert(error.response.data.message)
    }
}
//24.02.19 #26046 juank 거래처 등록 함수
const regist = async () => {
    let payload = 
    {
        id: [],
    }
    try {
        if(selectList.value.length > 0) {
            //24.02.19 #26046 juank 선택된 거래처 id값 확인
            for(var i = 0; i < selectList.value.length; i++)
            {
                payload.id[i] = selectList.value[i].id
            }
            let response = await partnerAPI.registPartner(payload)
            if (response.status === 200) {   
                alert(t("message.msg_565")+t("message.msg_573"))
                partnerStore.fetchPartnerList()
            }
            else{
                alert(response.status + t("message.msg_203"))
            }
        }
        else {
            alert(t("message.msg_574"))
        }
    } catch (error) {
        alert(error.response.data.message)
    }
    
}
//24.02.19 #26046 juank 거래처 정보 모달을 띄우기 위한 변수와 거래처 id값 확인 함수
const showPartnerInfo = (partnerId) => {
    PartnerInfoDialog.value = true
    selectId.value = partnerId
}
//24.02.19 #26046 juank 거래처 정보 모달을 닫기 위한 변수 확인 함수
const hideInfoDialog = () => {
    PartnerInfoDialog.value = false
    clear()
    partnerStore.fetchPartnerList()
}

const hidePartnerAddBulk = () => {
	partnerAddBulkFlag.value = false
	clear()
	partnerStore.fetchPartnerList()
}

const hidePartnerDeleteBulk = () => {
	partnerDeleteBulkFlag.value = false
	clear()
	partnerStore.fetchPartnerList()
}

const toggleFilter = () => {
	emit("toggle-filter")
}

const makeDate = (date) => {
	if(date != "" && date != null)
		return format(date, "yyyy-MM-dd HH:mm:ss")
	else
		return ""
}

const getSortIcon = (item) => {
  if (sortItem.value === item) {
    return sortCount.value == 1 ? icons.tableUp : icons.tableDown;
  }
  return "";
}

const updateSort = async (item) => {
	if (sortItem.value != item) {
		sortCount.value = 0
	}

	sortItem.value = item
	sortCount.value++

	if (sortCount.value == 1) {
		sortDirection.value = "asec"
	} else if (sortCount.value == 2) {
		sortDirection.value = "desc"
	}else if (sortCount.value == 3) {
		sortCount.value = 0
		sortItem.value = null
		sortDirection.value = null
	}
	if (sortItem.value != null) {
		partnerStore.setPartnerFilter({sort: sortItem.value + "," + sortDirection.value})
		await partnerStore.fetchPartnerList()
	}
}
const movePage = async (payload) => {
	await partnerStore.setPartnerFilter({page: payload.page - 1})
	await partnerStore.fetchPartnerList()
}

const clickPartner = async (partnerId) => {
	selectId.value = partnerId
	setCurrentPartnerInfo(partnerId)	
	history()
	infoFlag.value = true
}

const sendSecretCode = async(partnerId) => {
	partnerAPI.sendSecretCode(partnerId).then(() => {
        alert(t("message.msg_594"))
    })
    .catch(async (e) => {
        alert(e.response.data.message)
    })
}

const closeInfo = () => {
	infoFlag.value = false
}

const isSelected = (partnerId) => {
	return selectId.value == partnerId && infoFlag.value
}
</script>
<style scoped src="@/assets/css/pc/account.css">
</style>

<style scoped>
	.selected {
		background-color: var(--vc-gray-100);
	}
	.left_list_table {
		overflow-x: auto;
	}
</style>
import constant from "@/common/constants"
import http from "@/common/http"
import utils from "@/common/util"

export default {
	getShareBoxList: () => {
		var url = constant.GET_SHARE_BOX_LIST_URL
		return http.get(url)
	},
	getShareBoxCcList: () => {
		var url = constant.GET_SHARE_BOX_CC_LIST_URL
		return http.get(url)
	},
	modifyShareBox: (payload) => {
		var url = constant.MODIFY_SHARE_BOX_URL
		return http.post(url, payload)
	},
	deleteShareBox: (sharedBoxId, mailFlag) => {
		var payload = {
			sharedBoxId: sharedBoxId,
			mailFlag: mailFlag,
		}
		var url = constant.DELETE_SHARE_BOX_URL.replace("${sharedBoxId}", sharedBoxId).replace("${mailFlag}", mailFlag)
		return http.delete(url, payload)
	},
	getShareBoxUserList: (sharedBoxId) => {
		var url = constant.GET_SHARE_BOX_USER_LIST_URL.replace("${sharedBoxId}", sharedBoxId)
		return http.get(url)
	},
	addShareReports: (payload) => {
		var url = constant.POST_SHARE_REPORTS_URL
		return http.post(url, payload)
	},
	getShareBoxReportList: (sharedBoxId, page) => {
		var url = constant.GET_SHARE_BOX_REPORT_LIST_URL.replace("${sharedBoxId}", sharedBoxId)
        url = utils.makeUrl(url, page)
		return http.get(url)
	},
	deleteShareReports: (payload) => {
		var url = constant.DELETE_SHARE_REPORTS_URL
		return http.delete(url, {data: payload})
	},
}

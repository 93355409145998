<template>
	<div id="loginWrap">
		<div class="login_cont login_input">
			<img :src="$icons.login_logo" alt="" class="login_logo" />
			<div class="login_text">
				<form>
					<input v-model="id" type="email" :placeholder="t('message.msg_635')" @keyup.enter="login()" />
					<input v-model="password" type="password" :placeholder="t('message.msg_636')" @keyup.enter="login()" />
				</form>
			</div>
			<div class="login_btn">
				<button @click="login()">{{ t("message.msg_632") }}</button>
			</div>
		</div>
	</div>
</template>
<script setup>
import {useI18n} from "vue-i18n"
import { useLoginFunctions } from "@/composable/login";

const { id, password, router, login, checkId,  checkPw} = useLoginFunctions();

const {t} = useI18n()
</script>

<style scoped src="@/assets/css/mobile/login.css">
</style>

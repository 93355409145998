<template>
	<div class="scrollBar">
		<button	class="right_xmark" @click="closeInfo">
			<img :src="$icons.close" alt="" />
		</button>
		<div class="right_title">
			<h3>{{ report.reportNm }}</h3>
		</div>
		<div class="right_tab tab">
			<button	id="defaultOpen" class="tablinks" :class="{active: activedTab == 'info'}" @click="changeTab('info')">{{ t("message.msg_026") }}</button>
			<button class="tablinks" :class="{active: activedTab == 'history'}" @click="changeTab('history')">{{ t("message.msg_027") }}</button>
		</div>
		<ReportInfo v-if="activedTab == 'info'" />
		<ReportHistory v-if="activedTab == 'history'" />
	</div>
</template>
<script setup>
import ReportInfo from "./ReportInfo"
import ReportHistory from "./ReportHistory"

import {useI18n} from "vue-i18n"
import {computed, ref, defineEmits} from "vue"
import {useReportStore} from "@/store/reportStore"

const reportStore = useReportStore()
const {t} = useI18n()

const emit = defineEmits(["close-info"])

const activedTab = ref("info")

const report = computed(() => reportStore.getCurrentReport)

const closeInfo = () => {
	emit("close-info")
}

const changeTab = (tab) => {
	activedTab.value = tab
}

</script>
<style scoped src="@/assets/css/pc/reportList.css">
</style>
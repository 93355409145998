<template>
	<div class="sending_popup">
		<div class="popup">
			<div class="popup_inner">
				<p>{{ t("message.msg_301") }}</p>
				<div class="send_popup">
					<table>
						<thead>
							<tr>
								<th>{{ t("message.msg_018") }}<br />/ {{ t("message.msg_643") }}<br />/ {{ t("message.msg_016") }}</th>
								<td>{{ report.reportNm }}<br />/ {{ report.companyNm }}<br />/{{ format(report.createdDate, "yyyy-MM-dd HH:mm:ss") }}</td>
							</tr>
						</thead>
						<tbody>
							<tr>
								<th>{{ t("message.msg_318") }}</th>
								<td>
									<div class="input_wrap">
										<div class="side_check">
											<label class="container">OK
												<input id="one" v-model="replyAnswer" type="radio" value="OK" />
												<span class="checkmark"></span>
											</label>
											<label class="container">NG
												<input id="two" v-model="replyAnswer" type="radio" value="NG" />
												<span class="checkmark"></span>
											</label>
										</div>
									</div>
								</td>
							</tr>
							<tr>
								<th>{{ t("message.msg_319") }}</th>
								<td>
									<textarea ref="docComment" v-model="replyMemo" name="story" rows="3" cols="39" :placeholder="t('message.msg_335')"></textarea>
								</td>
							</tr>
						</tbody>
					</table>
				</div>
				<button class="send_btn" @click.stop.prevent="ok">{{ t("message.msg_068") }}</button>
			</div>
			<button class="close_btn" @click="closeModal()"></button>
		</div>
	</div>
</template>
<script setup>
import { useI18n } from "vue-i18n";
import { computed, ref, defineEmits, watch } from "vue";
import { useReportStore } from "@/store/reportStore";
import { replyReports } from "@/composable/report";
import {format} from "date-fns"

const reportStore = useReportStore();
const { t } = useI18n();

const replyAnswer = ref("OK");
const replyMemo = ref("");
const emit = defineEmits(["close-modal"]);

const closeModal = () => {
  emit("close-modal");
};

const report = computed(() => reportStore.getCurrentReport);

const ok = async () => {
  const payload = {
    reportId: report.value.reportId,
    replyOk: replyAnswer.value,
    replyMemo: replyMemo.value,
  };
  await replyReports(payload);
  await reportStore.fetchReceivedReportList()
  closeModal();
};

watch(replyAnswer, (newReplyAnswer) => {
  if (newReplyAnswer === "OK") {
    replyMemo.value = "";
  }
});

</script>
<style scoped src="@/assets/css/mobile/ticket.css">
</style>

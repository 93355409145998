<template>
	<div id="wrap">
		<main id="main">
			<section class="main_container">
				<article class="main_left" :style="{width: infoFlag ? '74%' : '100%'}">
					<div class="title">{{ t("message.msg_641") }}</div>
					<div class="left_title">
						<div class="list_btns">
							<button class="list_up" @click="toggleFilter"></button>
						</div>

						<div class="list_search">
							<button @click="uploadAdd">
								<img :src="require('../../assets/img/pc/addUpload.png')" alt="" />
								{{ t("message.msg_600") }}
							</button>
						</div>
					</div>

					<div class="left_list_table">
						<table>
							<thead>
								<tr>
									<td style="width: 20%;" class="center"><span style="cursor: pointer" @click.stop.prevent="updateSort('uploadStatus')">{{ t("message.msg_601") }}</span><img :src="getSortIcon('uploadStatus')" alt="" /></td>
									<td style="width: 20%;" class="center"><span style="cursor: pointer" @click.stop.prevent="updateSort('partnerCompanyName')">{{ t("message.msg_550") }} {{ t("message.msg_510") }}</span><img :src="getSortIcon('partnerCompanyName')" alt="" /></td>
									<td class="center"><span style="cursor: pointer" @click.stop.prevent="updateSort('createdBy')">{{ t("message.msg_040") }}</span><img :src="getSortIcon('createdBy')" alt="" /></td>
									<td class="center"><span style="cursor: pointer" @click.stop.prevent="updateSort('createdDate')">{{ t("message.msg_569") }}</span><img :src="getSortIcon('createdDate')" alt="" /></td>
									<td class="center"><span style="cursor: pointer" @click.stop.prevent="updateSort('reportCode')">{{ t("message.msg_608") }}</span><img :src="getSortIcon('reportCode')" alt="" /></td>
								</tr>
							</thead>
							<tbody>
								<tr v-for="(upload, index) in uploadList" :key="index">
									<th class="center" :title="upload.errMsg">
										<span v-if="upload.uploadStatus == 'UPLOAD_FAIL'" style="color: red;">{{ t("message.msg_658") }}</span>
										<span v-if="upload.uploadStatus == 'UPLOAD_OK'" style="color: green;">{{ t("message.msg_657") }}</span>
									</th>
									<th class="center"><span v-html="upload.partnerCompanyName"></span></th>
									<th class="center">
										<span v-if="util.checkEmail(upload.createdBy) == true" v-html="upload.createdBy"></span>
										<span v-else>{{ t("message.msg_659") }}</span>
									</th>
									<th class="center"><span>{{ format(upload.createdDate, "yyyy-MM-dd HH:mm:ss") }}</span></th>
									<th class="center"><span v-html="upload.reportCode"></span></th>
								</tr>
							</tbody>
						</table>
					</div>
					<CommonPagination v-if="totalPages != undefined" ref="pagination" :total-pages="totalPages" @move-page="movePage" />
				</article>
				<div v-if="uploadAddFlag" class="modal-wrap">
					<common-modal @close-modal="hideUploadAdd">
						<upload-add-modal class="UploadAdd" @close-modal="hideUploadAdd" />
					</common-modal>
				</div> 
				<!-- //main_left -->
			</section>
		</main> 
	</div>
</template>

<script setup>
import {ref, onMounted, watch, defineEmits} from "vue"
import {useUploadStore} from "@/store/uploadStore"
import {useI18n} from "vue-i18n"
import UploadAddModal from "./UploadAddModal.vue"
import CommonPagination from "@/components/common/CommonPagination"
import _ from "lodash"
import {format} from "date-fns"
import icons from "@/common/icons"
import util from "@/common/util"

const {t} = useI18n()
const uploadStore = useUploadStore()
const uploadAddFlag = ref(false)
const infoFlag = ref(false)
const totalPages = ref(1)
const uploadList = ref([])
const sortItem = ref("modifiedDate")
const sortDirection = ref("desc")
const sortCount = ref(0)
const emit = defineEmits(["toggle-filter"])
const pagination = ref(null)


onMounted(async() =>{
	var now = new Date();
	uploadStore.setUploadFilter({createdDateTo:  makeDateStr(now, false)})
	uploadStore.setUploadFilter({createdDateFrom:  makeDateStr(new Date(now.setMonth(now.getMonth() - 3)), true)})
	await uploadStore.fetchUploadList()
	uploadList.value = _.cloneDeep(uploadStore.getUploadList)
	totalPages.value = uploadStore.getUploadPageInfo.totalPages
})

const makeDateStr = (date, flag) => {
    if(date == null || date == undefined || date == "") {
        return ""
    }
    let dateStr = format(date, "yyyy-MM-dd")
    if (flag) {
        return `${dateStr} 00:00:00`
    } else {
        return `${dateStr} 23:59:59`
    }     
}

watch(() =>	uploadStore.getUploadList, (newValue) => {
	uploadList.value = _.cloneDeep(newValue)
	totalPages.value = uploadStore.getUploadPageInfo.totalPages
	pagination.value.setPage(uploadStore.getUploadPageInfo.currentPage)
})


watch(() => uploadStore.getUploadFilter, (newValue) => {
	if (newValue.sort != (sortItem.value + "," + sortDirection.value)) {
		sortDirection.value = ""
		sortItem.value = ""
		sortCount.value = 0
	} //filter reset 시 default value로 변경
})

const uploadAdd = () => {
    uploadAddFlag.value = true
}

const hideUploadAdd = () => {
    uploadAddFlag.value = false
	uploadStore.fetchUploadList()
}

const movePage = async (payload) => {
	await uploadStore.setUploadFilter({page: payload.page - 1})
	await uploadStore.fetchUploadList()
}

const toggleFilter = () => {
	emit("toggle-filter")
}

const getSortIcon = (item) => {
  if (sortItem.value === item) {
    return sortCount.value == 1 ? icons.tableUp : icons.tableDown;
  }
  return "";
}

const updateSort = async (item) => {
	if (sortItem.value != item) {
		sortCount.value = 0
	}
	
	sortItem.value = item
	sortCount.value++

	if (sortCount.value == 1) {
		sortDirection.value = "asec"
	} else if (sortCount.value == 2) {
		sortDirection.value = "desc"
	}else if (sortCount.value == 3) {
		sortCount.value = 0
		sortItem.value = null
		sortDirection.value = null
	}

	if (sortItem.value != null) {
		uploadStore.setUploadFilter({sort: sortItem.value + "," + sortDirection.value})
		await uploadStore.fetchUploadList()
	}
}

</script>

<style scoped src="@/assets/css/pc/addUpload.css">
</style>


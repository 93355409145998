<template>
	<div class="middle-box text-center animated fadeInDown">
		<h1>404</h1>
		<h3 class="font-bold">{{ $t("message.msg_525") }}</h3>

		<div class="error-desc">
			{{ $t("message.msg_526") }}
		</div>
	</div>
</template>

<script>
export default {
	name: "not-found",
}
</script>

<style></style>

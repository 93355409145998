<template>
	<div class="popup_inner" style="position: relative;">
		<div class="input_wrap">
			<label for=""><span>*</span>{{ t("message.msg_550") }} {{ t("message.msg_530") }}</label>
			<input :value="partnerCompanyName" @click="selectPartner()" @input="submitAutoCompletePartner($event.target.value)" /><br />
		</div>
		<partner-list-modal v-if="showPartner" v-on-click-outside="onClickOutside" :searched-partner-list="searchedPartner" class="option_list" @partner-info="selectPartnerInfo" />
		<div class="input_wrap">
			<label for=""><span>*</span>{{ t("message.msg_604") }} </label>
			<input :value="reportCode" @click="selectUploadOption()" @input="submitAutoCompleteOption($event.target.value)" /><br />
		</div>
		<upload-option-list-modal v-if="showOption" v-on-click-outside="onClickOutside" :searched-option-list="searchedUploadOption" class="option_list" style="margin-top: 120px;" @option-info="selectOptionInfo" />
		<div class="input_wrap">
			<label for=""><span>*</span>{{ t("message.msg_605") }}</label>
			<div class="input_file">
				<input :title="reportFileName" type="text" readonly data-index="1" />
				<button onclick="document.getElementById('fileInput1').click();">{{ t("message.msg_650") }}</button>
				<input
					id="fileInput1"
					type="file"
					accept=".PDF"
					style="display: none;"				
					@change="onReportFileChange"
				/>
			</div>
		</div>
		<div class="input_wrap">
			<label for="">{{ t("message.msg_606") }}</label>
			<div class="input_file">
				<input :title="attachedFileName" type="text" readonly data-index="2" />
				<button onclick="document.getElementById('fileInput2').click();">{{ t("message.msg_650") }}</button>
				<input
					id="fileInput2"
					multiple
					type="file"
					style="display: none;"
					@change="onAttachFileChange"
				/>
			</div>
		</div>
		<div class="input_wrap">
			<label for=""> {{ t("message.msg_085") }}</label>
			<input v-model="reportPrice" type="text" /><br />
		</div>
		<button @click="save">{{ t("message.msg_556") }}</button>
	</div>
</template>
<script setup>
import partnerAPI from "@/api/partner_api"
import uploadAPI from "@/api/upload_api"
import {ref, onMounted, defineEmits, watch} from "vue"
import {useI18n} from "vue-i18n"
import UploadOptionListModal from "./UploadOptionListModal.vue";
import PartnerListModal from "./PartnerListModal.vue";
import { vOnClickOutside } from '@vueuse/components'

const {t} = useI18n()

const partnerListArr = ref([{
    partnerCompanyName: "",
    partnerUserName: "",
    partnerCode: "",
    defaultReportName: "",
    registerFlag: "",
}
])
const searchedPartner = ref([])
const uploadOptionArr = ref([])
const searchedUploadOption = ref([])
const partner = ref({
    partnerCompanyName: "",
    partnerUserName: "",
    partnerEmail: "",
    registerFlag: "",
    requestFlag: "true",
    requestFrom: "",
    requestTo: "",
    registerFrom: "",
    registerTo: "",
    size: "10000"
})

const uploadOption = ref({
    reportCd: "",
    uploadMode: "",
    size: "10000" 
})

const partnerCode = ref("")
const reportFile = ref([])
const attachedFile = ref([])
const attachedFileName = ref("")
const reportFileName = ref("") //#26429 24.06.10 juank 마우스 오버할때 파일 이름이 보일 수 있도록 변수를 추가함
const selectOption = ref({
    reportCd: "",
    uploadMode: "",
    maxFile: "",
})
const reportCode = ref("")
const defaultReportName = ref("")
const partnerCompanyName = ref("")
const partnerUserName = ref("")
const showOption = ref(false)
const showPartner = ref(false)
const reportPrice = ref(0)
const emit = defineEmits(["closeModal"])

const closeModal = () => {
	emit("closeModal");
}
onMounted(async() =>{
    partnerAPI.getPartnerList(partner.value)
    .then((res) => {
        partnerListArr.value = res.data;
        searchedPartner.value = res.data;
    })
    .catch(async (error) => {
        alert(error.response.data.message);
    })
    uploadAPI.getUploadOptionList(uploadOption.value)
    .then((res) => {
        uploadOptionArr.value = res.data;
        searchedUploadOption.value = res.data;
    })
    .catch(async (error) => {
        alert(error.response.data.message);
    })
})

watch(() => reportPrice.value, () => {
	reportPrice.value = (String (reportPrice.value)).replace(/[^0-9]/g, '') //24.07.09 juank 숫자 이외에 값은 입력 안되도록
})

const selectPartner = () => {
    showPartner.value = true
    if(partnerListArr.value.content != null && partnerListArr.value.content != undefined)
        searchedPartner.value = [...partnerListArr.value.content]
}

const selectPartnerInfo = (partner) => {
    showPartner.value = false
    partnerCode.value = partner.partnerCode
    partnerCompanyName.value = partner.partnerCompanyName
    partnerUserName.value = partner.partnerUserName
}

const submitAutoCompletePartner = (partner) => {
    partnerCompanyName.value = partner
	searchedPartner.value = partnerListArr.value.content.filter((x) => {
		return x.partnerCompanyName.match(partner) || x.partnerUserName.match(partner);
	});	
};


const selectOptionInfo = (option) => {
    showOption.value = false
    selectOption.value = option.option
    //uploadMode에 따라 파일 추가에서 파일 형식을 지정한다
    //if(selectOption.value.uploadMode === "PDF")
        //document.getElementById("fileInput1").setAttribute("accept", "."+selectOption.value.uploadMode)
    // else
    //     document.getElementById("fileInput1").setAttribute("accept", ".*")

    reportCode.value = selectOption.value.reportCd
    defaultReportName.value = selectOption.value.defaultReportName
}

const selectUploadOption = () => {
    showOption.value = true
    if(uploadOptionArr.value.content != null && uploadOptionArr.value.content != undefined)
        searchedUploadOption.value = [...uploadOptionArr.value.content]
};

const onClickOutside = () => {
    showOption.value = false
    showPartner.value =false
}

const submitAutoCompleteOption = (option) => {
    reportCode.value = option
	searchedUploadOption.value = uploadOptionArr.value.content.filter((x) => {
		return x.reportCd.match(option) || x.defaultReportName.match(option);
	});	
};

const save = async () => {
    if (partnerCode.value == '' || reportCode.value == '' || reportFile.value.length == 0){
        alert(t('message.msg_627'))
        return
    }
    
    let payload = {
        partnerCode: partnerCode.value,
        reportCode: reportCode.value,
        reportPrice: reportPrice.value,
        reportFileInfo: [
            {
                name: reportFile.value[0].name,
                fileType: reportFile.value[0].type,  
            },
        ],
        fileList: [],
	}
    if(payload.reportPrice =="")
        payload.reportPrice = 0
    else
        payload.reportPrice *= 1

    if(reportFile.value[0].type.indexOf("pdf") != -1){
        payload.reportFileInfo[0].fileType = "PDF"
    }
        
    else if (reportFile.value[0].type.indexOf("csv") != -1)
        payload.reportFileInfo[0].fileType = "CSV"
    else
        payload.reportFileInfo[0].fileType = "ETC"

    //옵션이 PDF일때 추가한 파일이 PDF인지 검사
    if(selectOption.value.uploadMode == "PDF" && payload.reportFileInfo[0].fileType != selectOption.value.uploadMode)
    {
        alert(t("message.msg_654")) 
        return
    }
    //파일수 상한 검사
    if(attachedFile.value.length > selectOption.value.maxFile)
    {
        alert(t("message.msg_653") +"(" + selectOption.value.maxFile + t("message.msg_652") +")")
        return
    }
    payload.fileList.push(reportFile.value[0])

    for (var i = 0; i < attachedFile.value.length; i++) {
        payload.fileList.push(attachedFile.value[i])
        let fileInfo = {
            name: attachedFile.value[i].name,
            fileType: attachedFile.value[i].type,
        }
        if(attachedFile.value[i].type.indexOf("pdf") != -1)
            fileInfo.fileType = "PDF"
        else if (attachedFile.value[i].type.indexOf("csv") != -1)
            fileInfo.fileType = "CSV"
        else
            fileInfo.fileType = "ETC"
        payload.reportFileInfo.push(fileInfo)
    }
    
    try {
		let response = await uploadAPI.uploadReport(payload)
		if (response.status === 200) {   
            alert(t("message.msg_533"))
            closeModal()
        }
        else{
            alert(response.status + t("message.msg_203"))
        }
    } catch (error) {
        alert(error.response.data.message)
    }
    
}
const onReportFileChange = (e) => {
    reportFile.value = e.target.files || e.dataTransfer.files;
    var displayInput = document.querySelector('input[type="text"][data-index="' + 1 + '"]');
    reportFileName.value = e.target.files[0].name; //#26429 24.06.10 juank 마우스 오버할때 파일 이름이 보일 수 있도록 변수를 추가함
    displayInput.value = reportFileName.value;
}

const onAttachFileChange = (e) => {
    let fileMax = selectOption.value.maxFile
    //파일수 상한 검사
    if(e.target.files.length > fileMax)
    {
        alert(t("message.msg_653") +"(" + fileMax + t("message.msg_652") +")")
        return
    }
    attachedFile.value = e.target.files || e.dataTransfer.files;
    var displayInput = document.querySelector('input[type="text"][data-index="' + 2 + '"]');
    for(var i=0; i < e.target.files.length; i++)
        attachedFileName.value += e.target.files[i].name + "\n";
    displayInput.value = t("message.msg_651") + " " + e.target.files.length + t("message.msg_652");
}

</script>

<style scoped src="@/assets/css/pc/addUpload.css">

</style>
<style scoped>
  .option_list{
    position: absolute;
    z-index: 9999;
    left : 0;
    right : 0;
    margin-left : auto;
    margin-right : auto;
    width: 30.0em;
    margin-top: 50px; /*#26435 24.06.10 juank 거래처 리스트가 input을 가리지 않도록 수정함*/
  }

  .popup_inner .input_wrap span {
    display: inline-block;
    margin-right: 5px;
  }
</style>
export const commonStore = {
	namespaced: true,
	state: () => ({
		number: 0,
	}),
	mutations: {
		increaseNumber(state) {
			state.number++
		},
	},
	getters: {
		// eslint-disable-next-line no-unused-vars
		getTest(state, getters, rootState) {
			return state.number
		},
	},
	actions: {
		// eslint-disable-next-line no-unused-vars
		increaseNumber(state, commit, rootState) {
			commit("increaseNumber")
		},
	},
}

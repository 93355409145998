<template>
	<div id="State" class="tabCont tabcontent">
		<div class="detail detail02">
			<span>{{ t("message.msg_646") }} {{ historyList.length }} {{ t("message.msg_647") }}</span>
			<table>
				<thead>
					<tr>
						<th>{{ t("message.msg_039") }}</th>
						<th>{{ t("message.msg_027") }}</th>
						<th>{{ t("message.msg_040") }}</th>
						<th>{{ t("message.msg_048") }}</th>
					</tr>
				</thead>
				<tbody>
					<ReportHistoryItem v-for="history in historyList" :key="history.id" :report-history="history" />
				</tbody>
			</table>
		</div>
	</div>
</template>

<script setup>
import ReportHistoryItem from "./ReportHistoryItem"
import {useI18n} from "vue-i18n"
import {computed} from "vue"
import {useReportStore} from "@/store/reportStore"

const reportStore = useReportStore()
const {t} = useI18n()

const historyList = computed(() => reportStore.getReportHistory)

</script>

<style scoped src="@/assets/css/pc/reportList.css">
</style>

<template>
	<section class="main_container">
		<div class="list_btns">
			<button class="list_up" @click="toggleFilter"></button>
			<div class="list_totals">
				<p class="list_total">{{ t("message.msg_232") }} <span>: {{ reportStore.getReceivedReportPageInfo.totalElements }}</span></p>
				<button class="list_reset" @click.stop.prevent="reportStore.fetchReceivedReportList"></button>
			</div>
		</div>
		<div v-if="reportList && reportList.length !== 0">
			<template v-for="report in reportList" :key="report.reportId">
				<div class="list_cont">
					<div class="card_list">
						<div class="dots" @click.stop.prevent="clickMore($event)">
							<div class="dot"></div>
							<div v-on-click-outside="onClickOutside" class="popupMenu">
								<div>
									<ul>
										<li @click="openInfoModal(report.reportId)"><a href="#">{{ t("message.msg_026") }}</a></li>
										<li @click.stop.prevent="onClickDownload(report, downloadType[0].value)"><a href="#">{{ downloadType[0].text }}</a></li>
										<!-- <li @click.stop.prevent="onClickDownload(report.reportId, downloadType[2].value)"><a href="#">{{ downloadType[2].text }}</a></li> -->
										<li @click.stop.prevent="onClickDownload(report, downloadType[1].value)"><a href="#">{{ downloadType[1].text }}</a></li>
										<!-- <li @click.stop.prevent="onClickDownload(report.reportId, downloadType[3].value)"><a href="#">{{ downloadType[3].text }}</a></li> -->
										<li v-if="report.replyStatus == 'READY'" @click="openReplyModal()"><a href="#">{{ t("message.msg_063") }}</a></li>
									</ul>
								</div>
							</div>
						</div>
						<h2>{{ report.reportNm }}</h2>
						<div class="card_cont">
							<div><span>{{ t("message.msg_014") }}</span>{{ getReadStatusText(report.readStatus) }}</div>
							<div><span>{{ t("message.msg_025") }}</span><button class="replyImg" :style="{backgroundPosition: report.replyStatus == 'READY' ? 'right' : 'left'}"></button></div>
							<div><span>{{ t("message.msg_030") }}</span>{{ report.companyNm }}</div>
							<div><span>{{ t("message.msg_085") }}</span>{{ report.price }}</div>
							<div><span>{{ t("message.msg_039") }}</span>{{ format(report.createdDate, "yyyy-MM-dd HH:mm:ss") }}</div>
						</div>
					</div>
				</div>
			</template>
		</div>
		<CommonPagination v-if="totalPages != undefined" :total-pages="totalPages" @move-page="movePage" />
		<template v-if="showReportInfoModal">
			<MobileReportInfoMain @close-info="showReportInfoModal=false" />
		</template>
		<template v-if="showReportReplyModal">
			<MobileReceivedReportReplyModal @close-modal="showReportReplyModal=false" />
		</template>
	</section>
</template>

<script setup>
import { vOnClickOutside } from '@vueuse/components'
import {ref, onMounted, watch, defineEmits} from "vue"
import MobileReportInfoMain from "../m_receivedReport/m_reportInfos/MobileReportInfoMain.vue"
import CommonPagination from "@/components/common/CommonPagination"
import MobileReceivedReportReplyModal from "./MobileReceivedReportReplyModal.vue";
import {downloadReport, getReadStatusText} from "@/composable/report"
import _ from "lodash"
import {format} from "date-fns"
import { useShareBoxReportFunctions } from '@/composable/shareBox';
import { useReceivedReportListFunctions } from '@/composable/receivedReportList';
import { useI18n } from 'vue-i18n';

const { t } = useI18n();
const {
    selectAll,
	setReceivedReportInfo,
	fetchReportHistory,
	chkSelectAll,
	reportList,
	reportStore,
} = useShareBoxReportFunctions();

const {
    downloadType,
    totalPages,
    showReportReplyModal,
    showReportInfoModal,
    clickMore,
    onClickOutside,
} = useReceivedReportListFunctions();

const emit = defineEmits(["toggle-filter", "close"])

const openInfoModal = async (reportId) => {
    setReceivedReportInfo(reportId)
	fetchReportHistory(reportId)
    showReportInfoModal.value = true;
};

const openReplyModal = async () => {
    showReportReplyModal.value = true;
};

const toggleFilter = () => {
	emit("toggle-filter")
}

onMounted(async() => {
	reportStore.setReceivedReportFilter({ page: 0 });
	await reportStore.fetchReceivedReportList({})
	reportList.value = _.cloneDeep(reportStore.getReceiptedReportList)
	totalPages.value = reportStore.getReceivedReportPageInfo.totalPages
})

watch(() =>	reportStore.getReceiptedReportList, (newValue) => {
	reportList.value = _.cloneDeep(newValue)
	totalPages.value = reportStore.getReceivedReportPageInfo.totalPages
})

const onClickDownload = (report, type) => {
	downloadReport(report, type)
}

const movePage = async (payload) => {
    await reportStore.setReceivedReportFilter({ page: payload.page - 1 });
    await reportStore.fetchReceivedReportList();
	chkSelectAll.value = false;
    selectAll();
};

</script>

<style scoped src="@/assets/css/mobile/ticket.css">
</style>
import constant from "@/common/constants"
import http from "@/common/http"
import utils from "@/common/util"

export default {
	addPartner : (payload) => {
		var url = constant.NEW_PARTNER_URL
		return http.post(url, payload)
	},

	addPartnerList: (payload) => {
		var url = constant.POST_PARTNER_LIST_URL
		return http.post(url, payload)
	},

	getPartnerList: (payload) => {
		var url = constant.GET_PARTNERLIST_URL
		var newPayload = {
			pcn: payload.partnerCompanyName,
			pun: payload.partnerUserName,
			pue: payload.partnerEmail,
			reqF: payload.requestFlag,
			reqFrom: payload.requestFrom,
			reqTo: payload.requestTo,
			regF: payload.registerFlag,
			regFrom: payload.registerFrom,
			regTo: payload.registerTo,
			size: payload.size,
			page: payload.page,
			sort: payload.sort,
		}
		
		url = utils.makeUrl(url, newPayload)
		return http.get(url)
	},

	getPartnerListById: (id) => {
		var url = constant.GET_PARTNERLISTBYID_URL.replace("${id}", id)
		return http.get(url)
	},

	registPartner : (payload) => {
		var url = constant.POST_REGISTPARTNER_URL
		var temp = [];
		for(var i = 0; i < payload.id.length; i++)
			temp.push(payload.id[i])
		var regist = {idList: temp}
		return http.post(url, regist)
	},

	deletePartner : (payload) => {
		var url = constant.DELETE_PARTNER_URL
		var temp = [];
		for(var i = 0; i < payload.id.length; i++)
			temp.push(payload.id[i])
		var deleteP = {idList: temp}
		return http.delete(url, {data: deleteP})
	},

	deletePartnerList: (payload) => {
		var url = constant.DELETE_PARTNER_LIST_URL
		return http.delete(url, {data: payload})
	},

	getPartnerInfo: (payload) => {
		var url = constant.GET_PARTNER_INFO
		url += "/"+payload
		return http.get(url)
	},
	requestPartner: (secretCode) => {
		var temp = {secretCode: secretCode}
		var payload = utils.encrypt(JSON.stringify(temp))
		var url = constant.REQUEST_PARTNER_URL
		return http.post(url, {payload: payload})
	},
	modifyPartner: (payload) => {
		var url = constant.MODIFY_PARTNER_URL
		return http.put(url, payload)
	},
	getPartnerHistory: (id) => {
		var url = constant.GET_PARTNER_HISTORY_LIST_URL + "/" + id
		return http.get(url)
	},

	sendSecretCode : (id) => {
		var url = constant.GET_SECRET_CODE_URL.replace("${partnerId}", id)
		return http.get(url)
	},
}
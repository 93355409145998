// pc와 mobile 공통 login함수
import userAPI from "@/api/user_api"
import jwt from "@/common/jwt"
import utils from "@/common/util"
import {useI18n} from "vue-i18n"
import {ref} from "vue"
import {useRouter} from "vue-router"

export function useLoginFunctions() {

  const id = ref("")
  const password = ref("")
  const {t} = useI18n()
  const router = useRouter()

  const checkId = () => {
    if (utils.checkEmail(id.value)) {
      return true
    }
    alert(t("message.msg_001")) //24.01.23 #25910 juank 아이디 형식을 확인해주세요
    return false
  }

  const checkPw = () => {
    if (password.value.length >= 8) {
      return true
    }
    alert(t("message.msg_002")) //24.01.23 #25910 juank 비밀번호는 8자 이상이어야 합니다
    return false
  }

  const login = () => {
    let userId = id.value
    let userPassword = password.value
    if (userId === "" && userPassword === "") {
      alert(t("message.msg_000")) //24.01.23 #25910 juank 아이디와 암호를 입력해 주세요
      return
    }
    if (checkId() && checkPw()) {
      userAPI
        .login(userId, userPassword)
        .then((res) => {
          jwt.setToken(res.data)
          router.push("main")
        })
        .catch(async (error) => {
          alert(error.response.data.message)
        })
    }
  }

  return {
    id,
    password,
    router,
    checkId,
    checkPw,
    login,
  };
}
<template>
	<div id="app">
		<router-view />
	</div>
</template>

<script setup>
import {onMounted, watch} from "vue"
import {useI18n} from "vue-i18n"

const t = useI18n()

const setLanguage = () => {
	if (t.locale.value == "ja") {
		document.getElementsByTagName("html")[0].lang = "ja"
		document.documentElement.style.setProperty("--main-font", "'Noto Sans JP', sans-serif")
	} else {
		document.getElementsByTagName("html")[0].lang = "ko"
		document.documentElement.style.setProperty("--main-font", "'Noto Sans KR', sans-serif")
	}
}

onMounted(() => {
	setLanguage()
})

watch(() =>	t.locale.value, () => {
	setLanguage()
})

</script>

<style src="@/assets/css/pc/common.css">
#app {
	height: 100%;
}
</style>
<template>
	<div id="sideMenu">
		<button class="side_close_btn" @click="closeFilter"></button>
		<button class="side_reset_btn" @click="reset"></button>
		<div class="side_list">
			<h2 class="open_title">{{ t("message.msg_329") }}</h2>
			<div class="share_side">
				<div v-for="(item, index) in shareBoxCcList" :key="index" class="share_list" :class="{selected: isSelected(item)}" @click="clickShareBox(item, index)">
					<p>
						<span><img :src="$icons.share_document" alt="" /></span>{{ item.name }}
					</p>
					<div class="side_share_btns">
						<button class="share_btn" @click.stop="onclickUserModal(item)"></button>
					</div>
				</div>
			</div>
		</div>
	</div>
	<template v-if="showEditShareBoxUserModal">
		<shared-box-modal @close-modal="close()">
			<edit-shared-box-user-list
				:type="type"
				:mode="null"
				:item="null"
				@close="close()"
			/>
		</shared-box-modal>
	</template>
</template>

<script setup>
import {useI18n} from "vue-i18n"
import {useShareBoxStore} from "@/store/shareBoxStore.js"
import _ from "lodash"
import { ref, defineProps, defineEmits } from "vue";
import EditSharedBoxUserList from "@/components/sharedBox/EditSharedBoxUserList";
import SharedBoxModal from "@/components/sharedBox/SharedBoxModal";

const shareBoxStore = useShareBoxStore();
const {t} = useI18n()
const props = defineProps({
	shareBoxCcList: { type: Array, required: true },
	type: { type: String, required: true },
});
const showEditShareBoxUserModal = ref(false);

const clickShareBox = (item, index) => {
  shareBoxStore.setCcShareBox(item);
  shareBoxStore.fetchShareBoxReportList(item.sharedBoxId);
  closeFilter();
}

const emit = defineEmits(["resetSharedBoxList", "toggle-filter"]);

const closeFilter = () => {
  emit("toggle-filter")
}

const reset = () => {
	emit("resetSharedBoxList")
}

const onclickUserModal = (item) => {
	shareBoxStore.setCcShareBox(item);
	showEditShareBoxUserModal.value = true
}

const close = () => {
	showEditShareBoxUserModal.value = false;
}

const isSelected = (item) => {
	return _.isEqual(shareBoxStore.getCurrentCcShareBox, item)
}

</script>

<style scoped src="@/assets/css/pc/share.css">
button {
margin: 1em 0 0 1em;
}
.item {
height: 2.2em;
text-align: center;
cursor: pointer;
padding-top: 1em;
}
.item:hover {
background-color: rgb(113, 122, 122);
}
.editButton {
/* z-index: 10; */
position: relative;
left: 0.5em;
}
.item.active {
background-color: rgb(113, 122, 122);
}
</style>

<style scoped>
.selected {
	background-color: var(--vc-gray-100);
}
</style>
<template>
	<div>
		<div>
			<div class="d-flex justify-content-center align-items-center title">
				ReportDelivery - Product Information
			</div>
		</div>
		<div class="d-flex justify-content-center align-items-center">
			<div class="tableForm">
				<table>
					<tbody>
						<tr>
							<th>{{ backendTitle }}</th>
							<td>{{ backendVersion }}</td>
						</tr>
						<tr>
							<th>ReportDelivery Mailer Version</th>
							<td>{{ mailerVersion }}</td>
						</tr>
						<tr>
							<th>Front-end Version</th>
							<td>{{ vueVersion }} ({{ formatDate(vueTimeStamp) }})</td>
						</tr>
					</tbody>
				</table>
			</div>
		</div>
	</div>
</template>

<script setup>
import { ref, onMounted } from "vue";
import versionApi from "@/api/version_api";
import utils from "@/common/util";

const backendTitle = ref("");
const backendVersion = ref("");
const mailerVersion = ref("");
const vueVersion = ref("");
const vueTimeStamp = ref("");

const getBackEndVersion = () => {
	versionApi.getBackEndVersion().then((res) => {
		backendTitle.value = res.data.title;
		backendVersion.value = res.data.version;
		mailerVersion.value = res.data.mailerVersion;
	});
};

const getVueVersion = async () => {
	const version = await utils.projectVersion();
	vueVersion.value = version;
	vueTimeStamp.value = document.documentElement.dataset.buildTimestampUtc;
};

onMounted(() => {
	getBackEndVersion();
	getVueVersion();
});

const formatDate = (date) => {
  if (date != null && date !== undefined) {
    const dateObj = new Date(date);

    const kstOffset = 9 * 60;
    const kstDateObj = new Date(dateObj.getTime() + (kstOffset * 60 * 1000));

    const year = kstDateObj.getUTCFullYear();
    const month = String(kstDateObj.getUTCMonth() + 1).padStart(2, '0');
    const day = String(kstDateObj.getUTCDate()).padStart(2, '0');
    const hours = String(kstDateObj.getUTCHours()).padStart(2, '0');
    const minutes = String(kstDateObj.getUTCMinutes()).padStart(2, '0');

    return `${year}-${month}-${day} ${hours}:${minutes}`;
  }
  return "";
};
</script>

<style scoped>
.title {
	font-size: 1.5em;
	background-color: #1a8758;
	font-weight: bold;
	height: 50px;
	color: white;
	display: flex;
	align-items: center;
	padding-left: 10px;
}

.body {
	margin: 2em 0 0 1em;
	font-weight: bold;
}

.tableForm {
	border: 1px solid #e7e7e7;
	border-bottom: none;
	margin-top: 10px;
	width: 40%;
}

.tableForm table {
	width: 100%;
}

th {
	height: 51px;
	padding-left: 20px;
	border-bottom: 1px solid #e7e7e7;
	background: #f7f7f7;
	width: 20%;
	text-align: left;
}

td {
	padding: 10px;
	border-bottom: 1px solid #e7e7e7;
	width: 30%;
	font-weight: bold;
}
</style>

import {defineStore} from "pinia"
import {reactive, computed} from "vue"
import partnerAPI from "@/api/partner_api"
import {useI18n} from "vue-i18n"
import jwt from "@/common/jwt"

export const usePartnerStore = defineStore("partners", () => {
    const state = reactive({
		partnerList: [],
		partnerPageInfo: {},
		partnerFilter: {
			size: 15,
			page: 0,
            sort: ""
		},
		partnerHistory: [],
		currentPartner: {},
        currentPartnerInfo: {},
	})
    const {t} = useI18n()
    const setPartnerInfo = async (partnerId) => {
		const partner = state.partnerList.filter((partnerItem) => partnerItem.partnerId === partnerId)
		if (partner.length > 0) {
			state.currentPartner = partner[0]
		}
	}

    const setCurrentPartnerInfo = async (partnerId) => {
        try {
			let response = await partnerAPI.getPartnerInfo(partnerId)

			if (response.status === 200) {
				state.currentPartnerInfo = response.data
                state.currentPartnerInfo.BCC = response.data.emailBcc.split(",")
			} else {
				console.log(t("message.msg_202"))
			}
		} catch (error) {
			if (error.response.data.message != null && error.response.data.message != undefined) {
				alert(t("message.msg_203") + "\n" + error.response.data.message)
				console.log(t("message.msg_203") + "\n" + error.response.data.message)
			} else {
				alert(error)
			}
		}
	}
	const setPartnerFilter = (filters) => {
		const newFilters = { ...state.partnerFilter }
		for (const key in filters) {
			if (Object.prototype.hasOwnProperty.call(filters, key)) {
				newFilters[key] = filters[key]
			}
		}

		state.partnerFilter = newFilters
	}
    const fetchPartnerList = async() => {
        try {
			if (jwt.getAccessToken() == null) {
				return null
			} //unmounted에서 호출 시 logout 시에도 호출되는 경우가 있어 체크
			let response = await partnerAPI.getPartnerList(state.partnerFilter)

			if (response.status === 200) {
				state.partnerPageInfo = {totalPages: response.data.totalPages, totalElements: response.data.totalElements, currentPage: response.data.pageable.pageNumber + 1}
				state.partnerList = response.data.content
				if (state.partnerList != null && state.partnerList.length > 0) {
					state.currentPartner = state.partnerList[0]
					//fetchReportHistory(state.reportList[0].reportId)
				} else {
					state.currentPartner = {}
					state.partnerHistory = []
				}
			} else {
				console.log(t("message.msg_202"))
			}
		} catch (error) {
			if (error.response.data.message != null && error.response.data.message != undefined) {
				alert(t("message.msg_203") + "\n" + error.response.data.message)
				console.log(t("message.msg_203") + "\n" + error.response.data.message)
			} else {
				alert(error)
			}
		}
    }
    const fetchPartnerHistory = async(partnerId) => {
        try {
			let response = await partnerAPI.getPartnerHistory(partnerId)

			if (response.status === 200) {
				state.partnerHistory = response.data.content
			} else {
				console.log(t("message.msg_202"))
			}
		} catch (error) {
			if (error.response.data.message != null && error.response.data.message != undefined) {
				alert(t("message.msg_203") + "\n" + error.response.data.message)
				console.log(t("message.msg_203") + "\n" + error.response.data.message)
			} else {
				alert(error)
			}
		}
    }
    const getPartnerList = computed(() => state.partnerList)
	const getPartnerPageInfo = computed(() => state.partnerPageInfo)
	const getPartnerFilter = computed(() => state.partnerFilter)

    const getPartnerHistory = computed(() => state.partnerHistory)
	const getCurrentPartner = computed(() => state.currentPartner)
    const getCurrentPartnerInfo = computed(() => state.currentPartnerInfo)
    return {
        fetchPartnerList,
        setPartnerInfo,
        setPartnerFilter,
        setCurrentPartnerInfo,
        fetchPartnerHistory,
        getPartnerList,
        getPartnerPageInfo,
        getPartnerFilter,
        getPartnerHistory,
        getCurrentPartner,
        getCurrentPartnerInfo,

    }
})
<template>
	<section class="main_container">
		<article class="main_left" :style="{width: infoFlag ? '74%' : '100%'}">
			<div class="title">{{ t("message.msg_201") }}</div>
			<div class="left_title">
				<div class="list_btns">
					<button class="list_up" @click="toggleFilter"></button>
				</div>
				<div class="list_search">
					<div class="list_select">
						<select id="batchRequest" v-model="selectedBatch" name="batchRequest">
							<option v-for="batchFunc in batchFuncs" :key="batchFunc.value" :value="batchFunc.value">{{ batchFunc.text }}</option>
						</select>
						<button @click.stop.prevent="onClickBatchFunc">{{ t("message.msg_230") }}</button>
					</div>
					<p class="list_total">{{ t("message.msg_232") }}<span>: {{ reportStore.getReportPageInfo.totalElements }}</span></p>
					<button class="list_reset" @click.stop.prevent="reportStore.fetchReportList"></button>
				</div>
			</div>

			<div class="left_list_table">
				<table>
					<thead>
						<tr>
							<td style="width: 1%;"><input v-model="chkSelectAll" type="checkbox" @change="selectAll" /></td>
							<td style="width: 20%;"><span @click.stop.prevent="updateSort('reportNm')">{{ t("message.msg_018") }}</span> <img :src="getSortIcon('reportNm')" alt="" /></td>
							<td><span @click.stop.prevent="updateSort('partnerNm')">{{ t("message.msg_550") }}</span> <img :src="getSortIcon('partnerNm')" alt="" /></td>
							<td><span @click.stop.prevent="updateSort('price')">{{ t("message.msg_085") }}</span> <img :src="getSortIcon('price')" alt="" /></td>							
							<td><span @click.stop.prevent="updateSort('status')">{{ t("message.msg_204") }}</span> <img :src="getSortIcon('status')" alt="" /></td>
							<td><span @click.stop.prevent="updateSort('approvalStatus')">{{ t("message.msg_052") }}</span> <img :src="getSortIcon('approvalStatus')" alt="" /></td>
							<td><span @click.stop.prevent="updateSort('readStatus')">{{ t("message.msg_014") }}</span> <img :src="getSortIcon('readStatus')" alt="" /></td>
							<td><span @click.stop.prevent="updateSort('replyStatus')">{{ t("message.msg_224") }}</span> <img :src="getSortIcon('replyStatus')" alt="" /></td>
							<td><span @click.stop.prevent="updateSort('modifiedDate')">{{ t("message.msg_041") }}</span> <img :src="getSortIcon('modifiedDate')" alt="" /></td>
							<td></td>
						</tr>
					</thead>
					<tbody v-if="reportList.length!=0">
						<template v-for="report in reportList" :key="report.reportId"> 
							<tr :class="{selected: isSelected(report)}" @click="clickReport($event, report.reportId)">
								<th><input v-model="chkSelect[report.reportId]" type="checkbox" @change="selectReport(report.reportId)" /></th>
								<th>{{ report.reportNm }}<span v-if="report.createdBy.indexOf(report.companyId.replaceAll('-', '')) > 0"><img style="width: 15px; margin-left: 2px;" :src="icons.uploader" /></span></th>
								<th>{{ report.partnerNm }}</th>
								<th>{{ report.price }}</th>
								<th>{{ getOpenStatusText(report.status) }}</th>
								<th>{{ getApprovalStatusText(report.approvalStatus) }}</th>
								<th>{{ getReadStatusText(report.readStatus) }}</th>
								<th :title="report.replyStatus == 'REPLIED' ? report.replyOk + ', ' + report.replyMemo : ''">{{ getReplyStatusText(report.replyStatus) }}</th>
								<th>{{ format(report.modifiedDate, "yyyy-MM-dd HH:mm:ss") }}</th>
								<th>
									<div class="list_btn">
										<button class="list_open" :title="funcList[0].text" :disabled="report.status != 'READY'" @click.stop.prevent="onClickFunc(report.reportId, funcList[0].value)"></button> <!-- 공개 -->
										<button class="list_reply" :title="funcList[3].text" :disabled="report.status != 'OPENED' || report.replyStatus != 'READY'" @click.stop.prevent="onClickFunc(report.reportId, funcList[3].value)"></button> <!-- 회신 요청 메일 발송 -->
										<button class="list_delete" :title="funcList[4].text" :disabled="report.status == 'OPENED'" @click.stop.prevent="onClickFunc(report.reportId, funcList[4].value)"></button> <!-- 삭제 -->
										<div class="list_modal">
											<button class="list_more" @click.stop.prevent="clickMore($event)"></button>
											<div class="modal">
												<ul v-on-click-outside="onClickOutside">
													<template v-for="func in funcList" :key="func.value">
														<li v-if="checkValidFunc(report, func.value)"><a @click.stop.prevent="onClickFunc(report.reportId, func.value)">{{ func.text }}</a></li>
													</template>
													<template v-for="dl in downloadType" :key="dl.value">
														<li v-if="checkValidDLType(report, dl.value)"><a @click.stop.prevent="onClickDownload(report, dl.value)">{{ dl.text }}</a></li>
													</template>
												</ul>
											</div>
										</div>
									</div>
								</th>
							</tr>
						</template>                           
					</tbody>
				</table>
			</div>
			<CommonPagination v-if="totalPages != undefined" ref="pagination" :total-pages="totalPages" @move-page="movePage" />
		</article>
		<article v-if="infoFlag" class="main_right">
			<ReportInfoMain @close-info="closeInfo" />
		</article>
		<teleport to="body">
			<ReportDueDateModal ref="expModal" />
		</teleport>
	</section>
</template>

<script setup>
import {useI18n} from "vue-i18n"
const {t} = useI18n()
import {computed, ref, onMounted, watch, defineEmits} from "vue"
import {useReportStore} from "@/store/reportStore"
import ReportInfoMain from "./reportInfos/ReportInfoMain"
import ReportDueDateModal from "./ReportDueDateModal"
import CommonPagination from "@/components/common/CommonPagination"
import {useFunc, downloadReport, getOpenStatusText, getApprovalStatusText, getReplyStatusText, getReadStatusText} from "@/composable/report"
import _ from "lodash"
import {format} from "date-fns"
import { vOnClickOutside } from '@vueuse/components'
import icons from "@/common/icons"
import jwt from "@/common/jwt"

const batchFuncs = [
	{value: "OPEN", text: t("message.msg_229")},
	{value: "CLOSE", text: t("message.msg_234")},
	{value: "SEND-READ", text: t("message.msg_235")},
	{value: "SEND-REPLY", text: t("message.msg_236")},
	{value: "DELETE", text: t("message.msg_240")},
	{value: "RESET-EXP", text: t("message.msg_237")},
]

const funcList = [
	{value: "OPEN", text: t("message.msg_229")},
	{value: "CLOSE", text: t("message.msg_234")},
	{value: "SEND-READ", text: t("message.msg_235")},
	{value: "SEND-REPLY", text: t("message.msg_236")},
	{value: "DELETE", text: t("message.msg_240")},
	{value: "RESET-EXP", text: t("message.msg_237")},
]

const downloadType = [
	{value: "pdf", text: t("message.msg_250")},
	{value: "all", text: t("message.msg_058")},
	{value: "dl", text: t("message.msg_233")}
	//{value: "csv", text: t("message.msg_239")},
]

const reportStore = useReportStore()
const {fetchReportHistory, setReportInfo} = reportStore
const reportList = ref([])
const chkSelect = ref({})
const chkSelectAll = ref(false)
const selectedBatch = ref(batchFuncs[0].value)
const selectedReportList = ref([])
const expModal = ref(null)
const pagination = ref(null)
const infoFlag = ref(false)
const sortItem = ref("modifiedDate")
const sortDirection = ref("desc")
const sortCount = ref(0)
const totalPages = ref(1)

const emit = defineEmits(["toggle-filter"])

onMounted(async() => {
	await reportStore.fetchReportList()
	reportList.value = _.cloneDeep(reportStore.getReportList)
	totalPages.value = reportStore.getReportPageInfo.totalPages
})

watch(() =>	reportStore.getReportList, (newValue) => {
	reportList.value = _.cloneDeep(newValue)
	totalPages.value = reportStore.getReportPageInfo.totalPages
	pagination.value.setPage(reportStore.getReportPageInfo.currentPage)
})

watch(() => reportStore.getReportFilter, (newValue) => {
	if (newValue.sort != (sortItem.value + "," + sortDirection.value)) {
		sortDirection.value = "desc"
		sortItem.value = "modifiedDate"
		sortCount.value = 0
	} //filter reset 시 default value로 변경
})

const userEmail = computed(() => {
	return jwt.decodeToken(jwt.getAccessToken()).email
})

const checkAuth = (report) => {
	return report.companyUserEmail != userEmail.value
}

const onClickBatchFunc = async () => {
	if (selectedReportList.value.length <= 0) {
		alert(t("message.msg_249"))
		return
	}
	let payload
	if (selectedBatch.value == funcList[5].value) {
		let period = await expModal.value.show()
		if (period == false) {
			return
		}
		payload = {
			reportIds: selectedReportList.value,
			period: period,
		}
	} else {
		payload = selectedReportList.value
	}
	await useFunc(payload, selectedBatch.value)
	clear()
}

const onClickFunc = async (reportId, opt) => {
	let payload
	if (opt == funcList[5].value) {
		let period = await expModal.value.show()
		if (period == false) {
			return
		}
		payload = {
			reportIds: [reportId],
			period: period,
		}
	} else {
		payload = [reportId]
	}

	await useFunc(payload, opt)
	clear()
}

const checkValidFunc = (report, func) => {
	switch(func){
		case funcList[0].value: {
			return report.status == "READY"
		} //open
		case funcList[1].value: {
			return report.status == "OPENED"
		} //close
		case funcList[2].value: {
			return report.status == "OPENED" && report.readStatus == "READY"
		} //send-read
		case funcList[3].value: {
			return report.status == "OPENED" && report.replyStatus == "READY"
		} //send-reply
		case funcList[4].value: {
			return report.status != "OPENED"
		} //delete
		case funcList[5].value: {
			break
		} //reset-exp
	}
	return true
}

const checkValidDLType = (report, type) => {
	switch(type) {
		case "all": {
			if(report.fileCount > 1) {
				return true
			}
			break;
		}
		case "pdf":
		case "dl":
			return true
	}
	return false
}

const clear = () => {
	setReportInfo("")
	selectedReportList.value = []
	chkSelect.value = []
	chkSelectAll.value = false
}

const onClickDownload = (report, type) => {
	downloadReport(report, type)
}

const clickReport = (evt, reportId) => {
	if(evt.target.type != undefined && evt.target.type == "checkbox") {
		return
	}
	setReportInfo(reportId)
	fetchReportHistory(reportId)
	infoFlag.value = true
}

const selectReport = (reportId) => {
	var flag = chkSelect.value[reportId]
	if (flag) {
		selectedReportList.value.push(reportId)
		if (selectedReportList.value.length == reportList.value.length) {
			chkSelectAll.value = true
		}
	} else {
		chkSelectAll.value = false
		selectedReportList.value.splice(selectedReportList.value.findIndex((r) => r === reportId), 1)
	}
}

const selectAll = () => {
	if (chkSelectAll.value) {
		selectedReportList.value = reportList.value.map((r) => {
			return r.reportId
		})
	} else {
		selectedReportList.value = []
	}

	for(let r of reportList.value) {
		chkSelect.value[r.reportId] = chkSelectAll.value
	}
}

const clickMore = (event) => {
	event.stopPropagation()
	var modal = event.target.nextElementSibling
	var flag = modal.style.display == "none" || modal.style == undefined || modal.style.display == ""
	if (flag){
		modal.style.display = "block"
	} else {
		modal.style.display = "none"
	}
}

const onClickOutside = (event) => {
	document.querySelectorAll(".modal").forEach((modal) => {
		var icon = modal.previousElementSibling
		if(event.target != icon){
			modal.style.display = "none"
		}
	})
}

const toggleFilter = () => {
	emit("toggle-filter")
}

const closeInfo = () => {
	infoFlag.value = false
}

const movePage = async (payload) => {
	await reportStore.setReportFilter({page: payload.page - 1})
	await reportStore.fetchReportList()
}

const updateSort = async (item) => {
	if (sortItem.value != item) {
		sortCount.value = 0
	}
	sortItem.value = item
	sortCount.value++

	if (sortCount.value == 1) {
		sortDirection.value = "asec"
	} else if (sortCount.value == 2) {
		sortDirection.value = "desc"
	}else if (sortCount.value == 3) {
		sortCount.value = 0
		sortItem.value = null
		sortDirection.value = null
	}

	if (sortItem.value != null) {
		await reportStore.setReportFilter({sort: sortItem.value + "," + sortDirection.value})
		await reportStore.fetchReportList()
	}
}

const getSortIcon = (item) => {
  if (sortItem.value === item) {
    return sortCount.value == 1 ? icons.tableUp : icons.tableDown;
  }
  return "";
}

const getVisibility = (item) => {
  if (sortItem.value === item) {
    return 'visible';
  }
  return 'hidden';
}

const isSelected = (report) => {
	return _.isEqual(reportStore.getCurrentReport, report) && infoFlag.value
}

</script>

<style scoped src="@/assets/css/pc/reportList.css">
</style>

<style scoped>
.selected {
	background-color: var(--vc-gray-100);
}
</style>
<template>
	<div style="width: 100%; height: 100%; align-items: center; display: flex; flex-direction: column;">
		<div class="input_wrap">
			<label><span class="required">*</span>{{ t("message.msg_503") }}</label>
			<select v-model="userEmail" name="email">
				<option disabled value="none">{{ t("message.msg_502") }} ({{ t("message.msg_503") }})</option>
				<option v-for="(user, index) in userArr" :key="index" :value="user.email"> {{ user.name + "(" + user.email + ")" }} </option>
			</select>
		</div>
		<!-- <div class="input_wrap">
			<label for="email">{{ t("message.msg_516") }} {{ t("message.msg_502") }}</label>
			<input v-model="userName" type="text" :disabled="true" />
		</div>
		<div class="input_wrap">
			<label for="email">{{ t("message.msg_503") }}</label>
			<input v-model="userEmail" type="email" name="email" />
		</div> -->
		<div class="input_wrap">
			<label><span class="required">*</span>{{ t("message.msg_550") }} {{ t("message.msg_510") }}</label>
			<input v-model="partnerCompanyName" type="text" />
		</div>
		<div class="input_wrap">
			<label for="email">{{ t("message.msg_567") }}</label>
			<input v-model="partnerCode" type="text" maxlength='16' />
		</div>
		<!-- <div class="input_wrap">
			<label>{{ t("message.msg_550") }} {{ t("message.msg_511") }}</label>
			<input v-model="partnerCompanyNo" type="text" :disabled="true" />
		</div> -->
		<div class="input_wrap">
			<label for="email">{{ t("message.msg_550") }} {{ t("message.msg_505") }}</label>
			<input v-model="partnerDivision" type="text" />
		</div>
		<div class="input_wrap">
			<label for="email"><span class="required">*</span>{{ t("message.msg_550") }} {{ t("message.msg_516") }} {{ t("message.msg_502") }}</label>
			<input v-model="partnerUserName" type="text" />
		</div>
		<div class="input_wrap">
			<label for="email"><span class="required">*</span>{{ t("message.msg_550") }} {{ t("message.msg_516") }} {{ t("message.msg_503") }}</label>
			<input v-model="partnerUserEmail" type="text" name="email" />
		</div>
		<div class="input_wrap bcc_wrap">
			<label for="bcc">{{ t("message.msg_555") }}</label>
			<div class="bcc">
				<div class="bcc_wrap">
					<div v-for="(item, index) in BCC" :key="index" style="display: flex;" class="bcc_plus">
						<div class="bcc_list" style="width: 80%;">
							<input v-model="BCC[index]" type="text" style=" width: 90%; border: none;" />
							<button class="x_mark" @click="deleteEvent(index)"></button>
						</div>
					</div>	
				</div>
				<button class="plus" @click="insertEvent"></button>
			</div>
		</div>

		<!-- <div class="input_wrap">  //거래처 수정 팝업에 보여지지 않아도 될거 같아서 제거함
			<label for="email">{{ t("message.msg_551") }}</label>
			<div class="side_check">
				<label class="container">{{ t("message.msg_552") }}
					<input v-model="mailType" type="radio" value="NONE" name="radio3" /> 
					<span class="checkmark"></span>
				</label>
				<label class="container">{{ t("message.msg_553") }}
					<input v-model="mailType" type="radio" value="MAIL" name="radio3" />
					<span class="checkmark"></span>
				</label>
				<label class="container">{{ t("message.msg_554") }}

					<input v-model="mailType" type="radio" value="ATTACH" name="radio3" /> 
					<span class="checkmark"></span>
				</label>
			</div>
		</div> -->


		<!-- <div class="input_wrap">
			<label for="email">{{ t("message.msg_560") }}</label>
			<input v-model="requestFlag" type="text" :disabled="true" />
		</div>
		<div class="input_wrap">
			<label for="email">{{ t("message.msg_568") }}</label>
			<input v-model="requestDate" type="text" :disabled="true" :value=" makeDate(requestDate)" />
		</div>
		<div class="input_wrap">
			<label for="email">{{ t("message.msg_517") }}</label>
			<input v-model="registeredFlag" type="text" :disabled="true" />
		</div>
		<div class="input_wrap">
			<label for="email">{{ t("message.msg_569") }}</label>
			<input v-model="registeredDate" type="text" :disabled="true" :value=" makeDate(registeredDate)" />
		</div> -->
		<button id="modify" type="button" class="save" @click="modify">{{ modifyButton }}</button>
	</div>
</template>
<script setup>

import partnerAPI from "@/api/partner_api"
import {ref, watch, defineProps, defineEmits, onMounted} from "vue"
import {useI18n} from "vue-i18n"
import _ from "lodash"
import {format} from "date-fns"
import userAPI from "@/api/user_api"

const {t} = useI18n()
const props = defineProps({
	selectId: {type: String, required: true}
})
const userEmail = ref ("")
const partnerCompanyName = ref("")
const partnerCompanyNo = ref("") //juank24.05.27 #26341 회사 법인번호를 사용하지 않기로 해서 해당 내용을 html에서 주석처리하고 스크립트 빈값으로 남겨 놓음
const partnerDivision = ref("")
const partnerUserName = ref("")
const partnerUserEmail = ref("")
const BCC = ref([])
const partnerCode = ref("")
const mailType = ref("") //juank24.05.27 #26341 mailType를 사용하지 않기로 해서 해당 내용을 html에서 주석처리하고 스크립트 빈값으로 남겨 놓음
const requestFlag = ref("")
const requestDate = ref("")
const registeredFlag = ref("")
const registeredDate = ref("")
const modifyButton = ref(t('message.msg_514'))
const userArr = ref([]) //juank24.05.27 #26342 거래처 추가/수정시 sender권한에 사용자 목록

const emit = defineEmits(["closeModal"])
onMounted(async() =>{
	userAPI
	.getCompanyUserList()
	.then((res) => {
		for(var i = 0; i < res.data.content.length; i++){
			if(res.data.content[i].authority.includes("SENDER")){
				userArr.value.push(res.data.content[i])
			}
		}
	})
	.catch(async (error) => {
		alert(error.response.data.message)
	})
})
const closeModal = () => {
	emit("closeModal");
}

const modify = async () => {
	let payload = 
    {
        id: props.selectId,
        userEmail: userEmail.value,
        partnerCompanyName: partnerCompanyName.value,
        partnerDivisionName: partnerDivision.value,
        partnerUserName: partnerUserName.value,
        partnerUserEmail: partnerUserEmail.value,
        emailBcc: "",
        mailType: mailType.value,
        partnerCode: partnerCode.value
    }
                
    for(var i = 0; i < BCC.value.length; i++) {
        payload.emailBcc += BCC.value[i]
        if(i + 1 < BCC.value.length)
            payload.emailBcc += ","
    }
    try {
        await partnerAPI.modifyPartner(payload)
        alert(t('message.msg_533'))
		closeModal()
    } catch (error) {
        alert(error.response.data.message);
    }
}

const deleteEvent = (index) => {
	BCC.value.splice(index, 1);
}
const insertEvent = () => {
	BCC.value.push("");
}
//24.02.19 #26046 juank id값을 받아 거래처 정보를 가져온다
watch(
	() => props.selectId,
	(selectId) => {
		if (selectId) {
			partnerAPI.getPartnerInfo(props.selectId)
            .then((res) => {
                userEmail.value = res.data.userEmail
                partnerCompanyName.value = res.data.partnerCompanyName
                partnerCompanyNo.value = res.data.partnerCompanyNo
                partnerDivision.value = res.data.partnerDivisionName
                partnerUserName.value = res.data.partnerUserName
                partnerUserEmail.value = res.data.partnerUserEmail
				BCC.value = [] //bcc가 없는 경우에 기존에 bcc가 있었던 정보를 열었으면 그 정보가 남아 있어서 초기화 함
				if(res.data.emailBcc.split(",") != "")
					BCC.value = res.data.emailBcc.split(",")
                partnerCode.value = res.data.partnerCode
                mailType.value = res.data.mailType
                requestFlag.value = res.data.requestFlag
                requestDate.value = res.data.requestedDate
                registeredFlag.value = res.data.registerFlag
                registeredDate.value = res.data.registeredDate
				modifyButton.value = t('message.msg_514')
				// for(var i = 0; i < BCC.value.length; i++) {
				// 	BCCTemp.value[i] += BCC.value[i]
				// }
            })
            .catch(async (error) => {
                alert(error.response.data.message);
            })
		}
	}
)

const makeDate = (date) => {
	if(date != "" && date != null)
		return format(date, "yyyy-MM-dd HH:mm:ss")
	else
		return ""
}

</script>  
  
<style scoped src="@/assets/css/pc/account.css">
</style>
  
<template>
	<div id="sideMenu">
		<button class="side_close_btn" @click="closeFilter"></button>
		<button class="side_reset_btn" @click="reset"></button>
		<button class="share_mint" @click="editSharedBox(add, 0)">
			{{ t("message.msg_320") }}
		</button>
		<div class="side_list">
			<h2 class="open_title">{{ t("message.msg_328") }}</h2>
			<div class="share_side">
				<div v-for="(item, index) in shareBoxList" :key="index" class="share_list" :class="{selected: isSelected(item)}" @click="clickShareBox(item, index)">
					<p>
						<span><img :src="$icons.share_document" alt="" /></span>
						{{ item.name }}
					</p>
					<div class="side_share_btns">
						<button class="share_btn" @click.stop="editSharedBox(item, 1)"></button>
						<button class="trash_btn" @click.stop="editSharedBox(item, 2)"></button>
					</div>
				</div>
			</div>
		</div>
	</div>
	<template v-if="showEditShareBoxUserModal">
		<shared-box-modal @close-modal="close()">
			<edit-shared-box-user-list
				:type="type"
				:mode="mode"
				:item="item"
				@close="close()"
			/>
		</shared-box-modal>
	</template>
</template>


<script setup>
import { ref, defineEmits, watch, defineProps, computed, onMounted } from "vue";
import { useShareBoxStore } from "@/store/shareBoxStore.js";
import _ from "lodash";
import { useI18n } from "vue-i18n";
import EditSharedBoxUserList from "@/components/sharedBox/EditSharedBoxUserList";
import SharedBoxModal from "@/components/sharedBox/SharedBoxModal";

const shareBoxStore = useShareBoxStore();
const { fetchShareBoxReportList } = shareBoxStore;
const emit = defineEmits(["resetSharedBoxList", "editSharedBox", "toggle-filter"]);
const shareBoxList = ref([]);
const { t } = useI18n();
const item = ref(null);
const mode = ref(0);
const showEditShareBoxUserModal = ref(false);
const currentShareBox = computed(() => shareBoxStore.getCurrentShareBox)

defineProps({
  shareBoxList: { type: Array, required: true },
  type: { type: String, required: true },
});

onMounted(async() => {
	shareBoxStore.setShareBox({});
})

watch(() => shareBoxStore.getShareBoxList, (newValue) => {
  shareBoxList.value = _.cloneDeep(newValue);
});

const clickShareBox = (item, index) => {
  shareBoxStore.setShareBox(item);
  fetchShareBoxReportList(item.sharedBoxId);
  closeFilter();
};

const close = () => {
	showEditShareBoxUserModal.value = false;
	clickShareBox(currentShareBox.value)
}

const editSharedBox = (itemData, modeValue) => {
	if(modeValue != 0){
		shareBoxStore.setShareBox(itemData);
	}

  item.value = itemData;
  mode.value = modeValue;
  showEditShareBoxUserModal.value = true;
};
const reset = () => {
  emit("resetSharedBoxList");
};

const closeFilter = () => {
  emit("toggle-filter");
};

const isSelected = (item) => {
	return _.isEqual(shareBoxStore.getCurrentShareBox, item)
}

</script>

<style scoped src="@/assets/css/pc/share.css">
button {
	margin: 1em 0 0 1em;
}
.item {
	height: 3em;
	text-align: center;
	cursor: pointer;
}
.item:hover {
	background-color: rgb(113, 122, 122);
}
.editButton {
	/* z-index: 10; */
	position: relative;
	left: 0.5em;
}
.item.active {
	background-color: rgb(113, 122, 122);
	font-weight: bold;
}
</style>

<style scoped>
.selected {
	background-color: var(--vc-gray-100);
}
</style>
<template>
	<div class="secret_popup">
		<div class="popup_wrap">
			<div class="popup">
				<div class="popup_inner">
					<slot></slot>
				</div>
				<button class="close_btn" @click="closeModal"></button>
			</div>
		</div>
	</div>
</template>

<script setup>
import { defineEmits } from 'vue';
const emit = defineEmits(["closeModal"])
// const title = ref(props.title);

const closeModal = () => {
  emit("closeModal");
};

</script>

<style scoped>
@media screen and (max-width: 1330px) {
	.popup {
		max-height: 80vh;
		overflow-y: auto;
		padding: 5rem;
	}

	.popup_wrap {
        width: 60%;
        overflow-x: auto;
    }

	
    .secret_popup .popup {
        min-width: 585px;
        position: relative;
		max-height: 80vh;
		overflow-y: auto;
		padding: 3rem;
    }
}

@media screen and (max-width: 1024px) {
    .popup_wrap {
        width: 60%;
        overflow-x: auto;
    }

    /* 아래의 모든 코드는 영역::코드로 사용 */
    .popup_wrap::-webkit-scrollbar {
        width: 7px;
        height: 7px;
        /* 스크롤바의 너비 */
        padding-right: 10px;
    }

    .popup_wrap::-webkit-scrollbar-thumb {
        height: 7px;
        /* 스크롤바의 길이 */
        background: #bbbbbb;
        /* 스크롤바의 색상 */
        border-radius: 50px;
    }

    .popup_wrap::-webkit-scrollbar-track {
        background: rgba(92, 92, 92, 0.1);
        /*스크롤바 뒷 배경 색상*/
    }


    .secret_popup .popup {
        min-width: 585px;
        position: relative;
		max-height: 80vh;
		overflow-y: auto;
		padding: 3rem;
    }

    .popup>button.close_btn {
        top: 0;
    }
}

.popup_wrap {
    background: #fff;
    width: 67%;
    position: relative;
    top: 50%;
    left: 50%;
    padding-top: 2rem;
    transform: translate(-50%, -50%);
    border-radius: 40px;
}

.secret_popup {
    position: fixed;
	width: 100%;
    height: 100%;
	top: 0;
	left: 0;
    background: #00000080;
    z-index: 9;
    /*display: none;*/
}

.popup {
    max-height: 80vh;
    overflow-y: auto;
    padding: 3rem;
}

.popup>button.close_btn {
    background: none;
    background-image: url(../../assets/img/pc/side_xmark.png);
    width: 35px;
    height: 35px;
    background-size: cover;
    cursor: pointer;
    position: absolute;
    top: 2.5rem;
    right: 2.5rem;
	border: 0px;
}

.popup_inner {
 margin-top: 2rem
}

.popup_inner p {
    text-align: center;
    font-size: 1.4rem;
    line-height: 1.7;
}

.popup_inner input {
    width: 500px;
    padding: 15px 20px;
    font-size: 1rem;
    border-radius: 50px;
    margin: 4rem 0 10rem;
    outline: none;
}

.popup_inner button {
    background-color: var(--main-color);
    color: var(--white);
    width: 500px;
    padding: 15px;
    font-size: 1rem;
    border-radius: 50px;
}
</style>

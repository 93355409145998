<template>
	<div id="State" class="tabCont tabcontent">
		<div class="detail detail02">
			<!-- <span>{{ t("message.msg_646") }} {{ historyList.length }} {{ t("message.msg_647") }}</span> --><strong>기본내용</strong>
			<table>
				<thead>
					<tr>
						<th>{{ t("message.msg_039") }}</th>
						<th>{{ t("message.msg_027") }}</th>
						<th>{{ t("message.msg_040") }}</th>
						<th>{{ t("message.msg_048") }}</th>
					</tr>
				</thead>
				<tbody>
					<MobileReportHistoryItem v-for="history in historyList" :key="history.id" :report-history="history" />
				</tbody>
			</table>
		</div>
		<div class="btn_ok_wrap">
			<button class="btn_ok" @click="closeInfo()">{{ t("message.msg_068") }}</button>
		</div>
	</div>
</template>

<script setup>
import MobileReportHistoryItem from "./MobileReportHistoryItem.vue"
import {useI18n} from "vue-i18n"
import {computed, defineEmits} from "vue"
import {useReportStore} from "@/store/reportStore"

const reportStore = useReportStore()
const {t} = useI18n()

const historyList = computed(() => reportStore.getReportHistory)

const emit = defineEmits(["close-info"])

const closeInfo = () => {
	emit("close-info")
}

</script>

<style scoped src="@/assets/css/mobile/ticket.css">
</style>

import axios from "axios"
import jwt from "./jwt"

import constant from "./constants"

let isTokenRefreshing = false
let refreshSubscribers = []

const onTokenRefreshed = () => {
	refreshSubscribers.map((callback) => callback())
	refreshSubscribers = []
}

const addRefreshSubscribers = (callback) => {
	refreshSubscribers.push(callback)
}

const http = axios.create({
	headers: {"content-type": "application/json", "Referer-path": location.origin},
})

http.interceptors.request.use(
	(config) => {
		const isAuthenticated = !!jwt.getToken()
		if (isAuthenticated) {
			config.headers.Authorization = jwt.getAccessToken()
		}
		if (isTokenRefreshing) {
			config.headers["Authorization-Refresh"] = jwt.getRefreshToken()
		}
		return config
	},
	(error) => {
		Promise.reject(error)
	}
)

http.interceptors.response.use(
	(res) => {
		return res
	},
	async (error) => {
		const status = error.response.status
		const originalRequest = error.config
		if (status === 401 && !!jwt.getRefreshToken()) {
			if (!isTokenRefreshing) {
				//need to check mesg
				isTokenRefreshing = true
				const response = await http.post(constant.TOKEN_REFRESH_URL)
				jwt.setToken(response.data)

				originalRequest.headers.Authorization = response.data.accessToken
				await onTokenRefreshed()
				isTokenRefreshing = false
				return new Promise(() => {
					http(originalRequest)
				})
			}
			const retryOriginalRequest = new Promise((resolve) => {
				addRefreshSubscribers(() => {
					originalRequest.headers.Authorization = jwt.getAccessToken()
					resolve(http(originalRequest))
					console.trace()
				})
			})
			return retryOriginalRequest
		}

		if (isTokenRefreshing && status === 400) {
			alert(error)
			jwt.deleteToken()
			location.replace("/login")
		}

		if (status === 500) {
			console.log(error.response.data)
			alert(error.response.data.phrase)
		}

		return Promise.reject(error)
	}
)

export default http

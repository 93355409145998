<template>
	<common-modal @close-modal="closeModal">
		<p>{{ t("message.msg_576") }}</p>
		<input v-model="secretCode" type="text" />
		<button @click="request">{{ t("message.msg_561") }}</button>
	</common-modal>
</template>
<script setup>
import partnerAPI from "@/api/partner_api"
import {useI18n} from "vue-i18n"
import {ref, defineEmits} from "vue"

const {t} = useI18n()
const secretCode = ref ("")
const emit = defineEmits(["closeModal"])

const request = async() => {
    try {
        let response = await partnerAPI.requestPartner(secretCode.value)
        
        if (response.status === 200) {   
            alert(t("message.msg_575"))
            closeModal()
        }
        else{
            alert(response.status + t("message.msg_203"))
        }
    } catch (error) {
        alert(error.response.data.message);
    }
    secretCode.value = ""
}

const closeModal = () => {
  emit("closeModal");
};
</script>
<style scoped src="@/assets/css/pc/received.css">

</style>
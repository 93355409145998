<!-- eslint-disable prettier/prettier -->
<template>
	<div id="Basic" class="tabCont tabcontent">
		<div class="detail">
			<strong>{{ t("message.msg_550") }} {{ t("message.msg_643") }}</strong>
			<ul>
				<li><strong>{{ t("message.msg_516") }} {{ t("message.msg_502") }}</strong> <span>{{ partner.partnerUserName }}</span></li>
				<li><strong>{{ t("message.msg_503") }}</strong> <span>{{ partner.partnerUserEmail }}</span></li>
			</ul>
		</div>
		<div class="detail detail02">
			<strong>{{ t("message.msg_550") }} {{ t("message.msg_528") }}</strong>
			<ul>
				<li><strong>{{ t("message.msg_550") }} {{ t("message.msg_510") }}</strong> <span>{{ partner.partnerCompanyName }}</span></li>
				<!-- <li><strong>{{ t("message.msg_550") }} {{ t("message.msg_511") }}</strong> <span>{{ partner.partnerCompanyNo }}</span></li> -->
				<li><strong>{{ t("message.msg_550") }} {{ t("message.msg_505") }}</strong> <span>{{ partner.partnerDivision }}</span></li>
			</ul>
		</div>
		<div class="detail detail02">
			<strong>{{ t("message.msg_649") }}</strong>
			<ul>
				<li>
					<strong>{{ t("message.msg_555") }}</strong> <span>
						<ul style="list-style:none;">
							<li v-for="item in partner.BCC" :key="item">
								{{ item }}
							</li>
						</ul>
					</span>
				</li>
				<li><strong>{{ t("message.msg_567") }}</strong> <span>{{ partner.partnerCode }}</span></li>
				<!-- <li><strong>{{ t("message.msg_551") }}</strong> <span>{{ partner.mailType }}</span></li> -->
				<li><strong>{{ t("message.msg_560") }}</strong> <span>{{ partner.requestFlag }}</span></li>
				<li><strong>{{ t("message.msg_568") }}</strong> <span>{{ makeDate(partner.requestedDate) }}</span></li>
				<li><strong>{{ t("message.msg_517") }}</strong> <span>{{ partner.registerFlag }}</span></li>
				<li><strong>{{ t("message.msg_569") }}</strong> <span>{{ makeDate(partner.registeredDate) }}</span></li>
			</ul>
		</div>
	</div>
</template>

<script setup>
import {useI18n} from "vue-i18n"
import {computed} from "vue"
import {usePartnerStore} from "@/store/partnerStore"
import {format} from "date-fns"

const partnerStore = usePartnerStore()
const {t} = useI18n()

const partner = computed(() => partnerStore.getCurrentPartnerInfo)

const makeDate = (date) => {
	if(date != "" && date != null)
		return format(date, "yyyy-MM-dd HH:mm:ss")
	else
		return ""
}

</script>

<style scoped src="@/assets/css/pc/account.css"></style>

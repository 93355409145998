<template>
	<div style="width: 100%; height: 100%; align-items: center; display: flex; flex-direction: column;">
		<div class="input_wrap">
			<label for="email">{{ t("message.msg_516") }}</label>
			<input v-model="user.name" type="text" />
		</div>
		<div class="input_wrap">
			<label for="email">{{ t("message.msg_510") }} {{ t("message.msg_503") }}</label>
			<input v-model="user.email" type="text" :disabled="true" />
		</div>
		<div class="input_wrap">
			<label>{{ t("message.msg_505") }}{{ t("message.msg_502") }}</label>
			<input v-model="user.divisionName" type="text" />
		</div>
		<div class="input_wrap">
			<label for="email">{{ t("message.msg_519") }}</label>
			<input v-model="user.createdDate" type="text" :disabled="true" :value="makeDate(user.createdDate)" />
		</div>
		<div class="input_wrap">
			<label for="email">{{ t("message.msg_504") }}</label>
			<div class="side_check">
				<label class="container">{{ t("message.msg_520") }}
					<input v-model="user.authority" type="checkbox" value="MANAGER" />
					<span class="checkmark"></span>
				</label>
				<label class="container">{{ t("message.msg_521") }}
					<input v-model="user.authority" type="checkbox" value="APPROVER" />
					<span class="checkmark"></span>
				</label>
				<!--
			<label class="container">{{ t("message.msg_522") }}
				<input v-model="user.authority" type="checkbox" value="UPLOADER" />
				<span class="checkmark"></span>
			</label>
		-->
				<label class="container">{{ t("message.msg_523") }}
					<input v-model="user.authority" type="checkbox" value="SENDER" />
					<span class="checkmark"></span>
				</label>
				<label class="container">{{ t("message.msg_524") }}
					<input v-model="user.authority" type="checkbox" value="USER" :disabled="true"/>
					<span class="checkmark"></span>
				</label>
			</div>
		</div>
		<div>
			<button id="modify" class="save" type="button" @click="modify" style="display: inline-block;">{{ modifyButton }}</button>
			<button id="delete" class="save" type="button" @click="deleteUser" style="margin-left: 100px; background-color: grey;">{{ t("message.msg_535") }}</button>
		</div>
	</div>
</template>

<script>
export default {
	name: "UserInfoDialog",
}
</script>

<script setup>
import userAPI from "@/api/user_api"
import {useI18n} from "vue-i18n"
import {ref, watch, defineProps, defineEmits} from "vue"
import {format} from "date-fns"
import constant from "@/common/constants"
import partnerAPI from "@/api/partner_api"

const props = defineProps({
	selectId: {type: String, required: true}
})

const emit = defineEmits(["closeModal"])

const closeModal = () => {
    emit("closeModal");
}

const {t} = useI18n()

const user = ref({
	name: "",
	email: "",
	divisionName: "",
	createdDate: "",
	authority: [],
})
const oldAuthority = ref([])
const modifyButton = ref(t("message.msg_514"))

const modify = async () => {
	let payload = {
		name: user.value.name,
		email: user.value.email,
		divisionName: user.value.divisionName,
		createdDate: user.value.createdDate,
		authority: user.value.authority.join(","),
	}
	var result =true;
	if(oldAuthority.value.includes(constant.ROLE_TYPE.SENDER) && !user.value.authority.includes(constant.ROLE_TYPE.SENDER))
	{
		await partnerAPI.getPartnerListById(props.selectId)
		.then((res) => {
			result = confirm(t("message.msg_536")+"\n"+res.data)				
		})
		.catch(async (error) => {
			alert(error.response.data.message);
		})			
	}
	if(result){
		userAPI.saveUserInfoByAdim(payload, props.selectId)
		.then(() => {
			user.value.authority.sort()
			oldAuthority.value.sort()
			if(!equals(user.value.authority, oldAuthority.value)) {
				alert(t("message.msg_534"))
				oldAuthority.value = user.value.authority //24.06.11 #26436 juank91 정보를 수정 하고 같은 유저정보를 열면 props.selectId에 watch가 걸리지 않아 oldAuthority값을 저장할때 바꿔준다					
			}
			alert(t("message.msg_533"))	
			closeModal()	
		})
		.catch(async (error) => {
			alert(error.response.data.message)
		})
	}
}

const deleteUser = () => {
	var result = confirm(t("message.msg_729"))
	if(result){
		userAPI.deleteUserByAdmin(props.selectId)
		.then(() => {
			closeModal()
		}).catch(async (error) => {
			alert(error.response.data.message)
		})
	}
}

const equals = (a, b) => a.length === b.length && a.every((v, i) => v === b[i]); 

watch(
	() => props.selectId,
	(selectId) => {
		if (selectId) {
			userAPI
				.getUserInfobyId(props.selectId)
				.then((res) => {
					user.value.name = res.data.name
					user.value.email = res.data.email
					user.value.divisionName = res.data.divisionName
					user.value.createdDate = res.data.createdDate
					user.value.authority = res.data.authority.split(",")
					oldAuthority.value = res.data.authority.split(",")
				})
				.catch(async (error) => {
					alert(error.response.data.message)
				})
		}
	}
)

const makeDate = (date) => {
	if(date != "" && date != null)
		return format(date, "yyyy-MM-dd HH:mm:ss")
	else
		return ""
}
</script>

<style scoped src ="../../assets/css/pc/account.css">
</style>

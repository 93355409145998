<template>
	<div class="secret_popup">
		<div class="popup_wrap">
			<div class="popup">
				<div class="popup_inner">
					<slot></slot>
				</div>
				<button class="close_btn" @click="closeModal"></button>
			</div>
		</div>
	</div>
</template>

<script setup>
import { defineEmits } from 'vue';
const emit = defineEmits(["closeModal"])
// const title = ref(props.title);

const closeModal = () => {
  emit("closeModal");
};

</script>

<style scoped src="@/assets/css/pc/reportList.css">
</style>

<template>
	<div id="wrap">
		<main id="main">
			<section class="main_container">
				<article class="main_left" :style="{width: infoFlag ? '74%' : '100%'}">
					<div class="title">{{ t("message.msg_607") }}</div>
					<div class="left_title">
						<div class="list_btns">
							<button class="list_up" @click="toggleFilter"></button>
							<button @click="uploadOptionrAdd">
								<img :src="require('../../assets/img/pc/upload.png')" alt="" />
								{{ t("message.msg_607") }} {{ t("message.msg_556") }}
							</button>
							<button @click="deleteUploadOption">
								<img :src="require('../../assets/img/pc/remove.png')" alt="" style="width: 15px; height: 15px;" />
								{{ t("message.msg_572") }}
							</button>
						</div>
					</div>

					<div class="left_list_table">
						<table>
							<thead>
								<tr>
									<td style="width: 1%;"><input v-model="allSelected" type="checkbox" /></td>
									<td style="width: 10%;" class="center"><span style="cursor: pointer" @click.stop.prevent="updateSort('reportCd')">{{ t("message.msg_608") }}</span><img :src="getSortIcon('reportCd')" alt="" /></td>
									<td style="width: 20%;" class="center"><span style="cursor: pointer" @click.stop.prevent="updateSort('comment')">{{ t("message.msg_609") }}</span><img :src="getSortIcon('comment')" alt="" /></td>
									<td style="width: 10%;" class="center"><span style="cursor: pointer" @click.stop.prevent="updateSort('maxFile')">{{ t("message.msg_612") }}</span><img :src="getSortIcon('maxFile')" alt="" /></td>
									<td class="center"><span style="cursor: pointer" @click.stop.prevent="updateSort('defaultReportName')">{{ t("message.msg_613") }}</span><img :src="getSortIcon('defaultReportName')" alt="" /></td>
									<td class="center"><span style="cursor: pointer" @click.stop.prevent="updateSort('defaultMemo')">{{ t("message.msg_614") }}</span><img :src="getSortIcon('defaultMemo')" alt="" /></td>
									<td class="center"><span style="cursor: pointer" @click.stop.prevent="updateSort('defaultRetentionPeriod')">{{ t("message.msg_615") }}</span><img :src="getSortIcon('defaultRetentionPeriod')" alt="" /></td>
									<td class="center"><span style="cursor: pointer" @click.stop.prevent="updateSort('defaultReplyPeriod')">{{ t("message.msg_616") }}</span><img :src="getSortIcon('defaultReplyPeriod')" alt="" /></td>
									<td class="center"><span style="cursor: pointer" @click.stop.prevent="updateSort('replyFlag')">{{ t("message.msg_617") }}</span><img :src="getSortIcon('replyFlag')" alt="" /></td>
									<!-- <td class="center"><span style="cursor: pointer" @click.stop.prevent="updateSort('replyCommentFlag')">{{ t("message.msg_618") }}</span><img :src="getSortIcon('replyCommentFlag')" alt="" /></td> -->
									<td class="center"><span style="cursor: pointer" @click.stop.prevent="updateSort('releaseFlag')">{{ t("message.msg_619") }}</span><img :src="getSortIcon('releaseFlag')" alt="" /></td>
									<td class="center"><span style="cursor: pointer" @click.stop.prevent="updateSort('approvalFlag')">{{ t("message.msg_620") }}</span><img :src="getSortIcon('approvalFlag')" alt="" /></td>
									<td class="center"><span></span>{{ t("message.msg_514") }}</td>
								</tr>
							</thead>
							<tbody>
								<tr v-for="(option, index) in uploadOptionList" :key="index">
									<th><input v-model="selectList" type="checkbox" :value="option" /></th>
									<th class="center"><span v-html="option.reportCd"></span></th>
									<th class="center"><span v-html="option.comment"></span></th>
									<th class="center"><span v-html="option.maxFile"></span></th>
									<th class="center"><span v-html="option.defaultReportName"></span></th>
									<th class="center"><span v-html="option.defaultMemo"></span></th>
									<th class="center"><span v-html="option.defaultRetentionPeriod"></span></th>
									<th class="center"><span v-html="option.defaultReplyPeriod"></span></th>
									<th class="center"><span></span><div v-show="option.replyFlag" class="check"></div><div v-show="!option.replyFlag" class="check_not"></div></th>
									<!-- <th class="center"><span></span><div v-show="option.replyCommentFlag" class="check"></div><div v-show="!option.replyCommentFlag" class="check_not"></div></th> -->
									<th class="center"><span></span><div v-show="option.releaseFlag" class="check"></div><div v-show="!option.releaseFlag" class="check_not"></div></th>
									<th class="center"><span></span><div v-show="option.approvalFlag" class="check"></div><div v-show="!option.approvalFlag" class="check_not"></div></th>
									<th class="center"><div class="modify" style="cursor: pointer" @click="showDialog(option.id)"></div></th>
								</tr>
							</tbody>
						</table>
					</div>
					<CommonPagination v-if="totalPages != undefined" ref="pagination" :total-pages="totalPages" @move-page="movePage" />
				</article>
				<!-- //main_left -->
				<div v-show="UploadOptionInfoDialog" class="modal-wrap">
					<common-modal @close-modal="hideDialog">
						<upload-option-info-modal class="UploadOptionInfoDialog" :select-id="selectId" @close-modal="hideDialog" />
					</common-modal>
				</div>
				<div v-if="uploadOptionAddFlag" class="modal-wrap">
					<common-modal @close-modal="hideUploadOptionrAdd">
						<upload-option-add-modal class="UploadOptionAddDialog" @close-modal="hideUploadOptionrAdd" />
					</common-modal>
				</div>
			</section>
		</main>
	</div>
</template>

<script setup>
import uploadAPI from "@/api/upload_api"
import {ref, onMounted, computed, defineEmits, watch} from "vue"
import {useUploadStore} from "@/store/uploadStore"
import {useI18n} from "vue-i18n"
import UploadOptionInfoModal from "./UploadOptionInfoModal.vue"
import UploadOptionAddModal from "./UploadOptionAddModal.vue"
import CommonPagination from "@/components/common/CommonPagination"
import _ from "lodash"
import icons from "@/common/icons"

const {t} = useI18n()
const uploadStore = useUploadStore()
const selectList = ref([])
const UploadOptionInfoDialog = ref(false)
const selectId = ref("")
const uploadOptionAddFlag = ref(false)
const emit = defineEmits(["toggle-filter"])
const infoFlag = ref(false)
const sortItem = ref("modifiedDate")
const sortDirection = ref("desc")
const sortCount = ref(0)
const totalPages = ref(1)
const uploadOptionList = ref([])
const pagination = ref(null)

onMounted(async() =>{
    await uploadStore.fetchUploadOptionList()
	uploadOptionList.value = _.cloneDeep(uploadStore.getUploadOptionList)
	totalPages.value = uploadStore.getUploadOptionPageInfo.totalPages
})

const allSelected = computed({
    get: () => {
        let length = 0
        if (uploadOptionList.value != undefined)
            length = uploadOptionList.value.length
        return length === selectList.value.length
    },
    set: val => {
        selectList.value = val ? uploadOptionList.value : []
    },
})

watch(() =>	uploadStore.getUploadOptionList, (newValue) => {
	uploadOptionList.value = _.cloneDeep(newValue)
	totalPages.value = uploadStore.getUploadOptionPageInfo.totalPages
	pagination.value.setPage(uploadStore.getUploadOptionPageInfo.currentPage)
})


watch(() => uploadStore.getUploadOptionFilter, (newValue) => {
	if (newValue.sort != (sortItem.value + "," + sortDirection.value)) {
		sortDirection.value = ""
		sortItem.value = ""
		sortCount.value = 0
	} //filter reset 시 default value로 변경
})

const clear = () => {
	selectList.value = []
	selectId.value = ""
}

const uploadOptionrAdd = (event) => {
    event.preventDefault()
    uploadOptionAddFlag.value = true
}

const hideUploadOptionrAdd = (event) => {
    uploadOptionAddFlag.value = false
    selectList.value = []
    uploadStore.fetchUploadOptionList()
}

const deleteUploadOption = async() => {
    let payload = 
    {
		id: [],
    }
    for(var i = 0; i< selectList.value.length; i++)
    {
        payload.id[i] = selectList.value[i].id
    }
    
	try {
		let response = await uploadAPI.deleteUploadOption(payload)
		if (response.status === 200) {   
			alert(t("message.msg_572") + t("message.msg_573"))
			selectList.value = []
			uploadStore.fetchUploadOptionList()
		}
		else{
			alert(response.status + t("message.msg_203"))
		}
	} catch (error) {
		alert(error.response.data.message)
	}
}

const movePage = async (payload) => {
	await uploadStore.setUploadOptionFilter({page: payload.page - 1})
	await uploadStore.fetchUploadOptionList()
}

const showDialog = (optionId) => {
	UploadOptionInfoDialog.value = true
    selectId.value = optionId
}

const hideDialog = () => {
    UploadOptionInfoDialog.value = false
    clear()
    uploadStore.fetchUploadOptionList()
}

const toggleFilter = () => {
	emit("toggle-filter")
}

const getSortIcon = (item) => {
  if (sortItem.value === item) {
    return sortCount.value == 1 ? icons.tableUp : icons.tableDown;
  }
  return "";
}

const updateSort = async (item) => {
	if (sortItem.value != item) {
		sortCount.value = 0
	}

	sortItem.value = item
	sortCount.value++

	if (sortCount.value == 1) {
		sortDirection.value = "asec"
	} else if (sortCount.value == 2) {
		sortDirection.value = "desc"
	}else if (sortCount.value == 3) {
		sortCount.value = 0
		sortItem.value = null
		sortDirection.value = null
	}
	
	if (sortItem.value != null) {
		await uploadStore.setUploadOptionFilter({sort: sortItem.value + "," + sortDirection.value})
		await uploadStore.fetchUploadOptionList()
	}
}
</script>

<style scoped src="@/assets/css/pc/upload.css">
</style>


var CryptoJS = require("crypto-js")
var MobileDetect = require("mobile-detect")

export default {
	checkEmail: (email) => {
		let regex = /([\w-.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([\w-]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(\]?)$/
		return regex.test(email)
	},
	encryptUrl: (url) => {
		return encodeURIComponent(CryptoJS.AES.encrypt(url, process.env.VUE_APP_PRIV_KEY).toString())
	},
	encrypt: (text) => {
		try {
			var CryptoJSAesJson = {
				stringify: (cipherParams) => {
					var j = {ct: cipherParams.ciphertext.toString(CryptoJS.enc.Base64)}
					if (cipherParams.iv) j.iv = cipherParams.iv.toString()
					if (cipherParams.salt) j.s = cipherParams.salt.toString()
					return JSON.stringify(j)
				},
			}
		} catch (error) {
			alert(error)
		}

		return CryptoJS.AES.encrypt(text, process.env.VUE_APP_PRIV_KEY, {format: CryptoJSAesJson}).toString()
	},
	decryptUrl: (url) => {
		return CryptoJS.AES.decrypt(decodeURIComponent(url), process.env.VUE_APP_PRIV_KEY).toString(CryptoJS.enc.Utf8)
	},
	getExtension: (filename) => {
		return filename.split(".").pop().toLocaleLowerCase()
	},
	makeUrl: (url, payload) => {
		var newUrl = url + "?"
		var options = Object.keys(payload)

		options.map((option) => {
			if (payload[option] != null)
				newUrl = newUrl + option + "=" + payload[option] + "&"
		})

		return newUrl.slice(0, -1)
	},
	getDevice: () => {
		var device = new MobileDetect(window.navigator.userAgent)
		return device
	},
	projectVersion: () => {
		return process.env.VUE_APP_REPORT_DELIVERY_FRONT_VERSION
	},
}

<template>
	<div style="width: 100%; height: 100%; align-items: center; display: flex; flex-direction: column;">
		<div class="input_wrap">
			<label><span class="required">*</span>{{ t("message.msg_503") }}</label>
			<!-- <div class="company"> -->
			<select v-model="userEmail" name="email">
				<option disabled value="none">{{ t("message.msg_502") }} ({{ t("message.msg_503") }})</option>
				<option v-for="(user, index) in userArr" :key="index" :value="user.email"> {{ user.name + "(" + user.email + ")" }} </option>
			</select>
			<!-- </div> -->
			<!-- <label><span class="required">*</span>{{ t("message.msg_503") }}</label>
			<input v-model="userEmail" type="email" name="email" /> -->
		</div>
		<!-- <div class="input_wrap">
			<label :disabled="checked"><span class="required">*</span>{{ t("message.msg_510") }} ({{ t("message.msg_511") }})</label>
			<div class="company">
				<select v-model="company" name="company" :disabled="checked">
					<option disabled value="none">{{ t("message.msg_510") }} ({{ t("message.msg_511") }})</option>
					<option v-for="(comapnyInfo, index) in companyInfoArr.content" :key="index" :value="comapnyInfo"> {{ comapnyInfo.name + " (" + comapnyInfo.companyNo + ")" }} </option>
				</select>
				<label for="joinCheck">
					<input id="joinCheck" v-model="checked" type="checkbox" />{{ t("message.msg_531") }}
				</label>
			</div>
		</div> -->
		<div class="input_wrap">
			<label :disabled="!checked"><span class="required">*</span>{{ t("message.msg_550") }} {{ t("message.msg_510") }}</label>
			<input v-model="partnerCompanyName" :disabled="!checked" type="text" />
		</div>
		<div class="input_wrap">
			<label for="email"><span class="required">*</span>{{ t("message.msg_567") }}</label>
			<input v-model="partnerCode" type="text" maxlength='16' />
		</div>
		<!-- <div class="input_wrap">
			<label :disabled="!checked"><span class="required">*</span>{{ t("message.msg_550") }} {{ t("message.msg_511") }}</label>
			<input v-model="partnerNumber" :disabled="!checked" type="text" />
		</div> -->
		<div class="input_wrap">
			<label>{{ t("message.msg_550") }} {{ t("message.msg_505") }}</label>
			<input v-model="partnerDivision" type="text" />
		</div>
		<div class="input_wrap">
			<label><span class="required">*</span>{{ t("message.msg_550") }} {{ t("message.msg_516") }}</label>
			<input v-model="partnerUserName" type="text" />
		</div>
		<div class="input_wrap">
			<label><span class="required">*</span>{{ t("message.msg_550") }} {{ t("message.msg_516") }} {{ t("message.msg_503") }}</label>
			<input v-model="partnerUserEmail" type="text" name="email" />
		</div>
		<!-- <div class="input_wrap">
			<label><span class="required">*</span>{{ t("message.msg_551") }}</label>
			<div class="side_check">
				<label class="container">{{ t("message.msg_552") }}
					<input v-model="mailType" type="radio" value="NONE" name="radio3" /> 
					<span class="checkmark"></span>
				</label>
				<label class="container">{{ t("message.msg_553") }}
					<input v-model="mailType" type="radio" value="MAIL" name="radio3" />
					<span class="checkmark"></span>
				</label>
				<label class="container">{{ t("message.msg_554") }}

					<input v-model="mailType" type="radio" value="ATTACH" name="radio3" /> 
					<span class="checkmark"></span>
				</label>
			</div>
		</div> -->
		<div class="input_wrap bcc_wrap">
			<label>{{ t("message.msg_555") }}</label>
			<div class="bcc">
				<div class="bcc_wrap">
					<div v-for="(item, index) in BCC" :key="index" style="display: flex;" class="bcc_plus">
						<div class="bcc_list" style="width: 80%;">
							<input v-model="BCC[index]" type="text" style=" width: 90%; border: none;" />
							<button class="x_mark" @click="deleteEvent"></button>
						</div>
					</div>	
				</div>
				<button class="plus" @click="insertEvent"></button>
			</div>
		</div>
		<button class="save" @click="save">{{ t("message.msg_556") }}</button>
	</div>
</template>
<script setup>
import userAPI from "@/api/user_api"
import partnerAPI from "@/api/partner_api"
import companyAPI from "@/api/company_api"
import {ref, onMounted, defineEmits} from "vue"
import {useI18n} from "vue-i18n"

const emit = defineEmits(["closeModal"])

const closeModal = () => {
    emit("closeModal");
}

const {t} = useI18n()
const companyInfoArr = ref([
{
    name: "",
    companyNo: "",
}])

const company = ref(
{
    name: "",
    companyNo: "",
})
//거래처 정보
const userEmail = ref("")
const partnerCompanyName = ref("")
const partnerNumber = ref("") //juank24.05.27 #26342 회사 법인번호를 사용하지 않기로 해서 해당 내용을 html에서 주석처리하고 스크립트 빈값으로 남겨 놓음
const partnerDivision = ref("")
const partnerUserName = ref("")
const partnerUserEmail = ref("")
const BCC = ref([])
const mailType = ref("MAIL")
const checked = ref(true)
const userArr = ref([]) //juank24.05.27 #26342 거래처 추가/수정시 sender권한에 사용자 목록
const partnerCode = ref("")

onMounted(async() =>{
    // userAPI.getUserInfo()
    // .then((res) => {
    //     userEmail.value = res.data.email
    // })
    // .catch(async (error) => {
    //     alert(error.response.data.message);
    // }),
    companyAPI.getCompanyList()
    .then((res) => {
        companyInfoArr.value = res.data;
    })
    .catch(async (error) => {
        alert(error.response.data.message);
    })

	userAPI
	.getCompanyUserList()
	.then((res) => {
		for(var i = 0; i < res.data.content.length; i++){
			if(res.data.content[i].authority.includes("SENDER")){
				userArr.value.push(res.data.content[i])
			}
		}
	})
	.catch(async (error) => {
		alert(error.response.data.message)
	})
})

const deleteEvent = (index) => {
	BCC.value.splice(index, 1);
}

const insertEvent = () => {
	BCC.value.push("");
}

const save = (event) => {
    let payload = {
        userEmail: userEmail.value,
        partnerCompanyName: partnerCompanyName.value,
        partnerCompanyNo: partnerNumber.value,
        partnerDivisionName: partnerDivision.value,
        partnerUserName: partnerUserName.value,
        partnerUserEmail: partnerUserEmail.value,
        emailBcc: "",
        mailType: mailType.value,
        partnerCode: partnerCode.value
	}
	for(var i = 0; i < BCC.value.length; i++) {
        payload.emailBcc += BCC.value[i]
        if(i + 1 < BCC.value.length)
            payload.emailBcc += ","
    }
    //24.02.19 #26046 juank 빈 항목 체크
    if(!checked.value) {
        if (company.value.name != "")
        {
            payload.partnerCompanyName = company.value.name
            payload.partnerNumber = company.value.companyNo
            if(company.value.companyNo == "")
                payload.partnerNumber = null;
        }
        else
        {
            alert(t("message.msg_557"))
            event.preventDefault()
            return
        }
    }
    else if(userEmail.value == "" || partnerCompanyName.value == "" || partnerUserName.value == "" || partnerUserEmail.value == "" || partnerCode.value == "")
    {
        alert(t("message.msg_557"))
        event.preventDefault()
        return
    }

    partnerAPI.addPartner(payload).then(() => {
        alert(t("message.msg_558"))
        closeModal()
    })
    .catch(async (e) => {
        alert(e.response.data.message)
    })
}
</script>

<style scoped src="@/assets/css/pc/account.css">
</style>

<template>
	<div>
		<div class="listFormHeader">
			<table>
				<thead>
					<tr>
						<th style="width: 34%">{{ t("message.msg_550") }}</th>
						<th style="width: 66%">{{ t("message.msg_559") }}</th>
					</tr>
				</thead>
			</table>
		</div>
		<div class="listFormBody">
			<table>
				<tbody>
					<tr v-if="searchedPartnerList == ''">
						<td>{{ t("message.msg_660") }}</td>
					</tr>
					<template v-for="(partner, index) in searchedPartnerList" :key="index">
						<tr @click="checkUser(partner)">
							<td style="width: 34%">{{ partner.partnerCompanyName }}</td>
							<td style="width: 66%">{{ partner.partnerUserName }}</td>
						</tr>
					</template>
				</tbody>
			</table>
		</div>
		<div class="btnClose"><a @click.prevent.stop="close"></a></div>
	</div>
</template>

<script setup>
import { defineProps, defineEmits } from 'vue';
import {useI18n} from "vue-i18n"

const emit = defineEmits(["close", "partnerInfo"])
const {t} = useI18n()

defineProps({
  searchedPartnerList: { type: Array, required: true },
});

let partnerCompanyName = '';
let partnerUserName = '';
let partnerCode;
const checkUser = (partner) => {
    partnerCompanyName = partner.partnerCompanyName;
    partnerUserName = partner.partnerUserName;
    partnerCode = partner.partnerCode;
    emit('partnerInfo', { partnerCode, partnerCompanyName, partnerUserName });
};

const close = () => {
    emit("close");
};
</script>

<style scoped src="@/assets/css/pc/share.css">
</style>

import {defineStore} from "pinia"
import {reactive, computed} from "vue"
import UploadAPI from "@/api/upload_api"
import {useI18n} from "vue-i18n"
import jwt from "@/common/jwt"

export const useUploadStore = defineStore("uploads", () => {
    const state = reactive({
		uploadList: [],
		uploadFilter: {
			size: 15,
			page: 0,
            sort: ""
		},
        uploadPageInfo: {},
        uploadOptionList: [],
		uploadOptionFilter: {
			size: 15,
			page: 0,
            sort: ""
		},
        uploadOptionPageInfo: {},
	})
    const {t} = useI18n()

    const setUploadFilter = (filters) => {
		const newFilters = { ...state.uploadFilter }
		for (const key in filters) {
			if (Object.prototype.hasOwnProperty.call(filters, key)) {
				newFilters[key] = filters[key]
			}
		}

		state.uploadFilter = newFilters
	}

    const fetchUploadList = async() => {
        try {
			if(jwt.getAccessToken() == null) {
				return null
			} //unmounted에서 호출 시 logout 시에도 호출되는 경우가 있어 체크
			let response = await UploadAPI.getUploadList(state.uploadFilter)

			if (response.status === 200) {
				state.uploadPageInfo = {totalPages: response.data.totalPages, totalElements: response.data.totalElements, currentPage: response.data.pageable.pageNumber + 1}
				state.uploadList = response.data.content
			} else {
				console.log(t("message.msg_202"))
			}
		} catch (error) {
			if (error.response.data.message != null && error.response.data.message != undefined) {
				alert(t("message.msg_203") + "\n" + error.response.data.message)
				console.log(t("message.msg_203") + "\n" + error.response.data.message)
			} else {
				alert(error)
			}
		}
    }

    const fetchUploadOptionList = async() => {
        try {
			if(jwt.getAccessToken() == null) {
				return null
			} //unmounted에서 호출 시 logout 시에도 호출되는 경우가 있어 체크
			let response = await UploadAPI.getUploadOptionList(state.uploadOptionFilter)

			if (response.status === 200) {
				state.uploadOptionPageInfo = {totalPages: response.data.totalPages, totalElements: response.data.totalElements, currentPage: response.data.pageable.pageNumber + 1}
				state.uploadOptionList = response.data.content
			} else {
				console.log(t("message.msg_202"))
			}
		} catch (error) {
			if (error.response.data.message != null && error.response.data.message != undefined) {
				alert(t("message.msg_203") + "\n" + error.response.data.message)
				console.log(t("message.msg_203") + "\n" + error.response.data.message)
			} else {
				alert(error)
			}
		}
    }

    const setUploadOptionFilter = (filters) => {
		const newFilters = { ...state.uploadOptionFilter }
		for (const key in filters) {
			if (Object.prototype.hasOwnProperty.call(filters, key)) {
				newFilters[key] = filters[key]
			}
		}

		state.uploadOptionFilter = newFilters
	}

    const getUploadList = computed(() => state.uploadList)
	const getUploadFilter = computed(() => state.uploadFilter)
	const getUploadPageInfo = computed(() => state.uploadPageInfo)
    const getUploadOptionList = computed(() => state.uploadOptionList)
	const getUploadOptionFilter = computed(() => state.uploadOptionFilter)
	const getUploadOptionPageInfo = computed(() => state.uploadOptionPageInfo)

    return {
        fetchUploadList,
        setUploadFilter,
        fetchUploadOptionList,
        setUploadOptionFilter,
        getUploadList,
        getUploadFilter,
        getUploadPageInfo,
        getUploadOptionList,
        getUploadOptionFilter,
        getUploadOptionPageInfo
    }
})
<template>
	<div class="content">
		<div class="listFormHeader">
			<table>
				<thead>
					<tr>
						<th style="width: 34%">{{ t("message.msg_502") }}</th>
						<th style="width: 66%">{{ t("message.msg_503") }}</th>
					</tr>
				</thead>
			</table>
		</div>
		<div class="listFormBody">
			<table>
				<tbody>
					<template v-for="(u, index) in searchedUserList" :key="index">
						<tr @click="checkUser(u)">
							<td style="width: 34%">{{ u.name }}</td>
							<td style="width: 66%">{{ u.email }}</td>
						</tr>
					</template>
				</tbody>
			</table>
		</div>
		<div class="btnClose"><a @click.prevent.stop="close"></a></div>
	</div>
</template>

<script setup>
import { defineProps, defineEmits } from 'vue';
import {useI18n} from "vue-i18n"

const emit = defineEmits(["close", "userInfo"])
const {t} = useI18n()

defineProps({
  searchedUserList: { type: Array, required: true },
});

let userName = '';
let userEmail = '';

const checkUser = (user) => {
  userName = user.name;
  userEmail = user.email;
  emit('userInfo', { userName, userEmail });
};

const close = () => {
  emit("close");
};
</script>

<style scoped src="@/assets/css/pc/share.css">
.content {
	width: 100%;
	height: 100%;
	position: relative;
	background: #fff;
	border-radius: 50px;
}
.listFormHeader {
	height: 30px;
	box-sizing: border-box;
	border: 1px solid #d3d3d3;
	background: #fcfcfc;
	border-radius: 50px 50px 0 0;
}
.listFormHeader table {
	width: 100%;
}
.listFormHeader th {
	padding: 5px 0;
	font-weight: 700;
	letter-spacing: -0.42px;
	line-height: 20px;
	text-align: center;
}
.listFormBody {
	width: 100%;
	max-height: 165px;
	overflow: auto;
	box-sizing: border-box;
	border: 1px solid #d3d3d3;
	border-top: none;
	background: #ffffff;
	border-radius: 0 0 20px 20px;
}
.listFormBody table {
	width: 100%;
}
.listFormBody tr:hover td {
	background: #f8fcff;
}
.listFormBody td {
	padding: 7px 0;
	font-weight: 500;
	cursor: pointer;
	text-align: center;
	letter-spacing: -0.42px;
	line-height: 20px;
}
.btnClose {
	position: absolute;
	right: 8px;
	top: 3px;
	width: 24px;
	height: 24px;
}
.btnClose a {
	display: block;
	width: 100%;
	height: 100%;
	cursor: pointer;
	/* background: url(../../../../assets/img/popup/btnPopupType02Close.png) no-repeat left top; */
}

/* Styles for the scrollbar */
.listFormBody::-webkit-scrollbar {
    width: 5px; /* Width of the scrollbar */
}

/* Track */
.listFormBody::-webkit-scrollbar-track {
    background: #f1f1f1; /* Color of the scrollbar track */
}

/* Handle */
.listFormBody::-webkit-scrollbar-thumb {
    background: #888; /* Color of the scrollbar handle */
	border-radius: 50px;
	width: 3px;
	height: 10px;
}

/* Handle on hover */
.listFormBody::-webkit-scrollbar-thumb:hover {
    background: #555; /* Color of the scrollbar handle on hover */
}
</style>

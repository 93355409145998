export default {
  "message": {
    "msg_000": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IDとパスワードを入力してください。"])},
    "msg_001": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IDはメールアドレスで入力してください。"])},
    "msg_002": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["パスワードは8文字以上で設定してください。"])},
    "msg_003": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["会社名を入力してください。"])},
    "msg_004": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["取引先を選択してください。"])},
    "msg_005": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["メール、パスワード、名前を確認してください。"])},
    "msg_006": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["現在のパスワードを入力してください。"])},
    "msg_007": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["新しいパスワードを入力してください。"])},
    "msg_008": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["新しいパスワードを再入力してください。"])},
    "msg_009": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["パスワードが一致しません。"])},
    "msg_010": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["受信トレイ"])},
    "msg_011": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["共有トレイ"])},
    "msg_012": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["帳票"])},
    "msg_013": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["検索"])},
    "msg_014": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["照会状態"])},
    "msg_015": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["返信状態"])},
    "msg_016": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["受信日付"])},
    "msg_017": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["保存期限"])},
    "msg_018": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["帳票名"])},
    "msg_019": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ユニークコード"])},
    "msg_020": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["帳票ID"])},
    "msg_021": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["帳票メモ"])},
    "msg_022": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["照会"])},
    "msg_023": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["未照会"])},
    "msg_024": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["帳票状態"])},
    "msg_025": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["返信状態"])},
    "msg_026": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["詳細情報"])},
    "msg_027": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["帳票履歴"])},
    "msg_028": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PDF帳票のダウンロード"])},
    "msg_029": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSVのダウンロード"])},
    "msg_030": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["送信会社名"])},
    "msg_031": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["取引先名"])},
    "msg_032": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["帳票コード"])},
    "msg_033": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["公開日付"])},
    "msg_034": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["照会日付"])},
    "msg_035": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["返信日付"])},
    "msg_036": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["返信満了日"])},
    "msg_037": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["帳票保存満了日"])},
    "msg_038": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["公開中止日付"])},
    "msg_039": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["生成日付"])},
    "msg_040": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["生成者"])},
    "msg_041": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["修正日付"])},
    "msg_042": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["修正者"])},
    "msg_043": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["すべての選択"])},
    "msg_044": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["アッロードID"])},
    "msg_045": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["送信会社ID"])},
    "msg_046": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["取引先ID"])},
    "msg_047": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["メールアドレス形式"])},
    "msg_048": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["メモ"])},
    "msg_049": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["公開中止日付"])},
    "msg_050": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["返信照会日付"])},
    "msg_051": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["返信メモ"])},
    "msg_052": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["承認状態"])},
    "msg_053": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["承認日付"])},
    "msg_054": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["承認者"])},
    "msg_055": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["承認メモ"])},
    "msg_056": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["返信確認"])},
    "msg_057": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ETCダウンロード"])},
    "msg_058": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["一括ダウンロード"])},
    "msg_059": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["前へ"])},
    "msg_060": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["次へ"])},
    "msg_061": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["更新"])},
    "msg_062": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["閉じる"])},
    "msg_063": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["返信する"])},
    "msg_064": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ダウンロード"])},
    "msg_065": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["オプション"])},
    "msg_066": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "msg_067": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["初期化"])},
    "msg_068": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["確認"])},
    "msg_069": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["返信を断ります。"])},
    "msg_070": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "msg_071": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["帳票情報"])},
    "msg_072": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["承認"])},
    "msg_073": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["差戻し"])},
    "msg_074": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["承認トレイ"])},
    "msg_075": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["日"])},
    "msg_076": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["月"])},
    "msg_077": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["週"])},
    "msg_078": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["アップローダーライセンスの発行"])},
    "msg_079": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["* アップローダーライセンスファイルは ReportDeliveryUploader サービスにて認証のために使われるファイルです。<br /><br />"])},
    "msg_080": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["* ReportDeliveryUploaderのインストール後、インストールされたフォルダの下にあるLicenseフォルダにuploader.licenseファイルを保存してください。<br /><br />"])},
    "msg_081": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["* アップローダーライセンスファイルは会社ごとに1個のみです。再発行されると既存のライセンスは使えません。<br /><br />"])},
    "msg_082": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["年"])},
    "msg_083": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["生成"])},
    "msg_084": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["受信確認"])},
    "msg_085": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["帳票金額（¥）"])},
    "msg_086": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["YYYY-MM-DD"])},
    "msg_201": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["発信トレイ"])},
    "msg_202": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["データ照会に失敗しました。"])},
    "msg_203": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["処理中にエラーが発生しました。"])},
    "msg_204": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["公開状態"])},
    "msg_205": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["すべて"])},
    "msg_206": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["待機中"])},
    "msg_207": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["公開状態"])},
    "msg_208": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["公開中止"])},
    "msg_209": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["削除失敗"])},
    "msg_210": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ファイルダウンロード失敗"])},
    "msg_211": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["公開失敗"])},
    "msg_212": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["公開中止失敗"])},
    "msg_213": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["照会要請メールの送信失敗"])},
    "msg_214": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["返信要請メールの送信失敗"])},
    "msg_215": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["帳票履歴照会の失敗"])},
    "msg_216": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["帳票情報照会の失敗"])},
    "msg_217": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["照会状態"])},
    "msg_218": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["未照会"])},
    "msg_219": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["照会完了"])},
    "msg_220": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["未返信"])},
    "msg_221": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["返信完了"])},
    "msg_222": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["送信会社の確認完了"])},
    "msg_223": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["返信キャンセル"])},
    "msg_224": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["返信状態"])},
    "msg_225": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["公開日付"])},
    "msg_226": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["返信日付"])},
    "msg_227": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["照会日付"])},
    "msg_228": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["満了日付"])},
    "msg_229": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["帳票公開"])},
    "msg_230": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["実行"])},
    "msg_231": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["更新"])},
    "msg_232": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["総件数"])},
    "msg_233": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PDFダウンロード"])},
    "msg_234": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["公開中止"])},
    "msg_235": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["照会要請メールを送信"])},
    "msg_236": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["返信要請メールを送信"])},
    "msg_237": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["保存期間の再設定"])},
    "msg_238": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["すべてのファイル"])},
    "msg_239": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSVダウンロード"])},
    "msg_240": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["帳票削除"])},
    "msg_241": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["基本情報"])},
    "msg_242": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["状態情報"])},
    "msg_243": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["受信先の情報"])},
    "msg_244": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["送信会社の情報"])},
    "msg_245": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["返信情報"])},
    "msg_246": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["承認情報"])},
    "msg_247": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["初期化"])},
    "msg_248": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["を完了しました。"])},
    "msg_249": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["選択した帳票がありません。帳票を選択した後に再要請してください。"])},
    "msg_250": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PDFを開く"])},
    "msg_300": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["発信トレイ"])},
    "msg_301": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["帳票返信"])},
    "msg_302": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["返信失敗"])},
    "msg_303": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["共有した文書のトレイ"])},
    "msg_304": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["共有された文書のトレイ"])},
    "msg_305": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["共有トレイ名"])},
    "msg_306": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["共有トレイを削除後にメール送信"])},
    "msg_307": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["共有トレイの削除"])},
    "msg_308": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["選択した帳票を削除しますか。"])},
    "msg_309": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["共有トレイ名を確認してください。"])},
    "msg_310": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["該当の共有トレイを削除しますか。"])},
    "msg_311": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["追加"])},
    "msg_312": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ユーザの追加・削除する際にメール送信"])},
    "msg_313": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ユーザリストを保存してください。"])},
    "msg_314": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["入力後に追加してください。"])},
    "msg_315": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["同じメールアドレスが存在します。"])},
    "msg_316": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["共有トレイの生成者はユーザリストに追加することができません。"])},
    "msg_317": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["帳票追加"])},
    "msg_318": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["返信確認"])},
    "msg_319": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["返信メモ"])},
    "msg_320": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["共有トレイの追加"])},
    "msg_321": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["編集"])},
    "msg_322": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ユーザ編集"])},
    "msg_323": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["帳票削除"])},
    "msg_324": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ユーザリスト"])},
    "msg_325": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["共有トレイが見つかりません。"])},
    "msg_326": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["共有トレイの生成者"])},
    "msg_327": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["帳票が見つかりません。"])},
    "msg_328": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["共有したトレイのリスト"])},
    "msg_329": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["共有されたトレイのリスト"])},
    "msg_330": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["共有ユーザの編集"])},
    "msg_331": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["共有ユーザのリスト"])},
    "msg_332": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["入力しなかった参照者があります。"])},
    "msg_333": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["同じ参照者があります。"])},
    "msg_334": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["共有ユーザ"])},
    "msg_335": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["返信メモを入力してください。"])},
    "msg_336": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["メールアドレスの形式を確認してください。"])},
    "msg_500": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["パスワード"])},
    "msg_501": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["アカウント情報"])},
    "msg_502": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["名前"])},
    "msg_503": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["メールアドレス"])},
    "msg_504": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["権限"])},
    "msg_505": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["部署"])},
    "msg_506": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["保存"])},
    "msg_507": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["パスワードの変更"])},
    "msg_508": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["現在のパスワード"])},
    "msg_509": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["新しいパスワード"])},
    "msg_510": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["会社名"])},
    "msg_511": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["会社法人登等番号"])},
    "msg_512": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["管理者のメールアドレス"])},
    "msg_513": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["サービスの情報"])},
    "msg_514": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["修正"])},
    "msg_515": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["確認"])},
    "msg_516": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ユーザ"])},
    "msg_517": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["登録の可否"])},
    "msg_518": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["満了日"])},
    "msg_519": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["生成日"])},
    "msg_520": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MANAGER"])},
    "msg_521": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["APPROVER"])},
    "msg_522": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["UPLOADER"])},
    "msg_523": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SENDER"])},
    "msg_524": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["USER"])},
    "msg_525": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ページが見つかりません。"])},
    "msg_526": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["入力したURLを確認してください。"])},
    "msg_527": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["アカウント情報"])},
    "msg_528": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["会社情報"])},
    "msg_529": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["会社の構成員"])},
    "msg_530": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["選択"])},
    "msg_531": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["直接入力"])},
    "msg_532": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["会員加入"])},
    "msg_533": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["保存しました。"])},
    "msg_534": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["権限が変更されたユーザーは再ログイン後に変更された権限が適用されます。"])},
    "msg_535": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["会員退会"])},
    "msg_536": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SENDER権限を削除すると担当取引先の送信者から削除されます。"])},
    "msg_550": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["取引先"])},
    "msg_551": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["発送方式"])},
    "msg_552": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NONE"])},
    "msg_553": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MAIL"])},
    "msg_554": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ATTACH"])},
    "msg_555": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BCC"])},
    "msg_556": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["追加"])},
    "msg_557": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["入力されていない項目があります。(確認してください。) "])},
    "msg_558": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["取引先を追加しました。"])},
    "msg_559": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["担当者"])},
    "msg_560": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["電子化申請の可否"])},
    "msg_561": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["申請"])},
    "msg_562": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["申請なし"])},
    "msg_563": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["選択なし"])},
    "msg_564": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["電子化の申請日付"])},
    "msg_565": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["登録"])},
    "msg_566": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["登録日付"])},
    "msg_567": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["取引先コード"])},
    "msg_568": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["電子化日付"])},
    "msg_569": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["登録日付"])},
    "msg_570": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["履歴"])},
    "msg_571": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["一括追加"])},
    "msg_572": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["削除"])},
    "msg_573": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["しました。"])},
    "msg_574": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["取引先を選択してください。"])},
    "msg_575": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["電子化の申請を完了しました。"])},
    "msg_576": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["シークレットコードの入力"])},
    "msg_577": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["シークレットコード"])},
    "msg_588": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1.サンプルCSVファイルをダウンロード、内容を入力してください。"])},
    "msg_589": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSVファイルダウンロード"])},
    "msg_590": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2.CSVファイルを入力してください。"])},
    "msg_591": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["は必須入力です。"])},
    "msg_592": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["行の内容に誤りがあります。"])},
    "msg_593": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["一括削除"])},
    "msg_594": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["シークレットコードを再送信しました。"])},
    "msg_595": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["再発行"])},
    "msg_600": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["アップロード"])},
    "msg_601": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["アップロード状態"])},
    "msg_602": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["アップロード追加"])},
    "msg_603": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["アップロード設定のID"])},
    "msg_604": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["アップロードオプション"])},
    "msg_605": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["帳票ファイル"])},
    "msg_606": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["添付ファイル"])},
    "msg_607": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["アップロード設定"])},
    "msg_608": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["帳票種別コード"])},
    "msg_609": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["説明"])},
    "msg_610": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["アップロード方式"])},
    "msg_611": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ファイル名のフィルタ"])},
    "msg_612": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ファイル数の上限"])},
    "msg_613": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["帳票名(既定)"])},
    "msg_614": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["備考(既定)"])},
    "msg_615": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["保存期間(既定)"])},
    "msg_616": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["返信期間(既定)"])},
    "msg_617": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["返信"])},
    "msg_618": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["返信メモの作成可否"])},
    "msg_619": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["公開"])},
    "msg_620": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["承認"])},
    "msg_621": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["返信する"])},
    "msg_622": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["返信しない"])},
    "msg_623": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PDF"])},
    "msg_624": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ALL"])},
    "msg_625": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["有"])},
    "msg_626": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["無"])},
    "msg_627": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["必須入力項目を確認してください。"])},
    "msg_628": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["取引先の管理"])},
    "msg_629": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ハスワードを入力してください。"])},
    "msg_630": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["名前を入力してください。"])},
    "msg_631": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["会社名を入力してください。"])},
    "msg_632": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ログイン"])},
    "msg_633": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["パスワードの再設定"])},
    "msg_634": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["メール送信"])},
    "msg_635": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["メールアドレスを入力してください。"])},
    "msg_636": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["パスワードを入力してください。"])},
    "msg_637": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["メールアドレスを入力すると該当メールへ"])},
    "msg_638": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["会員加入のリンクを送信します。"])},
    "msg_639": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["会員加入のメールを送信しました。"])},
    "msg_640": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["会員加入を完了しました。"])},
    "msg_641": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["アップロードリスト"])},
    "msg_642": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ログアウト"])},
    "msg_643": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["送信会社"])},
    "msg_644": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["伝送方法"])},
    "msg_645": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["履歴"])},
    "msg_646": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["総"])},
    "msg_647": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["件"])},
    "msg_648": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ユーザ情報"])},
    "msg_649": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["その他の情報"])},
    "msg_650": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ファイル追加"])},
    "msg_651": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ファイル"])},
    "msg_652": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["個"])},
    "msg_653": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["添付ファイルの個数が超えました。"])},
    "msg_654": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["アップロードする帳票のファイルフォーマットはPDFのみです。"])},
    "msg_655": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["変更したパスワードを送信します。"])},
    "msg_656": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["メールを送信しました。"])},
    "msg_657": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["成功"])},
    "msg_658": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["失敗"])},
    "msg_659": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ReportDeliveryUploader"])},
    "msg_660": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["登録された取引先情報が見つかりません。"])},
    "msg_661": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["3ヶ月"])},
    "msg_662": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["6ヶ月"])},
    "msg_663": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1年"])},
    "msg_664": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["直接入力"])},
    "msg_700": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["代表メールアドレス"])},
    "msg_701": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["料金プラン"])},
    "msg_702": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["サービス開始日"])},
    "msg_703": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["サービス満了日"])},
    "msg_704": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["サービス登録"])},
    "msg_705": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["使用量"])},
    "msg_706": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ライセンス管理"])},
    "msg_707": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["有効期限"])},
    "msg_708": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["アップローダーライセンスの管理"])},
    "msg_709": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["アップローダーライセンスのリスト"])},
    "msg_710": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MACアドレスを入力してください。"])},
    "msg_711": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MACアドレス型式ではありません。"])},
    "msg_712": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MACアドレス"])},
    "msg_713": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["使用量確認"])},
    "msg_714": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["会社リスト"])},
    "msg_715": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["先月の使用量"])},
    "msg_716": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["今月の使用量"])},
    "msg_717": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1年間の使用量"])},
    "msg_718": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["件"])},
    "msg_719": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["帳票数"])},
    "msg_720": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["500通"])},
    "msg_721": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["500通パック"])},
    "msg_722": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["標準"])},
    "msg_723": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["標準パック"])},
    "msg_724": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["現在のプラン"])},
    "msg_725": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["未加入"])},
    "msg_726": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["年"])},
    "msg_727": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ヶ月"])},
    "msg_728": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["会社削除"])},
    "msg_729": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["退会しますか？"])},
    "msg_730": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["退会しました。"])},
    "msg_731": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["削除しますか。"])},
    "msg_732": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["削除しました。"])}
  }
}
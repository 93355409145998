<template>
	<div id="State" class="tabCont tabcontent">
		<div class="detail detail02">
			<strong>{{ t("message.msg_645") }}</strong>
			<span>{{ t("message.msg_646") }} {{ historyList.length }} {{ t("message.msg_647") }}</span>
			<table>
				<thead>
					<tr>
						<th>{{ t("message.msg_643") }} {{ t("message.msg_559") }}</th>
						<th>{{ t("message.msg_550") }} {{ t("message.msg_559") }}</th>
						<th>{{ t("message.msg_644") }}</th>
						<th>{{ t("message.msg_040") }}</th>
						<th>{{ t("message.msg_039") }}</th>
					</tr>
				</thead>
				<tbody>
					<PartnerHistoryItem v-for="history in historyList" :key="history.userEmail" :partner-history="history" />
				</tbody>
			</table>
		</div>
	</div>
</template>

<script setup>
import PartnerHistoryItem from "./PartnerHistoryItem.vue"
import {useI18n} from "vue-i18n"
import {computed} from "vue"
import {usePartnerStore} from "@/store/partnerStore"

const partnerStore = usePartnerStore()
const {t} = useI18n()

const historyList = computed(() => partnerStore.getPartnerHistory)

</script>

<style scoped src="@/assets/css/pc/account.css">
</style>
<template>
	<common-modal @close-modal="closeModal">
		<p>{{ $t("message.msg_713") }}</p>
		<br />
		<div v-if="company != undefined" class="explanation">
			<label>{{ $t("message.msg_510") }}</label>
			<span>{{ company.name }}</span>
		</div>
		<div v-if="company != undefined" class="explanation">
			<label>{{ t("message.msg_724") }}</label>
			<span v-if="company.servicePlan == null">{{ t("message.msg_725") }}</span>
			<span v-else>{{ currentPlan.text }}, {{ company.serviceStart.replace("T", " ") }} ~ {{ company.serviceEnd.replace("T", " ") }}</span>
		</div>
		<div class="summary">
			<div class="summary-content">
				<p>{{ t("message.msg_715") }}</p>
				<span>{{ lastMonthCount }}{{ t("message.msg_718") }}</span>
			</div>
			<div class="summary-content">
				<p>{{ t("message.msg_716") }}</p>
				<span>{{ thisMonthCount }}{{ t("message.msg_718") }}</span>
			</div>
		</div>
		<div class="graph">
			<p>{{ t("message.msg_717") }}</p>
			<Bar :data="barData" :options="option" />
		</div>
	</common-modal>
</template>
<script setup>
import {useI18n} from "vue-i18n"
import {ref, defineEmits, defineProps, onMounted, computed} from "vue"
import reportApi from "@/api/report_api"
import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale
} from 'chart.js'
import { Bar } from 'vue-chartjs'

const {t} = useI18n()
const emit = defineEmits(["close"])
const props = defineProps({
	company: {type: Object, required: true}
})

const labels = ref([])
const datasets = ref([])

const option = {
	responsive: true,
	maintainAspectRatio: false,
}

const servicePlan = ref([
	{text: t("message.msg_721"), value: "LIGHT"},
	{text: t("message.msg_723"), value: "STANDARD"},
])

const currentPlan = computed(() => {
	return servicePlan.value.find((p) => p.value == props.company.servicePlan)
})


/*
const progressOption = {
	indexAxis: "y",
	responsive: true,
}
*/

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend)

onMounted(async() => {
	if (props.company != null && props.company != undefined) {
		let res = await reportApi.getReportCount(props.company.id)
		let statistics = res.data
		const labelArr = []
		const dataArr = []
		for (let data of statistics) {
			labelArr.push(data["month"])
			dataArr.push(data["reportCount"])
		}
		labels.value = labelArr
		datasets.value = dataArr
	}
})

const barData = computed(() => {
	return {
		labels: labels.value,
		datasets: [{
			label: t("message.msg_719"),
			backgroundColor: '#1a8758',
			data: datasets.value
		}]
	}
})

const thisMonthCount = computed(() => {
	return datasets.value[datasets.value.length - 1]
})

const lastMonthCount = computed(() => {
	return datasets.value[datasets.value.length - 2]
})

/*
const thisMonthData = computed(() => {
	return {
		labels: [labels.value[labels.value.length - 1]],
		datasets: [{
			label: "usage",
			backgroundColor: '#f87979',
			data: [datasets.value[datasets.value.length - 1]]
		}]
	}
})

const lastMonthData = computed(() => {
	return {
		labels: [labels.value[labels.value.length - 2]],
		datasets: [{
			label: "usage",
			backgroundColor: '#f87979',
			data: [datasets.value[datasets.value.length - 2]]
		}]
	}
})
*/

const closeModal = () => {
  emit("close");
};

</script>
<style scoped src="@/assets/css/pc/admin.css">
</style>
<style>
.explanation {
	display: flex;
	flex-direction: row;
	align-items: center;
	margin-bottom: 1em;
	width: 100%;
}

.explanation span {
	margin: 0 0 1rem 0;
}

.explanation label {
	min-width: 90px;
	margin: 0 0 1rem 0;
}

.explanation select {
	width: 100%;
	padding: 10px;
	margin: 0 0 1rem 0;
}

.explanation input {
	margin: 0 0 1rem 0;
}

.explanation :nth-child(2) {
	margin-left: 1.5rem !important;
}

.summary {
	display: flex;
	flex-direction: row;
	width: 100%;
}

.summary-content {
	text-align: center;
	width: 50%;
	flex: 1;
}

.summary-content p {
	font-size: 1.2rem !important;
}

.summary-content span {
	font-size: 1rem !important;
}

.graph {
	width: 100%;
	height: 300px;
}
</style>
<!-- eslint-disable prettier/prettier -->
<template>
	<div id="Basic" class="tabCont tabcontent">
		<div class="detail">
			<strong>{{ t("message.msg_241") }}</strong>
			<ul>
				<li><strong>{{ t("message.msg_018") }}</strong> <span>{{ report.reportNm }}</span></li>
				<li><strong>{{ t("message.msg_019") }}</strong> <span>{{ report.uniqueCode }}</span></li>
				<li><strong>{{ t("message.msg_021") }}</strong> <span>{{ report.memo }}</span></li>
				<li><strong>{{ t("message.msg_017") }}</strong> <span>{{ getDateText(report.dueDate) }}</span></li>
				<li><strong>{{ t("message.msg_608") }}</strong> <span>{{ report.reportCd }}</span></li>
				<li><strong>{{ t("message.msg_085") }}</strong> <span>{{ report.price }}</span></li>
			</ul>
		</div>
		<div class="detail detail02">
			<strong>{{ t("message.msg_242") }}</strong>
			<ul>
				<li><strong>{{ t("message.msg_204") }}</strong> <span>{{ getOpenStatusText(report.status) }}, {{ getDateText(report.openDate) }}</span></li>
				<li><strong>{{ t("message.msg_224") }}</strong> <span>{{ getReplyStatusText(report.replyStatus) }}, {{ getDateText(report.replyDate) }}</span></li>
				<li><strong>{{ t("message.msg_052") }}</strong> <span>{{ getApprovalStatusText(report.approvalStatus) }}, {{ getDateText(report.approvalDate) }}</span></li>
				<li><strong>{{ t("message.msg_014") }}</strong> <span>{{ getReadStatusText(report.readStatus) }}, {{ getDateText(report.readDate) }}</span></li>
			</ul>
		</div>
		<div class="detail detail02">
			<strong>{{ t("message.msg_243") }}</strong>
			<ul>
				<li><strong>{{ t("message.msg_031") }}</strong> <span>{{ report.partnerNm }}</span></li>
				<li><strong>{{ t("message.msg_559") }}</strong> <span>{{ report.partnerUserEmail }}</span></li>
			</ul>
		</div>
		<div class="detail detail02">
			<strong>{{ t("message.msg_244") }}</strong>
			<ul>
				<li><strong>{{ t("message.msg_030") }}</strong> <span>{{ report.companyNm }}</span></li>
				<li><strong>{{ t("message.msg_559") }}</strong> <span>{{ report.companyUserEmail }}</span></li>
			</ul>
		</div>
		<div class="detail detail02">
			<strong>{{ t("message.msg_245") }}</strong>
			<ul>
				<li><strong>{{ t("message.msg_056") }}</strong> <span>{{ report.replyOk }}</span></li>
				<li><strong>{{ t("message.msg_051") }}</strong> <span>{{ report.replyMemo }}</span></li>
			</ul>
		</div>
		<div class="detail detail02">
			<strong>{{ t("message.msg_246") }}</strong>
			<ul>
				<li><strong>{{ t("message.msg_054") }}</strong> <span>{{ report.approvalBy }}</span></li>
				<li><strong>{{ t("message.msg_053") }}</strong> <span>{{ getDateText(report.approvalDate) }}</span></li>
				<li><strong>{{ t("message.msg_055") }}</strong> <span>{{ report.approvalMemo }}</span></li>
			</ul>
		</div>
	</div>

	<div class="btn_ok_wrap">
		<button class="btn_ok" @click="closeInfo()">{{ t("message.msg_068") }}</button>
	</div>
</template>

<script setup>
import {useI18n} from "vue-i18n"
import {computed, defineEmits} from "vue"
import {useReportStore} from "@/store/reportStore"
import {getOpenStatusText, getApprovalStatusText, getReplyStatusText, getReadStatusText} from "@/composable/report"

const reportStore = useReportStore()
const {t} = useI18n()

const report = computed(() => reportStore.getCurrentReport)

const getDateText = (date) => {
	if(date == null || date == undefined || date == ""){
		return ""
	}
	return date.replace("T", " ")
}

const emit = defineEmits(["close-info"])

const closeInfo = () => {
	emit("close-info")
}

</script>

<style scoped src="@/assets/css/mobile/ticket.css"></style>

<template>
	<div id="infoWrap">
		<div class="info_cont info_input">
			<h2>{{ t("message.msg_648") }}</h2>
			<div class="info_text">
				<input v-model="userInfo.name" type="text" :placeholder="t('message.msg_502')" />
				<input v-model="userInfo.email" type="email" :disabled="true" :placeholder="t('message.msg_503')" />
				<input v-model="userInfo.authority" type="text" :disabled="true" :placeholder="t('message.msg_504')" />
				<input v-model="userInfo.divisionName" type="text" :disabled="true" :placeholder="t('message.msg_505')" />
			</div>
			<div class="info_btn">
				<button @click="saveUserInfo">{{ t("message.msg_506") }}</button>
			</div>
		</div>

		<div class="info_cont info_input">
			<h2>{{ t("message.msg_507") }}</h2>
			<div class="info_text">
				<input v-model="currentPassword" type="password" :placeholder="t('message.msg_508')" />
				<input v-model="newPassword1" type="password" :placeholder="t('message.msg_509')" />
				<input v-model="newPassword2" type="password" :placeholder="t('message.msg_509') + t('message.msg_068')" />
			</div>
			<div class="info_btn">
				<button @click="savePassword">{{ t("message.msg_506") }}</button>
			</div>
			<br/>
			<label style="width: 100%; cursor: pointer;" @click="deleteUser">{{ t("message.msg_535") }}</label>
		</div>
	</div>
</template>
<script>
export default {
	name: "PersonalSetting",
}
</script>

<script setup>
import userAPI from "@/api/user_api"
import {ref, onMounted} from "vue"
import {useI18n} from "vue-i18n"
import jwt from "@/common/jwt"
import {useRouter} from "vue-router"

const password = ref("")
const oldPassword = ref("")
const currentPassword = ref("")
const newPassword1 = ref("")
const newPassword2 = ref("")
const userInfo = ref({
	name: "",
	email: "",
	authority: "",
	divisionName: "",
})
const {t} = useI18n()
const router = useRouter()

onMounted(() => {
	userAPI
		.getUserInfo()
		.then((res) => {
			userInfo.value = res.data
			password.value = res.data.password
		})
		.catch(async (error) => {
			alert(error)
		})
})
const deleteUser = async () => {
   
   var result = confirm(t("message.msg_729"))
   if(result){
		try {
			let response = await userAPI.deleteUser()
			if (response.status === 200) {
				alert(t("message.msg_730"))
				jwt.deleteToken()
				router.replace("/login")
			} else {
				alert(t("message.msg_203"))
			}
		} catch (error) {
			alert(error.response.data.message)
		}  
	}
}
const saveUserInfo = async () => {
	let payload = {
		name: userInfo.value.name,
		divisionName: userInfo.value.divisionName,
	}
	userAPI
		.saveUserInfo(payload)
		.then(() => {
			alert(t("message.msg_533"))
		})
		.catch(async (e) => {
			alert(e.response.data.message)
		})
}
const savePassword = () => {
	oldPassword.value = currentPassword.value
	if (currentPassword.value == "") alert(t("message.msg_006"))
	else if (newPassword1.value == "") alert(t("message.msg_007"))
	else if (newPassword2.value == "") alert(t("message.msg_008"))
	else if (newPassword1.value != newPassword2.value) alert(t("message.msg_009"))
	else if (checkPw()) {
		let payload = {
			oldPassword: oldPassword.value,
			newPassword: newPassword1.value,
		}
		userAPI
			.saveNewPassword(payload)
			.then(() => {
				alert(t("message.msg_533"))
			})
			.catch(async (e) => {
				alert(e.response.data.message)
			})
	}
}
const checkPw = () => {
	if (newPassword1.value.length >= 8) {
		return true
	}
	alert(t("message.msg_002")) //24.01.23 #25910 juank 비밀번호는 8자 이상이어야 합니다
	return false
}
</script>

<style scoped src="../../assets/css/pc/infoModify.css">
</style>

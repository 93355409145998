<template>
	<section class="main_container">
		<article class="main_left" :style="{width: infoFlag ? '74%' : '100%'}">
			<div class="title">{{ t("message.msg_010") }}</div>
			<div class="left_title">
				<div class="list_btns">
					<button class="list_up" @click="toggleFilter"></button>
					<button @click="secretCodeDlg=true">
						<img :src="$icons.regSecCode" alt="" />
						{{ t("message.msg_576") }}
					</button>
				</div>
				<div class="list_search">
					<p class="list_total">{{ t("message.msg_232") }}<span>: {{ reportStore.getReceivedReportPageInfo.totalElements }}</span></p>
					<button class="list_reset" @click.stop.prevent="reportStore.fetchReceivedReportList"></button>
				</div>
			</div>

			<div class="left_list_table">
				<table>
					<thead>
						<tr>
							<!-- <td style="width: 1%;"><input v-model="chkSelectAll" type="checkbox" @change="selectAll" /></td> -->
							<td style="width: 20%;" @click.stop.prevent="updateSort('reportNm')">{{ t("message.msg_018") }} <img :src="getSortIcon('reportNm')" alt="" /></td>
							<td @click.stop.prevent="updateSort('readStatus')">{{ t("message.msg_014") }}<img :src="getSortIcon('readStatus')" alt="" /></td>
							<td @click.stop.prevent="updateSort('replyStatus')">{{ t("message.msg_025") }}<img :src="getSortIcon('replyStatus')" alt="" /></td>
							<td @click.stop.prevent="updateSort('companyNm')">{{ t("message.msg_030") }}<img :src="getSortIcon('companyNm')" alt="" /></td>
							<td><span @click.stop.prevent="updateSort('price')">{{ t("message.msg_085") }}</span> <img :src="getSortIcon('price')" alt="" /></td>	
							<td @click.stop.prevent="updateSort('createdDate')">{{ t("message.msg_039") }}<img :src="getSortIcon('createdDate')" alt="" /></td>
							<td></td>
						</tr>
					</thead>
					<tbody v-if="reportList && reportList.length !== 0">
						<template v-for="report in reportList" :key="report.reportId">
							<tr :class="{selected: isSelected(report)}" @click="clickReport(report.reportId)">
								<!-- <th><input v-model="chkSelect[report.reportId]" type="checkbox" @change="selectReport(report.reportId)" /></th> -->
								<th>{{ report.reportNm }}</th>
								<th>{{ getReadStatusText(report.readStatus) }}</th>
								<th><button v-show="report.replyStatus != 'NULL'" :style="{backgroundPosition: report.replyStatus == 'READY' ? 'right' : 'left'}"></button></th>
								<th>{{ report.companyNm }}</th>
								<th>{{ report.price }}</th>
								<th>{{ format(report.createdDate, "yyyy-MM-dd HH:mm:ss") }}</th>
								<th>
									<div class="list_btn">
										<button :title="report.replyStatus == 'READY' ? t('message.msg_063') : null" :class="report.replyStatus == 'READY' ? 'list_reply' : ''" @click="openReplyModal($event, report)"><a href="#"></a></button>
										<button :title="downloadType[0].text" class="list_pdf" @click.stop.prevent="onClickDownload(report, downloadType[0].value)"><a href="#"></a></button>
										<button :title="report.fileCount > 1 ? t('message.msg_058') : null" :class="report.fileCount > 1 ? 'list_download' : 'none_button'" @click.stop.prevent="onClickDownload(report, downloadType[1].value)"><a href="#"></a></button>
										<button :title="downloadType[2].text" class="list_pdf_dl" @click.stop.prevent="onClickDownload(report, downloadType[2].value)"><a href="#"></a></button>
										<!-- <div class="list_modal">
											<button class="list_more" @click.stop.prevent="clickMore($event)"></button>
											<div class="modal">
												<div v-on-click-outside="onClickOutside">
													<ul>
														<li @click.stop.prevent="onClickDownload(report, downloadType[0].value)"><a href="#">{{ downloadType[0].text }}</a></li>
														<li @click.stop.prevent="onClickDownload(report.reportId, downloadType[2].value)"><a href="#">{{ downloadType[2].text }}</a></li>
														<li v-if="report.fileCount > 1" @click.stop.prevent="onClickDownload(report, downloadType[1].value)"><a href="#">{{ downloadType[1].text }}</a></li>
														<li @click.stop.prevent="onClickDownload(report.reportId, downloadType[3].value)"><a href="#">{{ downloadType[3].text }}</a></li>
													</ul>
												</div>
											</div>
										</div> -->
									</div>
								</th>
							</tr>
						</template>
					</tbody>
				</table>
			</div>
			<CommonPagination v-if="totalPages != undefined" ref="pagination" :total-pages="totalPages" @move-page="movePage" />
		</article>
		<article v-if="infoFlag" class="main_right">
			<ReportInfoMain @close-info="closeInfo" />
		</article>
		<template v-if="showReportReplyModal">
			<common-modal @close-modal="showReportReplyModal=false">
				<ReceivedReportReplyModal @close-modal="showReportReplyModal=false" />
			</common-modal>
		</template>
		<template v-if="secretCodeDlg">
			<secret-code-modal class="secretCodeDlg" @close-modal="secretCodeDlg=false" />
		</template>
	</section>
</template>

<script setup>
import { vOnClickOutside } from '@vueuse/components'
import {ref, onMounted, watch, defineEmits} from "vue"
import ReportInfoMain from ".././report/reportInfos/ReportInfoMain"
import SecretCodeModal from "./SecretCodeModal.vue"
import CommonPagination from "@/components/common/CommonPagination"
import ReceivedReportReplyModal from "./ReceivedReportReplyModal.vue";
import {downloadReport, getReadStatusText} from "@/composable/report"
import _ from "lodash"
import {format} from "date-fns"
import icons from "@/common/icons"
import { useShareBoxReportFunctions } from '@/composable/shareBox';
import { useReceivedReportListFunctions } from '@/composable/receivedReportList';
import { useI18n } from 'vue-i18n';

const {
    selectReport,
    selectAll,
	setReceivedReportInfo,
	fetchReportHistory,
	chkSelectAll,
	chkSelect,
	reportList,
	reportStore,
} = useShareBoxReportFunctions();

const {
    downloadType,
    totalPages,
    showReportReplyModal,
    showReportInfoModal,
    clickMore,
    onClickOutside,
} = useReceivedReportListFunctions();

const { t } = useI18n();

const infoFlag = ref(false)
const sortItem = ref("modifiedDate")
const sortDirection = ref("desc")
const sortCount = ref(0)
const secretCodeDlg = ref(false)
const pagination = ref(null)

const emit = defineEmits(["toggle-filter", "close"])

const openReplyModal = async (evt, report) => {
    evt.stopPropagation();
	reportStore.setCurrentReport(report);
	if(report.replyStatus == 'READY'){
		showReportReplyModal.value = true;
	}
};

const toggleFilter = () => {
	emit("toggle-filter")
}

onMounted(async() => {
	reportStore.setReceivedReportFilter({ page: 0 });
	await reportStore.fetchReceivedReportList({})
	reportList.value = _.cloneDeep(reportStore.getReceiptedReportList)
	totalPages.value = reportStore.getReceivedReportPageInfo.totalPages
})

watch(() =>	reportStore.getReceiptedReportList, (newValue) => {
	reportList.value = _.cloneDeep(newValue)
	totalPages.value = reportStore.getReceivedReportPageInfo.totalPages
	pagination.value.setPage(reportStore.getReceivedReportPageInfo.currentPage)
})

watch(() => reportStore.getReceivedReportFilter, (newValue) => {
	if (newValue.sort != (sortItem.value + "," + sortDirection.value)) {
		sortDirection.value = "desc"
		sortItem.value = "modifiedDate"
		sortCount.value = 0
	} //filter reset 시 default value로 변경
})


const onClickDownload = (report, type) => {
	if((type=='all' && report.fileCount > 1) || type!='all' ){
		downloadReport(report, type)
	} else{
		clickReport(report.reportId)
	}
}

const clickReport = (reportId) => {
	setReceivedReportInfo(reportId)
	fetchReportHistory(reportId)
	infoFlag.value = true
}

const updateSort = async (item) => {
	if (sortItem.value != item) {
		sortCount.value = 0
	}
	sortItem.value = item
	sortCount.value++

	if (sortCount.value == 1) {
		sortDirection.value = "asc"
	} else if (sortCount.value == 2) {
		sortDirection.value = "desc"
	}else if (sortCount.value == 3) {
		sortCount.value = 0
		sortItem.value = null
		sortDirection.value = null
	}

	if (sortItem.value != null) {
		await reportStore.setReceivedReportFilter({sort: sortItem.value + "," + sortDirection.value})
		await reportStore.fetchReceivedReportList()
	}
}

const getSortIcon = (item) => {
  if (sortItem.value === item) {
    return sortCount.value == 1 ? icons.tableUp : icons.tableDown;
  }
  return "";
}

const closeInfo = () => {
	infoFlag.value = false
}

const movePage = async (payload) => {
    await reportStore.setReceivedReportFilter({ page: payload.page - 1 });
    await reportStore.fetchReceivedReportList();
	chkSelectAll.value = false;
    selectAll();
};

const isSelected = (report) => {
	return _.isEqual(reportStore.getCurrentReport, report) && infoFlag.value
}

</script>

<style scoped src="@/assets/css/pc/received.css">
</style>
<style scoped>
.selected {
	background-color: var(--vc-gray-100);
}
</style>
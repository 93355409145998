<template>
	<tr>
		<td>{{ format(reportHistory.createdDate, "yyyy-MM-dd HH:mm:ss") }}</td>
		<td>{{ getActionTypeText(reportHistory.action_type) }}</td>
		<td>{{ chkUploader(reportHistory.createdBy) }}</td>
		<td>{{ reportHistory.memo }}</td>
	</tr>
</template>
<script setup>
import {useI18n} from "vue-i18n"
import {defineProps, computed} from "vue"
import {format} from "date-fns"
import {useReportStore} from "@/store/reportStore"

const reportStore = useReportStore()
const {t} = useI18n()

const report = computed(() => reportStore.getCurrentReport)

const actionTypes = [
		{value: "CREATED", text: t("message.msg_083")}, //생성
		{value: "OPENED", text: t("message.msg_207")}, //공개
		{value: "CLOSED", text: t("message.msg_208")}, //공개 중지
		{value: "READ", text: t("message.msg_084")}, //수신 확인
		{value: "REPLIED", text: t("message.msg_221")}, //회신 완료
		{value: "REPLY_READ", text: t("message.msg_056")}, //회신 확인
		{value: "UPDATE_EXPIRED", text: t("message.msg_237")}, //장표 보관기한 변경
		{value: "APPROVED", text: t("message.msg_620")}, //승인
		{value: "DENIED", text: t("message.msg_073")}, //반려
		{value: "RESEND_READ", text: t("message.msg_235")}, //조회 요청 발송
		{value: "RESEND_REPLY", text: t("message.msg_236")}, //회신 요청 발송
		{value: "DOWNLOAD", text: t("message.msg_064")}, //다운로드
	]

defineProps({
	reportHistory: {type: Object, required: true},
})

const chkUploader = (createdBy) => {
	if (createdBy.indexOf(report.value.companyId.replaceAll('-', '')) >= 0){
		return "uploader"
	}
	return createdBy
}

const getActionTypeText = (actionType) => {
	return actionTypes.find((at) => at.value == actionType).text
}


</script>
<style scoped src="@/assets/css/pc/reportList.css">
</style>
import constant from '@/common/constants'
import axios from 'axios'

export default{
	getBackEndVersion: () => {
		let url = constant.GET_BACK_END_VERSION_URL;
        return axios({
			url: url,
			method: 'GET'
		});
	},
}
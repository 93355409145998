import i18n from "@/i18n"
import reportApi from "@/api/report_api"

const { t } = i18n.global

const functionList = [
	"OPEN", "CLOSE", "SEND-READ", "SEND-REPLY", "DELETE", "APPROVE", "DISAPPROVE", "RESET-EXP"
]

//발신
export const useFunc = (payload, opt) => {
	switch(opt){
		case functionList[0]: {
			openReports(payload)
			break
		} //open
		case functionList[1]: {
			closeReports(payload)
			break
		} //close
		case functionList[2]: {
			sendReadReports(payload)
			break
		} //send-read
		case functionList[3]: {
			sendReplyReports(payload)
			break
		} //send-reply
		case functionList[4]: {
			deleteReports(payload)
			break
		} //delete
		case functionList[5]:
		case functionList[6]: {
			approveReports(payload)
			break
		} //approve & disapprove
		case functionList[7]: {
			resetExpirationDate(payload)
			break
		} //reset-exp
	}
}
const fetchReportList = () => {
	const {useReportStore} = require("@/store/reportStore")
	const reportStore = useReportStore()
	reportStore.fetchReportList()
}

const fetchApprovalReportList = () => {
	const {useReportStore} = require("@/store/reportStore")
	const reportStore = useReportStore()
	reportStore.fetchApprovalReportList()
}

const openReports = async (payload) => {
	try {
		const response = await reportApi.openReports(payload)
		if (response.status === 200) {
			alert(t("message.msg_229") + t("message.msg_248"))
			fetchReportList()
		} else {
			alert(t("message.msg_211"))
			console.log(t("message.msg_211"))
		}
	} catch (error) {
		alert(t("message.msg_203") + "\n" + error.response.data.message)
	}
}
const closeReports = async (payload) => {
	try {
		const response = await reportApi.closeReports(payload)
		if (response.status === 200) {
			alert(t("message.msg_234") + t("message.msg_248"))
			fetchReportList()
		} else {
			alert(t("message.msg_212"))
			console.log(t("message.msg_212"))
		}
	} catch (error) {
		alert(t("message.msg_203") + "\n" + error.response.data.message)
		console.log(t("message.msg_203") + "\n" + error.response.data.message)
	}
}
const sendReadReports = async (payload) => {
	try {
		const response = await reportApi.sendReadReports(payload)
		if (response.status === 200) {
			alert(t("message.msg_235") + t("message.msg_248"))
			console.log(t("message.msg_235") + t("message.msg_248"))
		} else {
			alert(t("message.msg_213"))
			console.log(t("message.msg_213"))
		}
	} catch (error) {
		alert(t("message.msg_203") + "\n" + error.response.data.message)
		console.log(t("message.msg_203") + "\n" + error.response.data.message)
	}
}
const sendReplyReports = async (payload) => {
	try {
		const response = await reportApi.sendReplyReports(payload)
		if (response.status === 200) {
			alert(t("message.msg_236") + t("message.msg_248"))
			console.log(t("message.msg_236") + t("message.msg_248"))
		} else {
			alert(t("message.msg_214"))
			console.log(t("message.msg_214"))
		}
	} catch (error) {
		alert(t("message.msg_203") + "\n" + error.response.data.message)
		console.log(t("message.msg_203") + "\n" + error.response.data.message)
	}
}
const deleteReports = async (payload) => {
	if(!confirm(t("message.msg_308")))
		return
	try {
		const response = await reportApi.deleteReports(payload)
		if (response.status === 200) {
			alert(t("message.msg_240") + t("message.msg_248"))
			fetchReportList()
		} else {
			alert(t("message.msg_209"))
			console.log(t("message.msg_209"))
		}
	} catch (error) {
		alert(t("message.msg_203") + "\n" + error.response.data.message)
		console.log(t("message.msg_203") + "\n" + error.response.data.message)
	}
}
const resetExpirationDate = async (payload) => {
	try {
		const response = await reportApi.resetExpirationDate(payload)
		if (response.status === 200) {
			alert(t("message.msg_237") + t("message.msg_248"))
			fetchReportList()
		} else {
			alert(t("message.msg_209"))
			console.log(t("message.msg_209"))
		}
	} catch (error) {
		alert(t("message.msg_203") + "\n" + error.response.data.message)
		console.log(t("message.msg_203") + "\n" + error.response.data.message)
	}
}

//수신
export const replyReports = async (payload) => {
	try {
		const response = await reportApi.replyReports(payload)
		if (response.status === 200) {
			alert(t("message.msg_301") + t("message.msg_248"))
		} else {
			alert(t("message.msg_302"))
			console.log(t("message.msg_302"))
		}
	} catch (error) {
		alert(t("message.msg_203") + "\n" + error.response.data.message)
		console.log(t("message.msg_203") + "\n" + error.response.data.message)
	}
}

//승인
export const approveReports = async (payload) => {
	try {
		const response = await reportApi.approveReports(payload)
		if (response.status === 200) {
			alert(t("message.msg_301") + t("message.msg_248"))
			fetchApprovalReportList()
		} else {
			alert(t("message.msg_302"))
			console.log(t("message.msg_302"))
		}
	} catch (error) {
		alert(t("message.msg_203") + "\n" + error.response.data.message)
		console.log(t("message.msg_203") + "\n" + error.response.data.message)
	}
}

//공통
export const downloadReport = async (report, type) => {
	try {
		let dlType
		if (type == "dl") {
			dlType = "pdf"
		} else {
			dlType = type
		}
		const response = await reportApi.downloadReport(report.reportId, dlType)
		if (response.status === 200) {
			
			//const contentDisposition = response.headers["content-disposition"]
			let fileName = report.reportNm + "_" + report.partnerNm + "_" + report.companyNm
			fileName = report.price == null ? fileName + "_" : fileName + "_" + report.price
			fileName += "_" + report.createdDate
			/*
			if (contentDisposition) {
				const [fileNameMatch] = contentDisposition.split(";").filter((str) => str.includes("filename"))
				if (fileNameMatch) [, fileName] = fileNameMatch.split("=")
			}
			*/
			if(type == 'pdf'){
				const url = URL.createObjectURL(new Blob([response.data], {type: "application/pdf"}))
				window.open(url, "blob")
				window.URL.revokeObjectURL(url)
			} else if (type == "all"){
				const url = URL.createObjectURL(new Blob([response.data], {type: "binary"}))
				//fileName = decodeURIComponent(fileName)
				fileName = fileName + ".zip"
				downloadFile(url, fileName)
				//이름 변경
			} else if (type == "dl") {
				const url = URL.createObjectURL(new Blob([response.data], {type: "application/pdf"}))
				//fileName = decodeURIComponent(fileName)
				//이름 변경
				fileName = fileName + ".pdf"
				downloadFile(url, fileName)
			}
		} else {
			alert(t("message.msg_210"))
		}
	} catch (error) {
		if (error.response.data.message != null && error.response.data.message != undefined) {
			alert(t("message.msg_203") + "\n" + error.response.data.message)
			console.log(t("message.msg_203") + "\n" + error.response.data.message)
		} else {
			alert(t("message.msg_203"))
		}
	}
}

const downloadFile = (url, fileName) => {
	const newA = document.createElement("a")
	newA.href = url
	newA.setAttribute("download", `${fileName}`.replaceAll('"', ""))
	newA.style.cssText = "display:none"
	document.body.appendChild(newA)
	newA.click()
	newA.parentNode.removeChild(newA)
}

export const getOpenStatusText = (status) => {
	if (status == "READY") {
		return t("message.msg_206")
	} else if (status == "OPENED") {
		return t("message.msg_207")
	} else if (status == "CLOSED") {
		return t("message.msg_208")
	}
	return ""
}

export const getApprovalStatusText = (status) => {
	if (status == "READY") {
		return t("message.msg_206")
	} else if (status == "APPROVED") {
		return t("message.msg_072")
	} else if (status == "DENIED") {
		return t("message.msg_073")
	}
	return ""
}

export const getReplyStatusText = (status) => {
	if (status == "READY") {
		return t("message.msg_220")
	} else if (status == "REPLIED") {
		return t("message.msg_221")
	}
	return ""
}

export const getReadStatusText = (status) => {
	if (status == "READY") {
		return t("message.msg_218")
	} else if (status == "READ") {
		return t("message.msg_219")
	}
	return ""
}
<template>
	<div id="companyWrap">
		<div class="company_cont company_input">
			<h2>{{ t("message.msg_528") }}</h2>
			<div class="company_text">
				<input v-model="companyInfo.name" type="text" :title="t('message.msg_510')" :placeholder="t('message.msg_510')"/>
				<!-- <input v-model="companyInfo.companyNo" type="text" :disabled="modifyButton == t('message.msg_514')" /> -->
				<input v-model="companyInfo.adminEmail" type="text" :title="t('message.msg_700')" :placeholder="t('message.msg_700')"/>
				<!-- <input v-model="companyInfo.servicePlan" type="text" :disabled="modifyButton == t('message.msg_514')" /> -->
			</div>
			<div class="company_btn">
				<button @click="modify">{{ t('message.msg_514') }}</button>
			</div>
		</div>
	</div>
</template>

<script setup>
import companyApi from "@/api/company_api"
import {ref, onMounted} from "vue"
import {useI18n} from "vue-i18n"

const companyInfo = ref({
	name: "",
	companyNo: null, //juank24.05.29 #26342 회사 법인번호를 사용하지 않기로 해서 해당 내용을 html에서 주석처리하고 값을 null로 넣음
	adminEmail: "",
	servicePlan: "",
})
const {t} = useI18n()

onMounted(() => {
	companyApi
		.getCompanyInfo()
		.then((res) => {
			companyInfo.value = res.data
		})
		.catch(async (error) => {
			alert(error.response.data.message)
		})
})
const modify = (event) => {
	if(companyInfo.value.name == "" || companyInfo.value.adminEmail == ""){
		alert(t("message.msg_557"))
		return
	}
	else if (!emailCheck(companyInfo.value.adminEmail)){
		alert(t("message.msg_336"))
		return
	}
	let payload = {
		name: companyInfo.value.name,
		companyNo: companyInfo.value.companyNo,
		adminEmail: companyInfo.value.adminEmail,
		servicePlan: companyInfo.value.servicePlan,
	}

	companyApi
		.saveCompanyInfo(payload)
		.then(() => {
			alert(t("message.msg_533"))
		})
		.catch(async (error) => {
			alert(error.response.data.message)
		})
}

function emailCheck(email_address){     
	var email_regex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/i;
	if(!email_regex.test(email_address)){ 
		return false; 
	}else{
		return true;
	}
}

</script>

<style scoped src = "../../assets/css/pc/companyModify.css">
</style>

<template>
	<main id="main">
		<ReceivedReportFilter @toggle-filter="toggleFilter(false)" />
		<ReceivedReportList class="main_container" :mode="mode" @toggle-filter="toggleFilter(true)" />
	</main>
</template>
<script setup>
import { ref, defineProps } from 'vue';
import ReceivedReportFilter from "./ReceivedReportFilter.vue";
import ReceivedReportList from "./ReceivedReportList.vue";

const props = defineProps({
  mode: {
    type: String,
    default: 'defaultMode' //장표 추가로 수신 목록을 열지 않을 경우 default값을 설정함
  }
});

const toggleFilter = (flag) => {
	var sideMenu = document.getElementById('sideMenu');
	if (flag) { //open filter
		sideMenu.style.transition = 'left 0.5s';
		sideMenu.style.left = '0';
	} else { //close filter
		sideMenu.style.left = '-450px';
	}
}
</script>
<style scoped src="@/assets/css/pc/received.css">
</style>

<template>
	<div id="sideMenu">
		<button class="side_close_btn" @click="closeFilter"></button>
		<button class="side_reset_btn" @click="clearFilter"></button>
		<div class="side_list">
			<div class="side_list_cont">
				<div class="side_title open_title">{{ t("message.msg_601") }}</div>
				<div class="side_check">
					<label class="container">{{ t("message.msg_657") }}
						<input v-model="filters.uploadStatus" type="radio" value="UPLOAD_OK" />
						<span class="checkmark"></span>
					</label>
					<label class="container">{{ t("message.msg_658") }}
						<input v-model="filters.uploadStatus" type="radio" value="UPLOAD_FAIL" />
						<span class="checkmark"></span>
					</label>
					<label class="container">{{ t("message.msg_563") }}
						<input v-model="filters.uploadStatus" type="radio" value="" />
						<span class="checkmark"></span>
					</label>
				</div>
			</div>
			<div class="side_list_cont">
				<div class="side_title">{{ t("message.msg_550") }} {{ t("message.msg_510") }}</div>
				<input v-model="filters.partnerCompanyName" type="text" />
			</div>
			<div class="side_list_cont">
				<div class="side_title">{{ t("message.msg_040") }}</div>
				<input v-model="filters.createdBy" type="text" />
			</div>
			<div class="side_list_cont">
				<div class="side_title">{{ t("message.msg_569") }}</div>
				<div class="side_check">
					<label class="container" style="margin-right: 2em;">{{ t("message.msg_661") }}
						<input v-model="searchPeriod" type="radio" value="1" />
						<span class="checkmark"></span>
					</label>
					<label class="container" style="margin-right: 2em;">{{ t("message.msg_662") }}
						<input v-model="searchPeriod" type="radio" value="2" />
						<span class="checkmark"></span>
					</label>
					<label class="container" style="margin-right: 2em;">{{ t("message.msg_663") }}
						<input v-model="searchPeriod" type="radio" value="3" />
						<span class="checkmark"></span>
					</label>
					<label class="container" style="margin-right: 2em;">{{ t("message.msg_664") }}
						<input v-model="searchPeriod" type="radio" value="4" />
						<span class="checkmark"></span>
					</label>
				</div>
				<div class="side_date">
					<VDatePicker v-model="filters.createdDateFrom" :disabled-dates="disabledDatesCreatedDateFrom" :masks="masks" :popover="popover" :color="selectedColor"> 
						<template #default="{ inputValue, inputEvents }">
							<input :value="inputValue" :placeholder="t('message.msg_086')" :disabled="searchPeriod != '4'" v-on="inputEvents" />
						</template>
					</VDatePicker>
					- 
					<VDatePicker v-model="filters.createdDateTo" :disabled-dates="disabledDatesCreatedDateTo" :masks="masks" :popover="popover" :color="selectedColor">
						<template #default="{ inputValue, inputEvents }">
							<input :value="inputValue" :placeholder="t('message.msg_086')" :disabled="searchPeriod != '4'" v-on="inputEvents" />
						</template>
					</VDatePicker>
				</div>
			</div>
			<div class="side_list_cont">
				<div class="side_title">{{ t("message.msg_608") }}</div>
				<input v-model="filters.reportCode" type="text" />
			</div>

			<button class="search" @click="fetchUploadList()">{{ t("message.msg_013") }}</button>
		</div>
	</div>
</template>
<script setup>
import {useUploadStore} from "@/store/uploadStore"
import {useI18n} from "vue-i18n"
import {ref, defineEmits, onUnmounted, watch} from "vue"
import {format} from "date-fns"

const {t} = useI18n()
const masks = ref({ input: "YYYY-MM-DD" });
const selectedColor = ref('green');
const emit = defineEmits(["toggle-filter"])
const popover= ref({
				visibility: "focus",
				placement: "bottom",
				autoHide: "boolean",
			});
const disabledDatesCreatedDateFrom = ref(null)
const disabledDatesCreatedDateTo = ref(null)
const uploadStore = useUploadStore()
const searchPeriod = ref("1")
const filters = ref(
{
    uploadStatus: "",
    partnerCompanyName: "",
    createdBy: "",
    isIncludingUploader: false,
    createdDateTo: new Date().toISOString().slice(0,10),
    createdDateFrom: new Date(new Date().setMonth(new Date().getMonth() - 3)).toISOString().slice(0,10),
    reportCode: "",
    size: "",
    page: 0,
    sort: "",
})
onUnmounted(() => {
	clearFilter()
	fetchUploadList()
})

watch(() => searchPeriod.value, () => {
	var now = new Date();
	filters.value.createdDateTo = now.toISOString().slice(0,10)
	if(searchPeriod.value == 1) {
		filters.value.createdDateFrom = new Date(now.setMonth(now.getMonth() - 3)).toISOString().slice(0,10)
	} else if(searchPeriod.value == 2) {
		filters.value.createdDateFrom = new Date(now.setMonth(now.getMonth() - 6)).toISOString().slice(0,10)
	} else if(searchPeriod.value == 3) {
		filters.value.createdDateFrom = new Date(now.setFullYear(now.getFullYear() - 1)).toISOString().slice(0,10)
	} else if(searchPeriod.value == 4) {
		let dateTo = new Date(filters.value.createdDateTo)
		disabledDatesCreatedDateFrom.value = [{start: dateTo.setDate(dateTo.getDate() + 1)}]
		let dateFrom = new Date(filters.value.createdDateFrom)
		disabledDatesCreatedDateTo.value = [{end: dateFrom.setDate(dateFrom.getDate() - 1)}]
	}

})

watch(() => filters.value.createdDateFrom, (newValue) => {
	if (newValue == null) {
		disabledDatesCreatedDateTo.value = null
	} else {
		let date = new Date(newValue)
		disabledDatesCreatedDateTo.value = [{end: date.setDate(date.getDate() - 1)}]
	}
})

watch(() => filters.value.createdDateTo, (newValue) => {
	if (newValue == null) {
		disabledDatesCreatedDateFrom.value = null
	} else {
		let date = new Date(newValue)
		disabledDatesCreatedDateFrom.value = [{start: date.setDate(date.getDate() + 1)}]
	}
})

const clearFilter = () => {
	var now = new Date();
	searchPeriod.value = 1
	filters.value = {
        uploadStatus: "",
        partnerCompanyName: "",
        createdBy: "",
        isIncludingUploader: false,
        createdDateTo: now.toISOString().slice(0,10),
        createdDateFrom:  new Date(now.setMonth(now.getMonth() - 3)).toISOString().slice(0,10),
        reportCode: "",
        size: "",
        page: 0,
        sort: "",
	}
}

const fetchUploadList = async () => {
	if(filters.value.createdBy != "" && t("message.msg_659").toLowerCase().includes(filters.value.createdBy.toLocaleLowerCase()))
		filters.value.isIncludingUploader = true
	else
		filters.value.isIncludingUploader = false
	let payload = filters.value
	payload.createdDateFrom = makeDateStr(filters.value.createdDateFrom, true)
	payload.createdDateTo = makeDateStr(filters.value.createdDateTo, false)
	payload.page = 0
    await uploadStore.setUploadFilter(payload)
	await uploadStore.fetchUploadList()
}

const makeDateStr = (date, flag) => {
    if(date == null || date == undefined || date == "") {
        return ""
    }
    let dateStr = format(date, "yyyy-MM-dd")
    if (flag) {
        return `${dateStr} 00:00:00`
    } else {
        return `${dateStr} 23:59:59`
    }
        
}

const closeFilter = () => {
	emit("toggle-filter")
}

</script>
<style scoped src="@/assets/css/pc/addUpload.css">
</style>
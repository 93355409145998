let URL_PREFIX_REPORT = "/report"
let URL_PREFIX_USER = "/user"
let URL_PREFIX_SHARED_BOX = "/share-box"

export default {

	USER_LOGIN_URL: process.env.VUE_APP_BASE_URL + "/auth/login",
	USER_RESISTEMAIL_URL: process.env.VUE_APP_BASE_URL + "/auth/send-regist-email",
	USER_JOIN_URL: process.env.VUE_APP_BASE_URL + "/auth/register-user",
	USER_GET_NEW_PASSWORD_URL: process.env.VUE_APP_BASE_URL + "/auth/send-password-email",
	GET_COMPANYLIST_URL: process.env.VUE_APP_BASE_URL + "/auth/company/list",
	TOKEN_REFRESH_URL: process.env.VUE_APP_BASE_URL + "/auth/refresh",
	DELETE_USER_URL: process.env.VUE_APP_BASE_URL + "/user/delete",
	DELETE_USER_BY_ADMIN_URL: process.env.VUE_APP_BASE_URL + "/company/user/delete/${id}",

	GET_REPORT_LIST_URL: process.env.VUE_APP_BASE_URL + URL_PREFIX_REPORT + "/list",
	PUT_OPEN_REPORTS_URL: process.env.VUE_APP_BASE_URL + URL_PREFIX_REPORT + "/open",
	PUT_CLOSE_REPORTS_URL: process.env.VUE_APP_BASE_URL + URL_PREFIX_REPORT + "/close",
	PUT_SENDREAD_REPORTS_URL: process.env.VUE_APP_BASE_URL + URL_PREFIX_REPORT + "/resend-read",
	PUT_SENDREPLY_REPORTS_URL: process.env.VUE_APP_BASE_URL + URL_PREFIX_REPORT + "/resend-reply",
	DELETE_REPORTS_URL: process.env.VUE_APP_BASE_URL + URL_PREFIX_REPORT + "/delete",
	GET_DOWN_REPORT_URL: process.env.VUE_APP_BASE_URL + URL_PREFIX_USER + URL_PREFIX_REPORT + "/${reportId}/download?opt=${opt}",
	GET_REPORT_HISTORY_URL: process.env.VUE_APP_BASE_URL + URL_PREFIX_USER + URL_PREFIX_REPORT + "/${reportId}/history",
	GET_REPORT_INFO_URL: process.env.VUE_APP_BASE_URL + URL_PREFIX_USER + URL_PREFIX_REPORT + "/${reportId}",
	PUT_RESET_EXP_DATE_URL: process.env.VUE_APP_BASE_URL + URL_PREFIX_REPORT + "/reset-exp",
	GET_REPORT_COUNT_URL: process.env.VUE_APP_BASE_URL + "/system/report/statistics",

	GET_USER_URL: process.env.VUE_APP_BASE_URL + "/user",
	SAVE_USERINFO_URL: process.env.VUE_APP_BASE_URL + "/user/modify",
	SAVE_USERINFO_BY_ADMIN_URL: process.env.VUE_APP_BASE_URL + "/company/user/modify/${id}",
	SAVE_NEWPASSWORD_URL: process.env.VUE_APP_BASE_URL + "/user/newPassword",

	GET_COMPANY_URL: process.env.VUE_APP_BASE_URL + "/company",
	SAVE_COMPANYINFO_URL: process.env.VUE_APP_BASE_URL + "/company/modify",
	GET_COMPANY_USERLIST_URL: process.env.VUE_APP_BASE_URL + "/user/list",
	GET_USERBYID_URL: process.env.VUE_APP_BASE_URL + "/company/user/${id}",
	DELETE_COMPANY_URL: process.env.VUE_APP_BASE_URL + "/system/company/delete/${id}",

	GET_LICENSE_URL: process.env.VUE_APP_BASE_URL + "/system/register-uploader",
	GET_COMPANY_SERVICE_LIST_URL: process.env.VUE_APP_BASE_URL + "/system/company/list",
	GET_UPLOADER_LIST_URL: process.env.VUE_APP_BASE_URL + "/system/uploader-list",
	DELETE_UPLOADER_URL: process.env.VUE_APP_BASE_URL + "/system/delete-uploader",
	POST_SERVICE_PLAN_URL: process.env.VUE_APP_BASE_URL + "/system/new-service-plan/${companyId}",
	PUT_SERVICE_PLAN_URL: process.env.VUE_APP_BASE_URL + "/system/modify-service-plan/${companyId}",
	
	GET_PARTNERLIST_URL: process.env.VUE_APP_BASE_URL + "/partner/list" ,
	GET_PARTNERLISTBYID_URL: process.env.VUE_APP_BASE_URL + "/partner/list/${id}" ,
	POST_REGISTPARTNER_URL: process.env.VUE_APP_BASE_URL + "/partner/register",
	NEW_PARTNER_URL: process.env.VUE_APP_BASE_URL + "/partner/new",
	DELETE_PARTNER_URL: process.env.VUE_APP_BASE_URL + "/partner/delete",
	GET_PARTNER_INFO: process.env.VUE_APP_BASE_URL + "/partner/info",
	REQUEST_PARTNER_URL: process.env.VUE_APP_BASE_URL + "/common/partner/request",
	MODIFY_PARTNER_URL: process.env.VUE_APP_BASE_URL +"/partner/modify",
	GET_PARTNER_HISTORY_LIST_URL : process.env.VUE_APP_BASE_URL + "/partner/history/list",
	POST_PARTNER_LIST_URL: process.env.VUE_APP_BASE_URL + "/partner/add-bulk",
	DELETE_PARTNER_LIST_URL: process.env.VUE_APP_BASE_URL + "/partner/delete-bulk",
	GET_SECRET_CODE_URL: process.env.VUE_APP_BASE_URL + "/partner/reissue/${partnerId}",

	NEW_UPLOADOPTION_URL: process.env.VUE_APP_BASE_URL + "/upload-option/new",
	GRT_UPLOADOPTION_LIST : process.env.VUE_APP_BASE_URL + "/upload-option/list",
	GET_UPLOADOPTION_INFO: process.env.VUE_APP_BASE_URL + "/upload-option/info",
	MODIFY_UPLOADOPTION_URL: process.env.VUE_APP_BASE_URL +"/upload-option/modify",
	DELETE_UPLOADOPTION_URL: process.env.VUE_APP_BASE_URL + "/upload-option/delete",
	GRT_UPLOAD_LIST : process.env.VUE_APP_BASE_URL + "/upload/list",
	NEW_UPLOAD_LIST : process.env.VUE_APP_BASE_URL + "/upload/new",

	GET_RECEIVED_REPORT_LIST_URL: process.env.VUE_APP_BASE_URL + URL_PREFIX_USER + URL_PREFIX_REPORT + "/list",
	GET_CHECK_RECEIVED_REPORT_LIST_URL: process.env.VUE_APP_BASE_URL + URL_PREFIX_USER + URL_PREFIX_REPORT + "/list/${sharedBoxId}",
	PUT_RECEIVED_REPORT_REPLY_URL: process.env.VUE_APP_BASE_URL + URL_PREFIX_USER + URL_PREFIX_REPORT + "/${reportId}/reply",

	GET_APPROVAL_REPORT_LIST_URL: process.env.VUE_APP_BASE_URL + "/approval/list",
	PUT_APPROVE_REPORTS_URL: process.env.VUE_APP_BASE_URL + "/approval/approve",

	GET_SHARE_BOX_LIST_URL: process.env.VUE_APP_BASE_URL + URL_PREFIX_SHARED_BOX + "/list",
	GET_SHARE_BOX_CC_LIST_URL: process.env.VUE_APP_BASE_URL + URL_PREFIX_SHARED_BOX+ "/cc/list",
	MODIFY_SHARE_BOX_URL: process.env.VUE_APP_BASE_URL + URL_PREFIX_SHARED_BOX +"/modify",
	DELETE_SHARE_BOX_URL: process.env.VUE_APP_BASE_URL + URL_PREFIX_SHARED_BOX +"/delete/${sharedBoxId}/${mailFlag}",
	GET_SHARE_BOX_USER_LIST_URL: process.env.VUE_APP_BASE_URL + URL_PREFIX_SHARED_BOX + URL_PREFIX_USER+ "/${sharedBoxId}",
	GET_USER_LIST_URL: process.env.VUE_APP_BASE_URL + URL_PREFIX_USER + "/list?size=${size}",
	POST_SHARE_REPORTS_URL: process.env.VUE_APP_BASE_URL + URL_PREFIX_USER + URL_PREFIX_REPORT + "/shared",
	DELETE_SHARE_REPORTS_URL: process.env.VUE_APP_BASE_URL +URL_PREFIX_SHARED_BOX + "/delete/document",
	GET_SHARE_BOX_REPORT_LIST_URL: process.env.VUE_APP_BASE_URL +URL_PREFIX_SHARED_BOX + "/list/${sharedBoxId}",

	GET_BACK_END_VERSION_URL: process.env.VUE_APP_BASE_URL + "/info/version",

	ROLE_TYPE: {
		SYSTEM: "SYSTEM",
		MANAGER: "MANAGER",
		APPROVER: "APPROVER",
		UPLOADER: "UPLOADER",
		SENDER: "SENDER",
		USER: "USER",
	},
}

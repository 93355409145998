<template>
	<div id="sideMenu">
		<button class="side_close_btn" @click="closeFilter"></button>
		<button class="side_reset_btn" @click="clearFilter"></button>
		<div class="side_list">
			<div class="side_list_cont">
				<div class="side_title open_title">{{ t("message.msg_204") }}</div>
				<div class="side_check">
					<template v-for="statusOption in statusOptions" :key="statusOption.value">
						<label class="container"> {{ statusOption.text }}
							<input v-model="filters.approvalStatus" type="radio" name="radio" :value="statusOption.value" />
							<span class="checkmark"></span>
						</label>
					</template>
				</div>
			</div>
			<div class="side_list_cont">
				<div class="side_title">{{ t("message.msg_053") }}</div>
				<div class="side_date">
					<VDatePicker v-model="filters.approvalDateFrom" :disabled-dates="disabledDatesFrom" :masks="masks" :popover="popover" :color="selectedColor">
						<template #default="{ inputValue, inputEvents }">
							<input :value="inputValue" :placeholder="t('message.msg_086')" v-on="inputEvents" />
						</template>
					</VDatePicker>
					~
					<VDatePicker v-model="filters.approvalDateTo" :disabled-dates="disabledDatesTo" :masks="masks" :popover="popover" :color="selectedColor">
						<template #default="{inputValue, inputEvents}">
							<input :value="inputValue" :placeholder="t('message.msg_086')" v-on="inputEvents" />
						</template>
					</VDatePicker>
				</div>
			</div>
			<div class="side_list_cont">
				<div class="side_title">{{ t("message.msg_018") }}</div>
				<input v-model="filters.reportNm" type="text" />
			</div>
			<div class="side_list_cont">
				<div class="side_title">{{ t("message.msg_021") }}</div>
				<input v-model="filters.memo" type="text" />
			</div>
			<div class="side_list_cont">
				<div class="side_title">{{ t("message.msg_031") }}</div>
				<input v-model="filters.partnerNm" type="text" />
			</div>
			<div class="side_list_cont">
				<div class="side_title">{{ t("message.msg_085") }}</div>
				<div class="side_date">
					<input v-model="filters.minPrice" min="0" type="number" @change="onChangeMinPrice" />
					~
					<input v-model="filters.maxPrice" min="0" type="number" @change="onChangeMaxPrice" />					
				</div>
			</div>
			<button class="search" @click="fetchReportList">
				<img :src="$icons.search" alt="" />{{ t("message.msg_013") }}
			</button>
		</div>
	</div>
</template>

<script setup>

import {useReportStore} from "@/store/reportStore.js"
import {ref, defineEmits, onUnmounted, watch} from "vue"
import _ from "lodash"
import {format} from "date-fns"

import {ko} from "date-fns/locale"

const emit = defineEmits(["toggle-filter"])

const reportStore = useReportStore()
const masks = ref({ input: "YYYY-MM-DD" });
const selectedColor = ref('green');
const popover= ref({
				visibility: "focus",
				placement: "bottom",
				autoHide: "boolean",
			});

const filters = ref({
		approvalStatus: "",
		approvalDateFrom: null,
		approvalDateTo: null,
		reportNm: "",
		reportNo: "",
		memo: "",
		partnerNm: "",
		minPrice: null,
		maxPrice: null,
})

const disabledDatesFrom = ref(null)
const disabledDatesTo = ref(null)

const locale = ref(ko)

import {useI18n} from "vue-i18n"
const {t} = useI18n()

const statusOptions = [
	{value: "", text: t("message.msg_205")},
	{value: "READY", text: t("message.msg_206")},
	{value: "APPROVED", text: t("message.msg_072")},
	{value: "DENIED", text: t("message.msg_073")},
]

onUnmounted(() => {
	clearFilter()
	fetchReportList()
})

watch(() => filters.value.approvalDateFrom, (newValue) => {
	if (newValue == null) {
		disabledDatesTo.value = null
	} else {
		let date = new Date(newValue)
		disabledDatesTo.value = [{end: date.setDate(date.getDate() - 1)}]
	}
})

watch(() => filters.value.approvalDateTo, (newValue) => {
	if (newValue == null) {
		disabledDatesFrom.value = null
	} else {
		let date = new Date(newValue)
		disabledDatesFrom.value = [{start: date.setDate(date.getDate() + 1)}]
	}
})


const fetchReportList = async () => {
	let payload = _.cloneDeep(filters.value)
	payload.approvalDateFrom = makeDateStr(filters.value.approvalDateFrom, true)
	payload.approvalDateTo = makeDateStr(filters.value.approvalDateTo, false)
	payload.page = 0
	payload.sort = "createdDate,desc"
	await reportStore.setApprovalReportFilter(payload)
	await reportStore.fetchApprovalReportList()
}

const makeDateStr = (date, flag) => {
	if(date == null || date == undefined || date == "") {
		return ""
	}
	let dateStr = format(date, "yyyy-MM-dd")
	if (flag) {
		return `${dateStr}T00:00:00`
	} else {
		return `${dateStr}T23:59:59`
	}
	
}

const clearFilter = () => {
	filters.value = {
		approvalStatus: "",
		approvalDateFrom: null,
		approvalDateTo: null,
		reportNm: "",
		reportNo: "",
		memo: "",
		partnerNm: "",
		minPrice: null,
		maxPrice: null,
	}
}

const closeFilter = () => {
	emit("toggle-filter")
}

const onChangeMinPrice = () => {
	if (filters.value.minPrice == "") {
		filters.value.minPrice = null
		return
	}
	if(filters.value.maxPrice != null) {
		if (filters.value.minPrice > filters.value.maxPrice) {
			filters.value.minPrice = filters.value.maxPrice
		}
	}
}

const onChangeMaxPrice = () => {
	if (filters.value.maxPrice == "") {
		filters.value.maxPrice = null
		return
	}
	if(filters.value.minPrice != null) {
		if (filters.value.maxPrice < filters.value.minPrice) {
			filters.value.maxPrice = filters.value.minPrice
		}
	}
}
</script>
<style scoped src="@/assets/css/pc/reportList.css">
</style>

<template>
	<div class="left_list_table_modal">
		<table>
			<thead>
				<tr>
					<td style="width: 1%;"><input v-model="chkSelectAll" type="checkbox" @change="selectAll" /></td>
					<td style="width: 20%;">{{ t("message.msg_018") }} </td>
					<td>{{ t("message.msg_014") }}</td>
					<td>{{ t("message.msg_025") }}</td>
					<td>{{ t("message.msg_030") }}</td>
					<td>{{ t("message.msg_039") }}</td>
					<td></td>
				</tr>
			</thead>
			<tbody v-if="reportList && reportList.length">
				<template v-for="receivedRepo in reportList" :key="receivedRepo.reportId">
					<tr>
						<th><input v-model="chkSelect[receivedRepo.reportId]" type="checkbox" :disabled="receivedRepo.disabled" @change="selectReport(receivedRepo.reportId)" /></th>
						<th>{{ receivedRepo.reportNm }}</th>
						<th>{{ getReadStatusText(receivedRepo.readStatus) }}</th>
						<th><button v-show="receivedRepo.replyStatus != 'NULL'" :style="{backgroundPosition: receivedRepo.replyStatus == 'READY' ? 'right' : 'left'}"></button></th>
						<th>{{ receivedRepo.companyNm }}</th>
						<th>{{ format(receivedRepo.createdDate, "yyyy-MM-dd HH:mm:ss") }}</th>
					</tr>
				</template>
			</tbody>
		</table>
	</div>
	<CommonPagination v-if="totalPages != undefined" ref="pagination" :total-pages="totalPages" @move-page="movePage" />

	<div class="button_layout">
		<button class="send_btn" @click="addReports">{{ t("message.msg_317") }}</button>
		<button class="send_btn" @click="close">{{ t("message.msg_062") }}</button>
	</div>
</template>

<script setup>
import CommonPagination from "@/components/common/CommonPagination"
import {computed, ref, onMounted, inject, watch, defineEmits, defineProps} from "vue"
import _ from "lodash"
import { format } from "date-fns";
import { getReadStatusText } from "@/composable/report"
import { useShareBoxReportFunctions } from "@/composable/shareBox";
import { useI18n } from "vue-i18n";
import {useShareBoxStore} from "@/store/shareBoxStore"

const {
    selectReport,
    selectAll,
	chkSelectAll,
	chkSelect,
	reportList,
	currentShareBoxReportList,
	selectedReportList,
	reportStore,
	mode,
} = useShareBoxReportFunctions();

const { t } = useI18n();
const emit = defineEmits(["close"])
const shareBoxStore = useShareBoxStore()
const totalPages = ref(1)
const pagination = ref(null)

const close = () => {
  emit("close");
};
const {addShareReports} = shareBoxStore

onMounted(async() => {
	reportStore.setReceivedReportFilter({ page: 0 });
	await reportStore.fetchCheckReceivedReportList({})
	reportList.value = _.cloneDeep(reportStore.getReceiptedReportList)
	totalPages.value = reportStore.getReceivedReportPageInfo.totalPages
})

watch(() => reportStore.getReceivedReportPageInfo.totalPages, (newValue) => {
	totalPages.value = newValue
})

watch(() =>	reportStore.getReceiptedReportList, (newValue) => {
	reportList.value = _.cloneDeep(newValue)
})

watch(() => reportStore.getReceivedReportPageInfo.currentPage, (newValue) => {
	pagination.value.setPage(newValue)
})

const addReports = async () => {
	if (selectedReportList.value.length == 0) {
		return alert(t("message.msg_249"))
	} else{
		await addShareReports(selectedReportList.value)
		emit("close")
	}
}


const movePage = async (payload) => {
    await reportStore.setReceivedReportFilter({ page: payload.page - 1 });
    await reportStore.fetchCheckReceivedReportList();
	chkSelectAll.value = false;
    selectAll();
  };

</script>
<style scoped src="@/assets/css/pc/share.css">
</style>

import constant from "@/common/constants"
import http from "@/common/http"
import utils from "@/common/util"

export default {
    uploadReport: (payload) => {
		var url = constant.NEW_UPLOAD_LIST
        
        var formdata = new FormData()
        formdata.append("partnerCode", payload.partnerCode)
        formdata.append("reportCode", payload.reportCode)
        formdata.append('reportPrice', payload.reportPrice)
        formdata.append("reportFileInfo", JSON.stringify({"fileInfoList":payload.reportFileInfo}))
        formdata.append('fileList', payload.fileList)
        for (let file in payload.fileList) {
            if(file != null)
                formdata.append('fileList', payload.fileList[file])
        }
        
		return http.post(url, formdata, {headers: {"Content-Type": "multipart/form-data"}})
	},
    getUploadList: (payload) => {
        var url = constant.GRT_UPLOAD_LIST

        var newPayload = {
			us: payload.uploadStatus,
			pcn: payload.partnerCompanyName,
			cb: payload.createdBy,
            iu: payload.isIncludingUploader,
			from: payload.createdDateFrom,
			to: payload.createdDateTo,
            rcd: payload.reportCode,
			size: payload.size,
			page: payload.page,
			sort: payload.sort,
		}
        
        url = utils.makeUrl(url, newPayload)
		return http.get(url)
	},
    addUploadOption : (payload) => {
        var url = constant.NEW_UPLOADOPTION_URL
		return http.post(url, payload)
    },
	getUploadOptionList: (payload) => {
        var url = constant.GRT_UPLOADOPTION_LIST

        var newPayload = {
			rcd: payload.reportCd,
			cmt: payload.comment,
			mode: payload.uploadMode,
            size: payload.size,
			page: payload.page,
			sort: payload.sort,
		}
        url = utils.makeUrl(url, newPayload)
		return http.get(url)
	},
    getUploadOptionInfo: (payload) => {
		var url = constant.GET_UPLOADOPTION_INFO
        url += "/"+payload
		return http.get(url)
	},
    modifyUploadOption: (payload) => {
        var url = constant.MODIFY_UPLOADOPTION_URL
        return http.put(url, payload)
    },
    deleteUploadOption : (payload) => {
        var url = constant.DELETE_UPLOADOPTION_URL
        var temp = [];
        for(var i = 0; i < payload.id.length; i++)
            temp.push(payload.id[i])
        var deleteP = {idList: temp}

		return http.delete(url, {data: deleteP})
    },

}
import constant from "@/common/constants"
import http from "@/common/http"
import utils from "@/common/util"

export default {
	//공통
	downloadReport: (reportId, opt) => {
		let url = constant.GET_DOWN_REPORT_URL.replace("${reportId}", reportId).replace("${opt}", opt)
		return http.get(url, {responseType: "arraybuffer"})
	},
	getReportInfo: (reportId) => {
		let url = constant.GET_REPORT_INFO_URL.replace("${reportId}", reportId)
		return http.get(url)
	},
	getReportHistory: (reportId) => {
		let url = constant.GET_REPORT_HISTORY_URL.replace("${reportId}", reportId)
		return http.get(url)
	},
	
	//발신함
	getReportList: (filter) => {
		let url = utils.makeUrl(constant.GET_REPORT_LIST_URL, filter)
		return http.get(url)
	},
	openReports: (payload) => {
		let url = constant.PUT_OPEN_REPORTS_URL
		return http.put(url, payload)
	},
	closeReports: (payload) => {
		let url = constant.PUT_CLOSE_REPORTS_URL
		return http.put(url, payload)
	},
	sendReadReports: (payload) => {
		let url = constant.PUT_SENDREAD_REPORTS_URL
		return http.put(url, payload)
	},
	sendReplyReports: (payload) => {
		let url = constant.PUT_SENDREPLY_REPORTS_URL
		return http.put(url, payload)
	},
	deleteReports: (payload) => {
		let url = constant.DELETE_REPORTS_URL
		return http.delete(url, {data: payload})
	},
	resetExpirationDate: (payload) => {
		let url = constant.PUT_RESET_EXP_DATE_URL
		return http.put(url, payload)
	},

	//수신함
	getReceivedReportList: (filter) => {
		let url = utils.makeUrl(constant.GET_RECEIVED_REPORT_LIST_URL, filter)
		return http.get(url)
	},	
	getCheckReceivedReportList: (sharedBoxId, filter) => {
		var url = constant.GET_CHECK_RECEIVED_REPORT_LIST_URL.replace("${sharedBoxId}", sharedBoxId)
		url = utils.makeUrl(url, filter)
		return http.get(url)
	},
	replyReports:(payload) => {
		let url = constant.PUT_RECEIVED_REPORT_REPLY_URL.replace("${reportId}", payload.reportId)
		return http.put(url, payload)
	},

	//승인
	getApprovalReportList: (filter) => {
		let url = utils.makeUrl(constant.GET_APPROVAL_REPORT_LIST_URL, filter)
		return http.get(url)
	},
	approveReports: (payload) => {
		let url = constant.PUT_APPROVE_REPORTS_URL
		return http.put(url, payload)
	},

	//시스템관리자
	getReportCount: (companyId) => {
		let url = utils.makeUrl(constant.GET_REPORT_COUNT_URL, {companyId: companyId})
		return http.get(url)
	},
}

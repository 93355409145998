import {defineStore} from "pinia"
import {reactive, computed} from "vue"
import shareBoxApi from "@/api/sharebox_api"
import {useI18n} from "vue-i18n"

export const useShareBoxStore = defineStore("shareBox", () => {
  const {t} = useI18n()

  //state
	const state = reactive({
		shareBoxList: [],
		shareBoxCcList: [],
		currentShareBox: {},
		currentCcShareBox: {},
		currentShareBoxReportList: [],
		currentShareBoxRepoFilter: {
			size: 15,
			page: 0,
		},
		currentShareBoxReportInfo: {},
	})

  //actions
  const fetchShareBoxList = async () => {
		try {
			let response = await shareBoxApi.getShareBoxList();

			if (response.status === 200) {
				state.shareBoxList = response.data;
				if (state.shareBoxList != null && state.shareBoxList.length > 0) {
					const matchedBox = state.shareBoxList.find(box => box.sharedBoxId === state.currentShareBox.sharedBoxId);

					if (matchedBox) {
						state.currentShareBox = matchedBox;
					} else {
						if (state.shareBoxList.length > 0) {
							state.currentShareBox = state.shareBoxList[0];
						} else {
							state.currentShareBox = null;
						}
					}
					fetchShareBoxReportList(state.currentShareBox.sharedBoxId);
				}
			} else {
				console.log(t("message.msg_202"))
			}
		} catch (error) {
			if (error.response.data.message != null && error.response.data.message != undefined) {
				alert(t("message.msg_203") + "\n" + error.response.data.message)
				console.log(t("message.msg_203") + "\n" + error.response.data.message)
			} else {
				alert(error)
			}
		}
  }

	const fetchShareBoxCcList = async () => {
    try {
			let response = await shareBoxApi.getShareBoxCcList()

			if (response.status === 200) {
				state.shareBoxCcList = response.data
				if (state.shareBoxCcList != null && state.shareBoxCcList.length > 0 && state.currentCcShareBox != null) {
					state.currentCcShareBox = state.shareBoxCcList[0]
					fetchShareBoxReportList(state.currentCcShareBox.sharedBoxId);
				}
			} else {
				console.log(t("message.msg_202"))
			}
		} catch (error) {
			if (error.response.data.message != null && error.response.data.message != undefined) {
				alert(t("message.msg_203") + "\n" + error.response.data.message)
				console.log(t("message.msg_203") + "\n" + error.response.data.message)
			} else {
				alert(error)
			}
		}
  }

  const fetchShareBoxReportList = async (sharedBoxId) => {
    try {
			let response = await shareBoxApi.getShareBoxReportList(sharedBoxId, state.currentShareBoxRepoFilter)
			if (response.status === 200) {
				state.currentShareBoxReportInfo = {totalPages: response.data.totalPages, totalElements: response.data.totalElements, currentPage: response.data.pageable.pageNumber + 1}
				state.currentShareBoxReportList = response.data.content
			} else {
				console.log(t("message.msg_202"))
			}
		} catch (error) {
			if (error.response.data.message != null && error.response.data.message != undefined) {
				alert(t("message.msg_203") + "\n" + error.response.data.message)
				console.log(t("message.msg_203") + "\n" + error.response.data.message)
			} else {
				alert(error)
			}
		}
  }

	const addShareReports = async (reportIds) => {
		var payload = {
			sharedBoxId: state.currentShareBox.sharedBoxId,
			reportIds: reportIds,
		}
		try {
			let response = await shareBoxApi.addShareReports(payload)
			
			if (response.status === 200) {
				await fetchShareBoxReportList(payload.sharedBoxId)
			} else {
				console.log(t("message.msg_202")+"aaaaaaaaaaaaaaaa")
			}
		} catch (error) {
			if (error.response.data.message != null && error.response.data.message != undefined) {
				alert(t("message.msg_203") + "\n" + error.response.data.message)
				console.log(t("message.msg_203") + "\n" + error.response.data.message)
			} else {
				alert(error)
			}
		}
	}

	const setShareBox = (newShareBox) => {
		state.currentShareBox = newShareBox;
	};

	const setCcShareBox = (newShareBox) => {
		state.currentCcShareBox = newShareBox;
	};

	const setCurrentShareBoxRepoFilter = (filters) => {
		const newFilters = { ...state.currentShareBoxRepoFilter }
		for (const key in filters) {
			if (Object.prototype.hasOwnProperty.call(filters, key)) {
				newFilters[key] = filters[key]
			}
		}

		state.currentShareBoxRepoFilter = newFilters
	}

  //getters
  const getShareBoxList = computed(() => state.shareBoxList)
  const getShareBoxCcList = computed(() => state.shareBoxCcList)
  const getShareBoxReportList = computed(() => state.currentShareBoxReportList)
  const getCurrentShareBox = computed(() => state.currentShareBox)
  const getCurrentCcShareBox = computed(() => state.currentCcShareBox)
  const getCurrentShareBoxRepoFilter = computed(() => state.currentShareBoxRepoFilter)
  const getCurrentShareBoxReportInfo = computed(() => state.currentShareBoxReportInfo)
  

  return { 
		setShareBox, 
		setCcShareBox,
		addShareReports,
		setCurrentShareBoxRepoFilter, 
		fetchShareBoxList, 
		fetchShareBoxCcList, 
		fetchShareBoxReportList, 
		getShareBoxList, 
		getShareBoxReportList, 
		getCurrentShareBox, 
		getCurrentCcShareBox,
		getShareBoxCcList,
		getCurrentShareBoxRepoFilter,
		getCurrentShareBoxReportInfo
	}
})
<template>
	<section class="main_container">
		<article class="main_left" :style="{width: infoFlag ? '74%' : '100%'}">
			<div class="title">{{ type === 'share' ? t("message.msg_303") : t("message.msg_304") }}</div>
			<div class="left_title">
				<div class="list_btns">
					<button class="list_up" @click="toggleFilter"></button>
					<div class="title">{{ type === 'share' ? currentShareBox?.name || '' : currentCcShareBox?.name || '' }}</div>
				</div>
				<div v-if="shareBoxLength > 0" class="list_search">
					<template v-if="type == 'share'">
						<button @click="onclickReportModal()">{{ t("message.msg_317") }}</button>
						<button @click="deleteReports()">{{ t("message.msg_323") }}</button>
					</template>
				</div>
			</div>
		
			<div v-if="shareBoxLength > 0" class="left_list_table">
				<table class="table table-borderd">
					<thead>
						<tr>
							<td style="width: 1%;"><input v-model="chkSelectAll" type="checkbox" @change="selectAll" /></td>
							<td style="width: 20%;">{{ t("message.msg_018") }} </td>
							<td>{{ t("message.msg_014") }}</td>
							<td>{{ t("message.msg_025") }}</td>
							<td>{{ t("message.msg_030") }}</td>
							<td>{{ t("message.msg_085") }}</td>
							<td>{{ t("message.msg_039") }}</td>
							<td></td>
						</tr>
					</thead>
					<tbody v-if="currentShareBoxReportList.length!=0">
						<template v-for="report in currentShareBoxReportList" :key="report.reportId">
							<tr :class="{selected: isSelected(report)}" @click="clickReport($event, report)">
								<th><input v-model="chkSelect[report.reportId]" type="checkbox" @change="selectReport(report.reportId)" /></th>
								<th>{{ report.reportNm }}</th>
								<th>{{ getReadStatusText(report.readStatus) }}</th>
								<th><button v-show="report.replyStatus != 'NULL'" :style="{backgroundPosition: report.replyStatus == 'READY' ? 'right' : 'left'}"></button></th>
								<th>{{ report.companyNm }}</th>
								<th>{{ report.price }}</th>
								<th>{{ format(report.createdDate, "yyyy-MM-dd HH:mm:ss") }}</th>
								<th>
									<div class="list_btn">
										<div class="list_modal">
											<button class="list_more" @click.stop.prevent="clickMore($event)"></button>
											<div class="modal">
												<div v-on-click-outside="onClickOutside">
													<ul>
														<li @click.stop.prevent="onClickDownload(report, downloadType[0].value)"><a href="#">{{ downloadType[0].text }}</a></li>
														<!-- <li @click.stop.prevent="onClickDownload(report.reportId, downloadType[2].value)"><a href="#">{{ downloadType[2].text }}</a></li> -->
														<li v-if="report.fileCount > 1" @click.stop.prevent="onClickDownload(report, downloadType[1].value)"><a href="#">{{ downloadType[1].text }}</a></li>
														<li @click.stop.prevent="onClickDownload(report, downloadType[2].value)"><a href="#">{{ downloadType[2].text }}</a></li>
													</ul>
												</div>
											</div>
										</div>
									</div>
								</th>
							</tr>
						</template>
					</tbody>
				</table>
				<CommonPagination v-if="totalPages != undefined" ref="pagination" :total-pages="totalPages" @move-page="movePage" />
			</div>
			<div v-else class="emptyList">
				{{ t("message.msg_325") }}
			</div>
		</article>
		<article v-if="infoFlag" class="main_right">
			<ReportInfoMain @close-info="closeInfo" />
		</article>
	</section>

	<template v-if="showShareBoxReportListModal">
		<div class="modal_table">
			<shared-box-modal class="modal_table" @close-modal="close()">
				<add-report-list-modal @close="close()" />
			</shared-box-modal>
		</div>
	</template>
</template>


<script setup>
import CommonPagination from "@/components/common/CommonPagination"
import { vOnClickOutside } from '@vueuse/components'
import { useI18n } from "vue-i18n";
import { ref, computed, defineEmits, watch, defineProps, onMounted } from "vue";
import { useShareBoxStore } from "@/store/shareBoxStore.js";
import _ from "lodash";
import { useReportStore } from "@/store/reportStore";
import ReportInfoMain from ".././report/reportInfos/ReportInfoMain"
import { format } from "date-fns";
import shareBoxApi from "@/api/sharebox_api.js";
import {downloadReport, getReadStatusText} from "@/composable/report"
import AddReportListModal from "@/components/sharedBox/AddReportListModal";
import SharedBoxModal from "@/components/sharedBox/SharedBoxModal";

const totalPages = ref(1)
const chkSelectAll = ref(false);
const selectedReportList = ref([]);
const chkSelect = ref({});
const infoFlag = ref(false)
const pagination = ref(null)
const reportStore = useReportStore();
const { fetchReportHistory, setReceivedReportInfo } = reportStore;
const { t } = useI18n();
const currentShareBoxReportList = computed(() => shareBoxStore.getShareBoxReportList);
const currentCcShareBox = computed(() => shareBoxStore.getCurrentCcShareBox);
const currentShareBox = computed(() => shareBoxStore.getCurrentShareBox)
const shareBoxStore = useShareBoxStore();
const {fetchShareBoxReportList} = shareBoxStore

const downloadType = [
  { value: "pdf", text: t("message.msg_250") },
  { value: "all", text: t("message.msg_058") },
  {value: "dl", text: t("message.msg_233")},
//   { value: "csv", text: t("message.msg_239") },
];

const emit = defineEmits(["toggle-filter", "close"])

onMounted(async() => {
	shareBoxStore.setCurrentShareBoxRepoFilter({ page: 0 });
	totalPages.value = shareBoxStore.getCurrentShareBoxReportInfo.totalPages
})

watch(() => shareBoxStore.getCurrentShareBoxReportInfo, (newValue) => {
	totalPages.value =  newValue.totalPages
	if(pagination.value != null)
		pagination.value.setPage(newValue.currentPage)
})

const onClickDownload = (report, type) => {
	if((type=='all' && report.fileCount > 1) || type!='all' ){
		downloadReport(report, type)
	}
}

const props = defineProps({
  type: { type: String, required: true },
  shareBoxLength: {type:Number, required: true}
});

const toggleFilter = () => {
	emit("toggle-filter")
}

const movePage = async (payload) => {
	await shareBoxStore.setCurrentShareBoxRepoFilter({page: payload.page - 1})
	await shareBoxStore.fetchShareBoxReportList(currentShareBox.value.sharedBoxId)
	chkSelectAll.value = false;
    selectAll();
}

const selectReport = (reportId) => {
  var flag = chkSelect.value[reportId];
  if (flag) {
    selectedReportList.value.push(reportId);
    if (selectedReportList.value.length == currentShareBoxReportList.value.length) {
      chkSelectAll.value = true;
    }
  } else {
    chkSelectAll.value = false;
    selectedReportList.value.splice(selectedReportList.value.findIndex((r) => r === reportId), 1);
  }
};

const selectAll = () => {
  if (chkSelectAll.value) {
    selectedReportList.value = currentShareBoxReportList.value.map((r) => r.reportId);
  } else {
    selectedReportList.value = [];
  }

  for (let r of currentShareBoxReportList.value) {
    chkSelect.value[r.reportId] = chkSelectAll.value;
  }
};

const clickReport = (evt, report) => {
	if(evt.target.type != undefined && evt.target.type == "checkbox") {
		return
	}
  setReceivedReportInfo(report.reportId);
  fetchReportHistory(report.reportId);
  reportStore.setCurrentReport(report);
  infoFlag.value = true
};

const closeInfo = () => {
	infoFlag.value = false
}

const deleteReports = () => {
	if (selectedReportList.value.length == 0){
		return alert(t("message.msg_249"))
	}
	if(confirm(t("message.msg_308"))){
		const payload = {
			sharedBoxId: currentShareBox.value.sharedBoxId,
			reportIds: selectedReportList.value,
		}
		shareBoxApi.deleteShareReports(payload)
			.then(() => {
				fetchShareBoxReportList(currentShareBox.value.sharedBoxId)
				selectedReportList.value = [];
				chkSelect.value = {};
				chkSelectAll.value = false;
			})
			.catch((err) => {
				alert(err.response.data.message)
			})
	}
};

const showShareBoxReportListModal = ref(false);

const close = () => {
	showShareBoxReportListModal.value = false;
}

const onclickReportModal = () => {
	showShareBoxReportListModal.value = true;
}

const clickMore = (event) => {
	event.stopPropagation()
	var modal = event.target.nextElementSibling
	var flag = modal.style.display == "none" || modal.style == undefined || modal.style.display == ""
	if (flag){
		modal.style.display = "block"
	} else {
		modal.style.display = "none"
	}
}

const onClickOutside = (event) => {
	document.querySelectorAll(".modal").forEach((modal) => {
		var icon = modal.previousElementSibling
		if(event.target != icon){
			modal.style.display = "none"
		}
	})
}

const isSelected = (report) => {
	return _.isEqual(reportStore.getCurrentReport, report) && infoFlag.value
}
</script>

<style scoped src="@/assets/css/pc/share.css">
</style>

<style scoped>
.selected {
	background-color: var(--vc-gray-100);
}
</style>
// AddReportListModal.vue와 ReceivedReportList.vue 공통 메서드
import {useReportStore} from "@/store/reportStore"
import {computed, ref, emit} from "vue"
import {useShareBoxStore} from "@/store/shareBoxStore"


export function useShareBoxReportFunctions() {
  const reportStore = useReportStore()
  const {fetchReportHistory, setReceivedReportInfo} = reportStore
  const chkSelectAll = ref(false)
  const chkSelect = ref({})
  const reportList = ref([])
  const selectedReportList = ref([])
  const currentShareBoxReportList = computed(() => shareBoxStore.getShareBoxReportList);
  const shareBoxStore = useShareBoxStore()
  const mode = "addShareBoxRepoDlg"

  const selectReport = (reportId) => {
    const flag = chkSelect.value[reportId];
    if (flag) {
      selectedReportList.value.push(reportId);
      if (selectedReportList.value.length === reportList.value.length) {
        chkSelectAll.value = true;
      }
    } else {
      chkSelectAll.value = false;
      selectedReportList.value.splice(selectedReportList.value.findIndex((r) => r === reportId), 1);
    }

    // 공유문서함에 이미 있는 장표일 경우 모두 선택에서 추가되지 않게 함
    if (mode === 'addShareBoxRepoDlg') {
      selectedReportList.value = selectedReportList.value.filter(
        (reportId) => !currentShareBoxReportList.value.some((item) => item.reportId === reportId)
      );
    }
  };

  const selectAll = () => {
    if (chkSelectAll.value) {
      selectedReportList.value = reportList.value.map((r) => r.reportId);
    } else {
      selectedReportList.value = [];
    }

    for (const r of reportList.value) {
      chkSelect.value[r.reportId] = chkSelectAll.value;
    }

    // 공유문서함에 이미 있는 장표일 경우 모두 선택에서 추가되지 않게 함
    if (mode === 'addShareBoxRepoDlg') {
      selectedReportList.value = selectedReportList.value.filter(
        (reportId) => 
          !currentShareBoxReportList.value.some((item) => item.reportId === reportId) &&
          !reportList.value.some((r) => r.reportId === reportId && r.disabled)
      );
    }
  };

  return {
    selectReport,
    selectAll,
    setReceivedReportInfo,
    fetchReportHistory,
    chkSelectAll,
    chkSelect,
    reportList,
    selectedReportList,
    currentShareBoxReportList,
    shareBoxStore,
    reportStore,
    mode,
  };
}

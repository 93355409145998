<template>
	<div class="scrollBar">
		<button	class="right_xmark" @click="closeInfo">
			<img :src="$icons.close" alt="" />
		</button>
		<div class="right_title">
			<h3>{{ partner.partnerCompanyName }}</h3>
			<p>{{ partner.partnerUserName }}</p>
		</div>
		<div class="right_tab tab">
			<button	id="defaultOpen" class="tablinks" :class="{active: activedTab == 'info'}" @click="changeTab('info')">{{ t("message.msg_026") }}</button>
			<button class="tablinks" :class="{active: activedTab == 'history'}" @click="changeTab('history')">{{ t("message.msg_550") }}{{ t("message.msg_570") }}</button>
		</div>
		<PartnerInfo v-if="activedTab == 'info'" />
		<PartnerHistory v-if="activedTab == 'history'" />
	</div>
</template>
<script setup>
import PartnerInfo from "./PartnerInfo"
import PartnerHistory from "./PartnerHistory"

import {useI18n} from "vue-i18n"
import {computed, ref, defineEmits} from "vue"
import {usePartnerStore} from "@/store/partnerStore"

const partnerStore = usePartnerStore()
const {t} = useI18n()

const emit = defineEmits(["close-info"])

const activedTab = ref("info")

const partner = computed(() => partnerStore.getCurrentPartnerInfo)

const closeInfo = () => {
	emit("close-info")
}

const changeTab = (tab) => {
	activedTab.value = tab
}

</script>
<style scoped src="@/assets/css/pc/account.css">
</style>
<template>
	<div style="width: 100%; height: 100%; align-items: center; display: flex; flex-direction: column;">
		<div class="input_wrap">
			<label for=""><span>*</span>{{ t("message.msg_608") }}</label>
			<input v-model="reportCd" type="text" maxlength='16'/>
		</div>
		<div class="input_wrap">
			<label for="">{{ t("message.msg_609") }}</label>
			<input v-model="comment" type="text" />
		</div>
		<div class="input_wrap">
			<label for=""><span>*</span>{{ t("message.msg_612") }}</label>
			<input v-model="maxFile" type="text" placeholder="0" />
		</div>
		<div class="input_wrap">
			<label for=""><span>*</span>{{ t("message.msg_613") }}</label>
			<input v-model="defaultReportName" type="text" />
		</div>
		<div class="input_wrap">
			<label for="">{{ t("message.msg_614") }} </label>
			<input v-model="defaultMemo" type="text" />
		</div>
		<div class="input_wrap">
			<label for=""><span>*</span>{{ t("message.msg_615") }}</label>
			<input v-model="defaultRetentionPeriod" type="text" style="width: 50%;" /><select id="RetentionPeriod" v-model="option1" style="width: 20%;"><option value="d">d</option><option value="w">w</option><option value="m">m</option><option value="y">y</option></select>
		</div>
		<div class="input_wrap">
			<label for=""><span>*</span>{{ t("message.msg_616") }}</label>
			<input v-model="defaultReplyPeriod" type="text" style="width: 50%;" /><select id="ReplyPeriod" v-model="option2" style="width: 20%;"><option value="d">d</option><option value="w">w</option><option value="m">m</option><option value="y">y</option></select>
		</div>
		<div class="input_wrap">
			<label for=""><span>*</span>{{ t("message.msg_617") }}</label>
			<div class="side_check">
				<label class="container">{{ t("message.msg_625") }}
					<input v-model="replyFlag" type="checkbox" true-value="true" false-value="" />
					<span class="checkmark"></span>
				</label>
				<label class="container">{{ t("message.msg_626") }}
					<input v-model="replyFlag" type="checkbox" true-value="false" false-value="" />
					<span class="checkmark"></span>
				</label>
			</div>
		</div>
		<!-- <div class="input_wrap">
			<label for=""><span>*</span>{{ t("message.msg_618") }}</label>
			<div class="side_check">
				<label class="container">{{ t("message.msg_625") }}
					<input v-model="replyCommentFlag" type="checkbox" true-value="true" false-value="false/" :disabled="replyFlag==='false'" />
					<span class="checkmark"></span>
				</label>
				<label class="container">{{ t("message.msg_626") }}
					<input v-model="replyCommentFlag" type="checkbox" true-value="false" false-value="true/" :disabled="replyFlag==='false'" />
					<span class="checkmark"></span>
				</label>
			</div>
		</div> -->
		<div class="input_wrap">
			<label for=""><span>*</span>{{ t("message.msg_619") }}</label>
			<div class="side_check">
				<label class="container">{{ t("message.msg_625") }}
					<input v-model="releaseFlag" type="checkbox" true-value="true" false-value="" />
					<span class="checkmark"></span>
				</label>
				<label class="container">{{ t("message.msg_626") }}
					<input v-model="releaseFlag" type="checkbox" true-value="false" false-value="" />
					<span class="checkmark"></span>
				</label>
			</div>
		</div>
		<div class="input_wrap">
			<label for=""><span>*</span>{{ t("message.msg_620") }}</label>
			<div class="side_check">
				<label class="container">{{ t("message.msg_625") }}
					<input v-model="approvalFlag" type="checkbox" true-value="true" false-value="" />
					<span class="checkmark"></span>
				</label>
				<label class="container">{{ t("message.msg_626") }}
					<input v-model="approvalFlag" type="checkbox" true-value="false" false-value="" />
					<span class="checkmark"></span>
				</label>
			</div>
		</div>
		<button type="submit" @click="save">{{ t("message.msg_556") }}</button>
	</div>
</template>
<script setup>
import uploadAPI from "@/api/upload_api"
import {ref, watch, defineEmits} from "vue"
import {useI18n} from "vue-i18n"

const {t} = useI18n()
const reportCd = ref("")
const comment = ref("")
const uploadMode = ref("PDF")
const fileFilter = ref("")
const maxFile = ref(0)
const defaultReportName = ref("")
const defaultMemo = ref("")
const defaultRetentionPeriod = ref("1")
const defaultReplyPeriod = ref("1")
const option1 = ref("w") //defaultRetentionPeriod select option
const option2 = ref("w") //defaultReplyPeriod select option
const replyFlag = ref("")
const replyCommentFlag = ref("true")
const releaseFlag = ref("")
const approvalFlag = ref("")

const emit = defineEmits(["closeModal"])

const closeModal = () => {
	emit("closeModal");
}

watch(() => defaultRetentionPeriod.value, () => {
	defaultRetentionPeriod.value = (String (defaultRetentionPeriod.value)).replace(/[^0-9]/g, '') //24.06.03 #26423 juank 숫자 이외에 값은 입력 안되도록
})

watch(() => defaultReplyPeriod.value, () => {
	defaultReplyPeriod.value = (String (defaultReplyPeriod.value)).replace(/[^0-9]/g, '') //24.06.03 #26423 juank 숫자 이외에 값은 입력 안되도록
})

watch(() => maxFile.value, () => {
	maxFile.value = (String (maxFile.value)).replace(/[^0-9 ]/g, '') //24.06.03 #26423 juank 숫자 이외에 값은 입력 안되도록
})

// watch(() => replyFlag.value, () => {
// 	if(replyFlag.value == 'false')
// 		replyCommentFlag.value = 'false'
// })

const save = async() => {
	if(reportCd.value == "" || uploadMode.value == "" || maxFile.value == null || defaultReportName.value == "" || defaultRetentionPeriod.value == "" || option1.value == "" ||defaultReplyPeriod.value == "" || option2.value == "" || replyFlag.value == "" || releaseFlag.value == "" || approvalFlag.value == "") {
		alert(t("message.msg_627"))
		return
	}

	let payload = {
		reportCd: reportCd.value,
		comment: comment.value,
		uploadMode: uploadMode.value,
		fileFilter: fileFilter.value,
		maxFile: (maxFile.value*1),
		defaultReportName: defaultReportName.value,
		defaultMemo: defaultMemo.value,
		defaultRetentionPeriod: (defaultRetentionPeriod.value*1) + option1.value,
		defaultReplyPeriod: (defaultReplyPeriod.value*1) + option2.value,
		replyFlag: replyFlag.value,
		replyCommentFlag: replyCommentFlag.value,
		releaseFlag: releaseFlag.value,
		approvalFlag: approvalFlag.value,
	}
	try {
		let response = await uploadAPI.addUploadOption(payload)
		if (response.status === 200) {
			alert(t("message.msg_533"))
			closeModal()
		}
		else{
			alert(response.status + t("message.msg_203"))
		}
	} catch (error) {
		alert(error.response.data.message)
	}
  
}
</script>

<style scoped src="@/assets/css/pc/upload.css">
</style>
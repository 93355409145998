<template>
	<div class="wrap">
		<header>
			<div class="headerWrap">
				<div class="header_logo">
					<h1 class="logo">
						<a href="#">
							<img :src="$icons.logo" alt="logo" />
						</a>
					</h1>
				</div>
				<div class="header_admin">
					<a href="#" class="setting">
						<img class="setting" :src="$icons.setting" alt="" @click.stop.prevent="clickMore($event)" />
						<div class="modal">
							<ul v-on-click-outside="onClickOutside">
								<li @click="logout">{{ t("message.msg_642") }}</li>
							</ul>
						</div>
					</a>
				</div>
			</div>
		</header>
		<div class="contents">
			<router-view />
		</div>
	</div>
</template>

<script setup>
import {ref} from "vue"
import { vOnClickOutside } from '@vueuse/components'
import jwt from "@/common/jwt"
import {useRouter} from "vue-router"
import { useI18n } from "vue-i18n";

const { t } = useI18n();
const router = useRouter()
const menu = ref(true);

const menuHandler = (show) => {
  menu.value = show;
};

const clickMore = (event) => {
    event.stopPropagation()
    var modal = event.target.nextElementSibling
    var flag = modal.style.display == "none" || modal.style == undefined || modal.style.display == ""
    if (flag){
        modal.style.display = "block"
    } else {
        modal.style.display = "none"
    }
}

const logout = () => {
    jwt.deleteToken()
    router.replace("/login")
}

const onClickOutside = (event) => {
    document.querySelectorAll(".modal").forEach((modal) => {
        var icon = modal.previousElementSibling
        if(event.target != icon){
            modal.style.display = "none"
        }
    })
}
</script>

<style scoped src="@/assets/css/mobile/ticket.css">
</style>
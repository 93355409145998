<template>
	<div id="loginWrap">
		<div class="login_cont login_input">
			<img :src="$icons.login_logo" alt="" class="login_logo" />
			<div class="login_text">
				<form>
					<input v-model="id" type="email" :placeholder="t('message.msg_635')" @keyup.enter="login()" />
					<input v-model="password" type="password" :placeholder="t('message.msg_636')" @keyup.enter="login()" />
				</form>
			</div>
			<div class="login_btn">
				<button @click="login()">{{ t("message.msg_632") }}</button>
				<button @click="JoinDialog = true">{{ t("message.msg_532") }}</button>
			</div>
			<a style="cursor: pointer;" @click="PasswordDialog = true">{{ t("message.msg_633") }}</a>
		</div>
		<div class="login_cont login_img">
			<img :src="$icons.login" alt="" />
		</div>
	</div>

	<div v-show="JoinDialog" class="join_popup">
		<div class="popup_wrap">
			<div class="popup">
				<div class="popup_inner">
					<p>
						{{ t("message.msg_637") }}<br />
						{{ t("message.msg_638") }}
					</p>
					<input v-model="email" type="email" :placeholder="t('message.msg_635')" />
					<button @click="sendRegistEmail()">{{ t("message.msg_634") }}</button>
				</div>
				<button class="close_btn" @click="closeDialLog"></button>
			</div>
		</div>
	</div>

	<div v-show="PasswordDialog" class="join_popup">
		<div class="popup_wrap">
			<div class="popup">
				<div class="popup_inner">
					<p>
						{{ t("message.msg_637") }}<br />
						{{ t("message.msg_655") }}
					</p>
					<input v-model="email" type="email" :placeholder="t('message.msg_635')" />
					<button @click="sendPasswordEmail()">{{ t("message.msg_634") }}</button>
				</div>
				<button class="close_btn" @click="closeDialLog"></button>
			</div>
		</div>
	</div>
</template>
<script setup>
import userAPI from "@/api/user_api"
import {useI18n} from "vue-i18n"
import {ref} from "vue"
import { useLoginFunctions } from "@/composable/login";

const { id, password, router, login, checkId,  checkPw} = useLoginFunctions();

const email = ref("")
const {t} = useI18n()
const JoinDialog = ref(false)
const PasswordDialog = ref(false)

//24.01.23 #25910 juank 회원가입 링크를 이메일로 보내는 함수
const sendRegistEmail = async () => {
	try {
		let response = await userAPI.sendRegistEmail(email.value)
		if (response.status === 200) {
			alert(t("message.msg_639"))
			closeDialLog()
		} else {
			alert(t("message.msg_202"))
		}
		
	} catch (error) {
		alert(error.response.data.message)
	}
}

const closeDialLog = () =>{
	PasswordDialog.value = false
	JoinDialog.value = false
	email.value = ""
}

//24.01.23 #25910 juank 회원가입 링크를 이메일로 보내는 함수
const sendPasswordEmail = async () => {
	try {
		let response = await userAPI.sendNewPasswordEmail(email.value)
		if (response.status === 200) {
			alert(t("message.msg_656"))
		} else {
			alert(t("message.msg_202"))
		}
		
	} catch (error) {
		alert(error.response.data.message)
	}
}
</script>

<style scoped src="@/assets/css/pc/login.css">
</style>

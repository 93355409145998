<template>
	<main id="main">
		<div class="list">
			<shared-box-list
				v-if="type === 'share'"
				:type="type"
				:share-box-list="shareBoxList"
				@edit-shared-box="editSharedBox"
				@reset-shared-box-list="loadDataBasedOnType"
				@toggle-filter="toggleFilter(false)"
			/>
			<shared-box-cc-list 
				v-else 
				:type="type"
				:share-box-cc-list="shareBoxCcList" 
				@toggle-filter="toggleFilter(false)" 
				@reset-shared-box-list="loadDataBasedOnType"
			/>
		</div>

		<div v-if="shareBoxList">
			<div>
				<shared-box-report-list :type="type" :share-box-length="shareBoxList.length" @toggle-filter="toggleFilter(true)" />
			</div>
		</div>
	</main>
</template>

<script setup>
import { ref, onMounted, watch, inject, defineProps, computed, nextTick } from "vue";
import SharedBoxList from "@/components/sharedBox/SharedBoxList";
import SharedBoxCcList from "@/components/sharedBox/SharedBoxCcList";
import SharedBoxReportList from "@/components/sharedBox/SharedBoxReportList";
import {useShareBoxStore} from "@/store/shareBoxStore.js"
import _ from "lodash"
import {useI18n} from "vue-i18n"

const shareBoxStore = useShareBoxStore();
const {fetchShareBoxList, fetchShareBoxCcList} = shareBoxStore
const props = defineProps({
  type: { type: String, required: true },
});

const shareBoxList = ref([]);
const shareBoxCcList = ref([]);
const showShareBoxListModal = ref(false);
const showShareBoxReportListModal = ref(false);
const item = ref(null);
const {t} = useI18n()

const emitter = inject("emitter")

emitter.on('close', () => {
	close()
})

onMounted(async () => {
	loadDataBasedOnType();
	toggleFilter(true);
});

watch(() => props.type, (newType, oldType) => {
  loadDataBasedOnType();
  toggleFilter(true);

  nextTick(() => {
    var sideMenu = document.getElementById('sideMenu');
    sideMenu.style.transition = 'left 0.5s';
    sideMenu.style.left = '0';
  });
});

const loadDataBasedOnType = async () => {
  if (props.type === 'share') {
    await getShareBoxReportList();
  } else {
    await getShareBoxReportCcList();
  }
};

watch(() =>	shareBoxStore.getShareBoxList, (newValue) => {
	shareBoxList.value = _.cloneDeep(newValue)
})

watch(() =>	shareBoxStore.getShareBoxCcList, (newValue) => {
	shareBoxCcList.value = _.cloneDeep(newValue)
})

const getShareBoxReportList = async () => {
  await fetchShareBoxList({})
  shareBoxList.value = _.cloneDeep(shareBoxStore.getShareBoxList)
}

const getShareBoxReportCcList = async () => {
  await fetchShareBoxCcList({})
  shareBoxList.value = _.cloneDeep(shareBoxStore.getShareBoxCcList)
}

const editSharedBox = (shareBox, mode) => {
	//mode - 0: 추가, 1: 편집, 2: 삭제
	const payload = {
    item: shareBox,
    mode: mode
  };
	item.value = payload;
  showShareBoxListModal.value = true;
}

const toggleFilter = (flag) => {
	var sideMenu = document.getElementById('sideMenu');
	if (flag) { //open filter
		sideMenu.style.transition = 'left 0.5s';
		sideMenu.style.left = '0';
	} else { //close filter
		sideMenu.style.left = '-450px';
	}
}

const close = () => {
	showShareBoxListModal.value = false;
	showShareBoxReportListModal.value = false;
}

</script>
<style scoped src="@/assets/css/pc/share.css">
.list {
	background-color: rgb(207, 207, 207);
	width: 20%;
	min-width: 10em;
	height: 100%;
	float: left;
}
.buttonBar {
	height: 3em;
}
button {
	margin: 1em 0 0 1em;
}
</style>
<template>
	<div class="secret_popup">
		<div class="popup">
			<div class="popup_inner">
				<article class="main_right">
					<div class="scrollBar">
						<div class="right_title">
							<h3>{{ report.reportNm }}</h3>
							<p>{{ report.reportId }}</p>
						</div>
						<div class="right_tab tab">
							<button	id="defaultOpen" class="tablinks" :class="{active: activedTab == 'info'}" @click="changeTab('info')">{{ t("message.msg_026") }}</button>
							<button class="tablinks" :class="{active: activedTab == 'history'}" @click="changeTab('history')">{{ t("message.msg_027") }}</button>
						</div>
						<MobileReportInfo v-if="activedTab == 'info'" @close-info="closeInfo" />
						<MobileReportHistory v-if="activedTab == 'history'" @close-info="closeInfo" />
					</div>
				</article>
			</div>
			<button class="close_btn" @click="closeInfo"></button>
		</div>
	</div>
</template>
<script setup>
import MobileReportInfo from "./MobileReportInfo.vue"
import MobileReportHistory from "./MobileReportHistory"

import {useI18n} from "vue-i18n"
import {computed, ref, defineEmits} from "vue"
import {useReportStore} from "@/store/reportStore"

const reportStore = useReportStore()
const {t} = useI18n()

const emit = defineEmits(["close-info"])

const activedTab = ref("info")

const report = computed(() => reportStore.getCurrentReport)

const closeInfo = () => {
	emit("close-info")
}

const changeTab = (tab) => {
	activedTab.value = tab
}

</script>
<style scoped src="@/assets/css/mobile/ticket.css">
</style>
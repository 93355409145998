import {createApp} from "vue"
import App from "./App.vue"
import i18n from "./i18n"
import router from "./router"
import constants from "./common/constants"
import icons from "./common/icons"
import store from "./store"
import mitt from "mitt"
import CommonModal from "@/components/common/CommonModal.vue"
import {createPinia} from "pinia"
import {setupCalendar, Calendar, DatePicker} from "v-calendar"

const pinia = createPinia()
const app = createApp(App)
app.use(router)
app.use(i18n)
app.use(store)
app.use(pinia)

app.mount("#app")
app.component("VCalendar", Calendar)
app.component("VDatePicker", DatePicker)
app.component("CommonModal", CommonModal)

const emitter = mitt()

app.config.globalProperties.$constants = constants
app.config.globalProperties.$emitter = emitter
app.config.globalProperties.$icons = icons
app.provide("emitter", emitter)

router.beforeEach((to, from, next) => {
	const TOKEN_KEY = "token"

	if (!to.meta.requireAuth) {
		next()
	} else {
		let token = localStorage.getItem(TOKEN_KEY)

		if (token == null || token === undefined || token.length === 0) {
			next({name: "login"})
		} else {
			next()
		}
	}
})

<template>
	<section class="main_container">
		<article class="main_left" style="width: 100%">
			<div class="title">{{ t("message.msg_714") }}</div>
			<div class="left_list_table">
				<table>
					<thead>
						<tr>
							<td style="width: 20%;"><span @click.stop.prevent="updateSort('name')">{{ t("message.msg_510") }}</span> <img :src="getSortIcon('name')" alt="" /></td>
							<td><span>{{ t("message.msg_700") }}</span> <!--<img :src="getSortIcon('')" alt="" />--></td>
							<td><span>{{ t("message.msg_701") }}</span> <!--<img :src="getSortIcon('')" alt="" />--></td>
							<td><span @click.stop.prevent="updateSort('createdDate')">{{ t("message.msg_519") }}</span> <img :src="getSortIcon('createdDate')" alt="" /></td>
							<td><span>{{ t("message.msg_702") }}</span> <!--<img :src="getSortIcon('')" alt="" />--></td>
							<td><span>{{ t("message.msg_703") }}</span> <!--<img :src="getSortIcon('')" alt="" />--></td>
							<!--
							<td><span>라이선스 파일 여부</span> <img :src="getSortIcon('')" alt="" /></td>
							-->
							<td class="center"><span>{{ t("message.msg_704") }}</span></td>
							<td class="center"><span>{{ t("message.msg_706") }}</span></td>
							<td class="center"><span>{{ t("message.msg_705") }}</span></td>
							<td class="center"><span>{{ t("message.msg_728") }}</span></td>
						</tr>
					</thead>
					<tbody v-if="companyList.length!=0">
						<template v-for="(company, index) in companyList" :key="index"> 
							<tr>
								<th>{{ company.name }}</th> <!-- 회사명 -->
								<th>{{ company.adminEmail }}</th> <!-- 대표 이메일 -->
								<th>{{ getServicePlanText(company.servicePlan) }}</th> <!-- 요금제 -->
								<th>{{ formatDate(company.createdDate) }}</th> <!-- 생성일 -->
								<th>{{ formatDate(company.serviceStart) }}</th> <!-- 서비스 시작일 -->
								<th>{{ formatDate(company.serviceEnd) }}</th> <!-- 서비스 만료일 -->
								<!-- <th></th> --> <!-- 라이선스 파일 여부 -->
								<template v-for="func in funcList" :key="func.text">
									<th>
										<div class="list_btn">
											<button :class="func.class" :title="func.text" @click.stop.prevent="onClickFunc(company, func.value)"></button>
										</div>
									</th>
								</template>														
							</tr>
						</template>                           
					</tbody>
				</table>
			</div>
			<CommonPagination v-if="totalPages != undefined" ref="pagination" :total-pages="totalPages" @move-page="movePage" />
		</article>
		<teleport to="body">
			<register-service-modal v-if="serviceModalFlag" :company="selectedCompany" @close="onClose('register')" />
		</teleport>
		<teleport to="body">
			<issue-uploader-license-modal v-if="issueModalFlag" :company="selectedCompany" @close="onClose('issue')" />
		</teleport>
		<teleport to="body">
			<usage-modal v-if="usageModalFlag" :company="selectedCompany" @close="onClose('usage')" />
		</teleport>
	</section>
</template>

<script setup>
import {ref, onMounted} from "vue"
import icons from "@/common/icons"
import CommonPagination from "@/components/common/CommonPagination"
import RegisterServiceModal from "./RegisterServiceModal"
import IssueUploaderLicenseModal from "./IssueUploaderLicenseModal"
import UsageModal from "./UsageModal"
import {useCompanyStore} from "@/store/companyStore"
import _ from "lodash"
import {useI18n} from "vue-i18n"
import company_api from "@/api/company_api"

const companyStore = useCompanyStore()
const {t} = useI18n()
const companyList = ref([])
const serviceModalFlag = ref(false)
const issueModalFlag = ref(false)
const usageModalFlag = ref(false)
const sortItem = ref("modifiedDate")
const sortDirection = ref("desc")
const sortCount = ref(0)
const totalPages = ref(1)
const selectedCompany = ref({})
const pagination = ref(null)

const funcList = [
	{text: t("message.msg_704"), value: "register", class: "list_register"},
	{text: t("message.msg_706"), value: "issue", class: "list_license"},
	{text: t("message.msg_705"), value: "usage", class: "list_usage"},
	{text: t("message.msg_728"), value: "delete", class: "list_delete"},
]

const servicePlan = [
	{text: t("message.msg_720"), value: "LIGHT"},
	{text: t("message.msg_722"), value: "STANDARD"},
]

onMounted(async() => {
	await companyStore.fetchCompanyList()
	companyList.value = _.cloneDeep(companyStore.getCompanyList)
	totalPages.value = companyStore.getCompanyPageInfo.totalPages
})

const onClickFunc = (company, func) => {
	selectedCompany.value = company
	if (func == funcList[0].value) {
		serviceModalFlag.value = true
	} else if (func == funcList[1].value) {
		issueModalFlag.value = true
	} else if (func == funcList[2].value) {
		usageModalFlag.value = true
	} else if (func == funcList[3].value) {
		deleteCompany(company.id)
	}
}

const onClose = (modalType) => {
	refresh()
	if (modalType == funcList[0].value) {
		serviceModalFlag.value = false
	} else if (modalType == funcList[1].value) {
		issueModalFlag.value = false
	} else if (modalType == funcList[2].value) {
		usageModalFlag.value = false
	}
}

const refresh = () => {
	fetchCompanyList({page: 0, sort: "modifiedDate,desc"})
}

const movePage = (payload) => {
	fetchCompanyList({page: payload.page - 1})
}

const updateSort = (item) => {
	if (sortItem.value != item) {
		sortCount.value = 0
	}
	sortItem.value = item
	sortCount.value++

	if (sortCount.value == 1) {
		sortDirection.value = "asc"
	} else if (sortCount.value == 2) {
		sortDirection.value = "desc"
	}else if (sortCount.value == 3) {
		sortCount.value = 0
		sortItem.value = null
		sortDirection.value = null
	}

	if (sortItem.value != null) {
		fetchCompanyList({sort: sortItem.value + "," + sortDirection.value})
	}
}

const getSortIcon = (item) => {
  if (sortItem.value === item) {
    return sortCount.value == 1 ? icons.tableUp : icons.tableDown;
  }
  return "";
}

const getVisibility = (item) => {
  if (sortItem.value === item) {
    return 'visible';
  }
  return 'hidden';
}

const fetchCompanyList = async(filter) => {
	await companyStore.setCompanyFilter(filter)
	await companyStore.fetchCompanyList()
	companyList.value = _.cloneDeep(companyStore.getCompanyList)
	totalPages.value = companyStore.getCompanyPageInfo.totalPages
	pagination.value.setPage(companyStore.getCompanyPageInfo.currentPage)
}

const formatDate = (date) => {
	if(date != null && date != undefined) {
		return date.replace("T", " ")
	}
	return ""
}

const getServicePlanText = (plan) => {
	if(plan != null && plan != undefined){
		return servicePlan.find((p) => p.value == plan).text
	}
	return ""
}

const deleteCompany = (id) => {
	var result = confirm(t("message.msg_731"))
	if(result){
		company_api.deleteCompany(id)
		.then(() => {
			alert(t("message.msg_732"))
			refresh()
		}).catch(async (error) => {
			alert(error.response.data.message)
		})
	}
}

</script>

<style scoped src="@/assets/css/pc/admin.css">
</style>

<style scoped>
td.center {
	text-align: center;
}
</style>
<template>
	<div>
		<div class="listFormHeader">
			<table>
				<thead>
					<tr>
						<th style="width: 34%">{{ t("message.msg_608") }}</th>
						<th style="width: 66%">{{ t("message.msg_613") }}</th>
					</tr>
				</thead>
			</table>
		</div>
		<div class="listFormBody">
			<table>
				<tbody>
					<tr v-if="searchedOptionList == ''">
						<td>{{ t("message.msg_660") }}</td>
					</tr>
					<template v-for="(option, index) in searchedOptionList" :key="index">
						<tr @click="checkUser(option)">
							<td style="width: 34%">{{ option.reportCd }}</td>
							<td style="width: 66%">{{ option.defaultReportName }}</td>
						</tr>
					</template>
				</tbody>
			</table>
		</div>
		<div class="btnClose"><a @click.prevent.stop="close"></a></div>
	</div>
</template>

<script setup>
import { defineProps, defineEmits } from 'vue';
import {useI18n} from "vue-i18n"

const emit = defineEmits(["close", "optionInfo"])
const {t} = useI18n()

defineProps({
	searchedOptionList: { type: Array, required: true },
});

let reportCd = '';
let defaultReportName = '';

const checkUser = (option) => {
    reportCd = option.reportCd;
    defaultReportName = option.defaultReportName;
    emit('optionInfo', { option });
};

const close = () => {
    emit("close");
};
</script>

<style scoped src="@/assets/css/pc/share.css">
</style>
